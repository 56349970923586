import i18n from '@/plugins/i18n.js'
import store from '@/store'
import { showblank } from '@/components/summary/pdf/formats'
const t = i18n.global.t

export const getDate = (reporting = false, iso_string = undefined) => {
    let m = new Date()
    if (iso_string) {
        m = new Date(iso_string)
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const minutes = m.getMinutes() < 10 ? `0${m.getMinutes()}` : m.getMinutes()
    const seconds = m.getSeconds() < 10 ? `0${m.getSeconds()}` : m.getSeconds()
    let datestring = `${m.getUTCDate()} ${t(
        `general.months.${m.getMonth()}`
    )} ${m.getFullYear()}, ${m.getHours()}:${minutes}:${seconds} (${timezone})`
    if (reporting) {
        datestring = `${m.getUTCDate()} ${t(`general.months.${m.getMonth()}`, 1, {
            locale: 'en',
        })} ${m.getFullYear()}, ${m.getHours()}:${minutes}:${seconds} (${timezone})`
    }
    return datestring
}

export const generateUUID = () => {
    // Public Domain/MIT
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
    }
    const newGuid = 'yxxxxx-yxxxx-yxxxxx'.replace(/[xy]/g, function create(c) {
        // eslint-disable-next-line no-bitwise
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })

    return newGuid
}

export const getSubProjectCategoryOfMeasure = (uid, reporting = false) => {
    const completeMeasures = store.getters['measures/completeMeasures']

    // Show climateActionCategory for one m
    if (uid) {
        const measure = store.getters['measures/measures'].find(m => m.uid === uid)
        const climateActionCategory = store.getters['filters/climateActionCategories'].find(
            s => s.id === measure.result.reporting_info.reporting_id
        )
        return showblank(climateActionCategory.name)
    }

    // show climateActionCategory of all eligible measures with highest investment amount or most often added
    if (completeMeasures.filter(m => m.result.eligible === true).length > 0) {
        const allClimateActionCategories = store.getters['filters/climateActionCategories']

        let summaryByClimateActionCategory = {}

        eligibleMeasures.forEach(m => {
            const { investment_amount, reporting_info } = m.result
            const { reporting_id } = reporting_info
            if (summaryByClimateActionCategory[reporting_id]) {
                investment_amount === null
                    ? (summaryByClimateActionCategory[reporting_id].sum += 0)
                    : (summaryByClimateActionCategory[reporting_id].sum += investment_amount)
                summaryByClimateActionCategory[reporting_id].count++
            } else {
                summaryByClimateActionCategory[reporting_id] = {
                    sum: investment_amount === null ? 0 : investment_amount,
                    count: 1,
                }
            }
        })

        const arr = Object.entries(summaryByClimateActionCategory)

        // At least one investment amount value was specified -> return category with highest investment amount
        if (arr.filter(m => m[1].sum > 0).length > 0) {
            const sortedArr = arr.sort((a, b) => b[1].sum - a[1].sum)
            return allClimateActionCategories.find(c => c.id === parseInt(sortedArr[0][0])).name
        }
        // No investment amount value was specified -> return category with highest count
        const sortedArr = arr.sort((a, b) => b[1].count - a[1].count)
        return allClimateActionCategories.find(c => c.id === parseInt(sortedArr[0][0])).name
    }

    return ''
}

export const beneficiaryDetailsProvided = () => {
    const beneficiaryDetails = store.getters['global/beneficiaryDetails']
    const details = Object.values(beneficiaryDetails).filter(vals => vals !== '')
    if (
        details.length === 0 ||
        (details.length === 1 &&
            beneficiaryDetails.country === store.getters['global/country'].text)
    ) {
        return false
    }

    return true
}

export const addEmptyParagraph = () => {
    return {
        text: ' ',
        style: 'paragraph',
    }
}
