<template>
    <div>
        <teleport to="body">
            <confirm-alert
                :title="t('input.modal.confirm.project.reset.title')"
                :text="t('input.modal.confirm.project.reset.text')"
                :confirmBtnText="t('input.modal.confirm.project.reset.btn')"
                :confirmBtnIcon="true"
                :cancelBtnText="t('input.modal.confirm.project.keep.btn')"
                ref="confirmAlertRef"
                @confirm-clicked="resetProject"
            ></confirm-alert>
        </teleport>

        <div class="flex">
            <div class="flex-auto">
                <h4 class="h4 pb-6">
                    <!-- <CollectionIcon class="w-7 mt-1 mr-3 float-left text-blue-500" /> -->
                    {{ t('summary.texts.measuresListHeading') }}
                </h4>
            </div>
            <div class="flex-auto text-right lg:mr-4">
                <button
                    @click="this.$refs.confirmAlertRef.showAlert()"
                    v-if="measuresCount > 0"
                    type="button"
                    :class="[
                        'mb-2 py-1 px-1 text-base font-medium ml-1 md:py-1 md:px-3 md:text-sm lg:py-2 lg:px-3 border-2 relative inline-flex items-center rounded-full justify-center focus:z-10 focus:outline-none',
                        'border-gray-300 bg-white text-md text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-eibbrightblue focus:border-eibbrightblue',
                    ]"
                >
                    <ArrowPathIcon class="-ml-1 mr-2 h-4 w-4 text-gray-400" />
                    {{ t('summary.resetProjectBtn') }}
                </button>
            </div>
        </div>
        <div class="bg-white shadow overflow-hidden sm:rounded-md mb-6">
            <ul class="divide-y divide-gray-200">
                <!-- <li>
                    <div
                        class="
                            border-gray-200
                            bg-gray-50
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        <div class="flex items-center px-4 py-4 sm:px-6">
                            <div class="min-w-0 flex-1 flex items-center">Status</div>
                            <div class="min-w-0 flex-1 flex items-left">Measure</div>
                            <div>Actions</div>
                        </div> 
                    </div>
                </li> -->
                <li v-for="m in measures" v-bind:key="m.uid">
                    <SummaryItem
                        v-bind:measure="m"
                        :detailsOpen="
                            this.currentMeasure && this.currentMeasure.uid === m.uid ? true : false
                        "
                    />
                </li>
                <li v-if="measures.length === 0">
                    <div
                        class="border-gray-200 text-center text-sm pt-6 pb-6 font-medium text-gray-500"
                    >
                        {{ t('summary.texts.noMeasureSpecified') }}
                    </div>
                </li>
            </ul>
        </div>
        <div class="text-center w-full pt-3 pb-6">
            <p
                v-if="measuresCount >= 50"
                class="bg-red-50 text-sm p-4 my-4 rounded-md w-1/4 mx-auto"
            >
                {{ t('general.maxMeasures') }}
            </p>
            <BaseButton
                @click="addMeasure"
                :primary="false"
                test="addMeasureBtn"
                :disabled="measuresCount >= 50"
            >
                {{ t('summary.addmeasureBtn') }}
                <PlusIcon class="h-5 w-5 ml-1 mr-0 float-right" />
            </BaseButton>
        </div>
    </div>
</template>

<script>
// import EligibleBox from '@/components/common/EligibleBox.vue'
import { useStore } from 'vuex'

import { useRouter } from 'vue-router'
import { ref, computed } from 'vue'

import { PlusIcon, ArrowPathIcon } from '@heroicons/vue/24/outline'
// import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import SummaryItem from '@/components/summary/SummaryItem.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import confirmAlert from '../base/confirmAlert.vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'AssessmentResults',
    components: {
        SummaryItem,
        BaseButton,
        PlusIcon,
        ArrowPathIcon,
        confirmAlert,

        // InformationCircleIcon,
        // CheckCircleIcon,
        // EligibleBox,
    },
    setup() {
        const store = useStore()
        const { t } = useI18n()
        const router = useRouter()
        const confirmAlertRef = ref(false)
        const measures = computed(() => {
            const allmeasures = store.getters['measures/measures']
            return allmeasures
        })

        const triggerShowAlert = () => {
            confirmAlertRef.value.showAlert()
        }
        const measuresCount = computed(() => {
            return measures.value.length
        })
        const resetProject = () => {
            store.dispatch('measures/deleteMeasures')
            store.dispatch('global/deleteAdditionalInformation')
        }

        const currentMeasure = computed(() => store.getters['measures/currentMeasure'])

        const addMeasure = () => {
            router.push('/selection')
            store.commit('measures/currentMeasureId', null)
            store.commit('global/currentStep', 1)
        }

        // const showDetails = () => {}

        return {
            measures,
            currentMeasure,
            addMeasure,
            measuresCount,
            triggerShowAlert,
            resetProject,
            t,
        }
    },
}
</script>
