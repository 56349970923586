<template>
    <BaseFormField :label="label">
        <div>
            <div>
                <BaseRadioItem
                    v-for="(item, i) in options"
                    :fieldId="i"
                    :key="`item${i}`"
                    v-model:values="value"
                    :value="item.value"
                    :text="item.text"
                    :description="item.description"
                    :data-model="model"
                />
            </div>
        </div>
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import { ref, watch } from 'vue'
import BaseFormField from '@/components/base/BaseFormField.vue'
import BaseRadioItem from '@/components/base/BaseRadioItem.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { BaseFormField, ValidationMessage, BaseRadioItem },
    name: 'BaseSwitchSelect',
    props: {
        options: [Array],
        label: String,
        modelValue: { type: Array, default: () => [] },
        model: String,
        test: String,
        placeholder: String,
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String, Boolean],
            default: () => ({}),
        },
    },
    setup(props, context) {
        const value = ref(JSON.parse(JSON.stringify(props.modelValue)))
        watch(value, val => {
            context.emit('update:modelValue', val)
        })
        return {
            value,
        }
    },
}
</script>
