<template>
    <div></div>
</template>

<script>
import { watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useIsFormValid } from 'vee-validate'
// This is a workaround to set the valid state of the form as I did not find another way :-(
export default {
    props: {
        validation: {
            type: Object,
            default: () => ({}),
        },
        mode: { type: String },
    },
    setup(props) {
        const store = useStore()
        const { model } = store.getters['measures/currentMeasure']
        const valid = useIsFormValid()
        watch([valid, model], val => {
            if (props.mode === 'simple') {
                store.commit('global/formValidSimple', val[0])
            } else {
                store.commit('global/formValidAdvanced', val[0])
            }
            store.commit('measures/markMeasureReady', val[0])
        })
    },
}
</script>
