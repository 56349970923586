<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n() // use as global scope
</script>

<template>
    <BaseFormField :label="label">
        <div v-for="(item, index) in value" v-bind:key="index" class="mt-4">
            <MultiLineItem
                :modelValue="item"
                :index="index"
                :options="options"
                :suffix="suffix"
                :min="min"
                :max="max"
                @update:modelValue="update"
                @remove="remove"
                :showTrash="trashVisible"
                validation:validation
            />
        </div>
        <div class="flex justify-between items-center cursor-pointer mt-6">
            <BaseButton @click="addLine" size="sm">
                <PlusIcon class="h-4 w-4 float-left" />
                {{ t('general.addEnergySource') }}
            </BaseButton>
        </div>
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import MultiLineItem from '@/components/base/MultiLineItem.vue'
import BaseFormField from '@/components/base/BaseFormField.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'
import { PlusIcon } from '@heroicons/vue/24/outline'

// https://codesandbox.io/s/fvl-playground-3-x-forked-lztjzb?file=/src/App.vue:1377-1573

// Examples:
// schema = {
//     component: "MultiLine",
//     label: "Test",
//     model: "list",
//     options: [
//       { text: "Eins", value: 1 },
//       { text: "Zwei", value: 2 },
//       { text: "Drei", value: 3 },
//     ],
//   }

// model
// "list": [
//     {
//       "selection": 2,
//       "value": 12
//     },
//     {
//       "selection": {},
//       "value": null
//     }
//   ]

export default {
    components: { BaseFormField, ValidationMessage, MultiLineItem, BaseButton, PlusIcon },
    name: 'MultiLineFormField',
    props: {
        options: [Array],
        label: String,
        modelValue: { type: [Array, Object] },
        model: String,
        test: String,
        placeholder: String,
        suffix: String,
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String, Boolean],
            default: () => ({}),
        },
        styled: {
            type: String,
            required: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
    },
    data() {
        return {
            emptyLine: { value: null, numeric_value: null },
        }
    },
    computed: {
        value: {
            get() {
                if (!this.modelValue) {
                    return [this.emptyLine]
                }
                return this.modelValue
            },
        },
        modifiedOptions() {
            return this.options
        },
        trashVisible() {
            return this.value.length > 1
        },
    },
    methods: {
        getValue(item) {
            if (typeof item === 'object') {
                return item.value
            }
            return item
        },
        getText(item) {
            if (typeof item === 'object') {
                return item.text
            }
            return item
        },
        update(val) {
            // console.log(val)
            this.value[val.index] = val.value
            this.$emit('update:modelValue', Object.assign([], this.value))
        },
        addLine() {
            this.value.push(this.emptyLine)
            this.$emit('update:modelValue', Object.assign([], this.value))
        },
        remove(index) {
            // console.log(index)
            if (this.value.length > 1) {
                this.value.splice(index, 1)
                this.$emit('update:modelValue', Object.assign([], this.value))
            }
        },
    },
}
</script>
