<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <button
        @click="showCommentInput = !showCommentInput"
        type="button"
        :class="[
            'mb-2 py-2 px-4 text-base font-medium ml-1 md:py-2 md:px-3 md:text-sm lg:py-3 lg:px-6 lg:text-base  border-2 relative inline-flex items-center rounded-full justify-center focus:z-10 focus:outline-none',
            comment != ''
                ? 'border-eibbrightblue bg-eibbrightblue text-md text-white'
                : 'border-gray-300 bg-white text-md text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-eibbrightblue focus:border-eibbrightblue',
        ]"
    >
        <ChatBubbleBottomCenterTextIcon
            class="-ml-1 mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
            v-if="comment === ''"
        />
        <CheckIcon class="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" v-if="comment != ''" />
        {{ t('general.comment') }}
    </button>
    <div v-if="showCommentInput" class="mt-6 mb-6 shadow-xl rounded-xl border border-gray-100">
        <base-input
            :label="t('general.commentLabel')"
            v-model="comment"
            formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
            maxlength="60"
        ></base-input>
    </div>
</template>

<script>
import { ChatBubbleBottomCenterTextIcon, CheckIcon } from '@heroicons/vue/24/outline'
import BaseInput from '../base/BaseInput.vue'
import { useI18n } from 'vue-i18n'

export default {
    components: {
        ChatBubbleBottomCenterTextIcon,
        CheckIcon,
        BaseInput,
    },
    props: { class: { type: String } },

    data() {
        return {
            showCommentInput: false,
        }
    },
    computed: {
        comment: {
            set(val) {
                this.$store.commit('global/comment', val)
            },
            get() {
                return this.$store.getters['global/comment']
            },
        },
    },
    setup() {
        const { t } = useI18n()
        return { t }
    },
}
</script>
