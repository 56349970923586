<template>
    <BaseFormField :label="label">
        <select
            class="bg-eibblue20 rounded-md border-none w-full text-sm"
            v-model="value"
            :data-testid="test ? test : 'select'"
            :data-model="model"
        >
            <option
                value=""
                disabled
                selected="selected"
                v-if="placeholder"
                class="text-black"
                :data-model="model"
            >
                {{ placeholder }}
            </option>
            <option
                class="bg-white border-none"
                v-for="(item, i) in modifiedOptions"
                :key="`item${i}`"
                :value="getValue(item)"
                :data-model="model"
            >
                {{ getText(item) }}
            </option>
        </select>
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import BaseFormField from '@/components/base/BaseFormField.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { BaseFormField, ValidationMessage },
    name: 'BaseSelect',
    props: {
        options: [Array],
        label: String,
        modelValue: [String, Number, Object, Boolean],
        model: String,
        test: String,
        placeholder: String,
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String, Boolean],
            default: () => ({}),
        },
    },
    data() {
        return {}
    },
    computed: {
        value: {
            get() {
                if (typeof this.modelValue === 'object' && !this.modelValue === null) {
                    return this.modelValue.value
                }
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            },
        },
        modifiedOptions() {
            // if (this.placeholder) {
            //     const placeholder = {
            //         value: '',
            //         text: this.placeholder,
            //         selected: true,
            //         disabled: 'disabled',
            //     }
            //     this.options.unshift(placeholder)
            // }

            return this.options
        },
    },
    methods: {
        getValue(item) {
            if (typeof item === 'object') {
                return item.value
            }
            return item
        },
        getText(item) {
            if (typeof item === 'object') {
                return item.text
            }
            return item
        },
    },
}
</script>
