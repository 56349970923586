<template>
    <BaseFormField :label="label">
        <cleave
            :raw="false"
            :options="options"
            :modelValue="modelValue"
            :disabled="readOnly"
            :data-model="model"
            :data-testid="test"
            :styled="styled"
            :min="min"
            :max="max"
            @input="update($event.target.value)"
            :class="[
                'h-9 shadow-sm block w-full sm:text-sm border-none rounded-md px-3 focus:border-blue-700 focus:ring-blue-700',
                styled === 'meta_field' ? 'bg-gray-100' : 'bg-eibblue20',
            ]"
            inputmode="decimal"
        />
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import Cleave from 'vue-cleave-component'
import BaseFormField from '@/components/base/BaseFormField.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { BaseFormField, Cleave, ValidationMessage },
    data() {
        let options = {
            options: {
                numeral: true,
                numeralDecimalMark: '.',
                delimiter: ' ',
            },
        }

        if (this.decimals) {
            options.options.numeralDecimalScale = this.decimals
        }

        return options
    },
    props: {
        modelValue: { required: true },
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        styled: {
            type: String,
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String],
            default: () => ({}),
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
        decimals: {
            type: Number,
            default: 2,
        },
        model: {
            type: String,
        },
        test: {
            type: String,
        },
    },
    computed: {
        labelHtml() {
            const requiredHtml =
                // eslint-disable-next-line no-underscore-dangle
                this.validations._exclusive && this.validations._exclusive.required
                    ? ' <span class="label--required">*</span>'
                    : ''
            return this.label + requiredHtml
        },
    },
    methods: {
        update(value) {
            const newVal = this.decimals
                ? parseFloat(parseFloat(value.replace(/ /g, '')).toFixed(this.decimals))
                : parseFloat(value.replace(/ /g, ''))

            this.$emit('update:modelValue', newVal)
        },
    },
}
</script>
