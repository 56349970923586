<template>
    <div>
        <div class="relative">
            <div class="bg-gray-100">
                <div class="container sm:mx-auto mb-0 py-6 w-2/3 sm:py-12">
                    <h3
                        class="sm:text-lg lg:text-4xl font-semibold sm:font-bold font-alegreya pb-6"
                    >
                        About this website: &nbsp;
                        <a href="https://greenchecker.eib.org">greenchecker.eib.org</a>
                    </h3>

                    <p class="my-3">
                        This website has been developed by adelphi consult GmbH (Berlin, Germany) as
                        part of an assignment under the EIB Group's
                        <a
                            class="text-eibbrightblue"
                            href="https://greengateway.eib.org/"
                            target="_blank"
                        >
                            Green Gateway - Advise for Financial Institutions
                        </a>
                        . The Green Gateway is a joint agreement between the European Investment
                        Bank and the European Commission which addresses the limited access to
                        adequate and affordable commercial financing for climate action and
                        environmental sustainability investments. The assignment is carried out with
                        funding of the European Union in the framework of the InvestEU Advisory Hub
                        and of the European Investment Bank.
                    </p>
                    <p class="my-3 italic">
                        The views expressed in this website can in no way be taken to reflect the
                        official position of the InvestEU Advisory Hub, nor the EIB, nor the
                        European Union. The authors take full responsibility for the contents.
                    </p>
                </div>
            </div>
            <div class="">
                <div class="container sm:mx-auto mb-0 py-6 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">
                        Important Notification for Users of this Web-Tool
                    </p>

                    <p class="text-align-justify pb-3">
                        adelphi does not process any requests from users of the Green Eligibility
                        Checker. That is, adelphi does not process requests regarding specific
                        investment projects and/or loan applications. The processing of user
                        requests is solely conducted by the entities which draw on the Green
                        Eligibility Checker online tool, such as banks which implement intermediated
                        loans in different European countries. If you have questions regarding your
                        climate investment project and the respective financing possibilities,
                        please get in touch with the relevant bank or entity.
                    </p>

                    <p class="text-align-justify">
                        For general questions on the Green Eligibility Checker or the Green Gateway
                        you may contact the EIB under the following email address:
                        <a href="mailto:greenchecker@eib.org" class="text-eibbrightblue">
                            greenchecker@eib.org
                        </a>
                        .
                    </p>

                    <p class="text-xl font-bold py-6">Legal Notice</p>

                    <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <th>adelphi consult GmbH</th>
                            </tr>
                            <tr>
                                <td>Alt-Moabit 91</td>
                            </tr>
                            <tr>
                                <td>10559 Berlin</td>
                            </tr>
                            <tr>
                                <td>T +49 (30) 8900068-0</td>
                            </tr>
                            <tr>
                                <td>F +49 (30) 8900068-10</td>
                            </tr>
                            <tr>
                                <td><a href="mailto:office@adelphi.de">office@adelphi.de</a></td>
                            </tr>
                        </tbody>
                    </table>

                    <p class="py-3">
                        <b>Managing directors:</b>
                        Mikael P. Henzler, Walter Kahlenborn, Dr. Constanze Haug, Benno Gerhardus
                    </p>

                    <p class="py-3">Company with its registered seat in Berlin</p>

                    <p>
                        District court Charlottenburg
                        <br />
                        HRB 85067
                        <br />
                        USt.-ID: DE813485763
                    </p>

                    <p class="text-lg py-6 font-bold">
                        Responsible party according to Section 5 Paragraph 2 of the Interstate
                        Broadcasting Agreement (§ 55 Abs. 2 RStV) and pursuant to Section 2 of the
                        Ordinance on Service Providers’ Information Obligations (§ 2 DL-InfoV)
                    </p>

                    <p class="font-semibold">
                        Person responsible for the website according to press law:
                    </p>

                    <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td>Alexander Carius</td>
                            </tr>
                            <tr>
                                <td>adelphi</td>
                            </tr>
                            <tr>
                                <td>Alt-Moabit 91</td>
                            </tr>
                            <tr>
                                <td>10559 Berlin</td>
                            </tr>
                            <tr>
                                <td>T +49 (30) 8900068-0</td>
                            </tr>
                            <tr>
                                <td>F +49 (30) 8900068-10</td>
                            </tr>
                            <tr>
                                <td><a href="mailto:office@adelphi.de">office@adelphi.de</a></td>
                            </tr>
                        </tbody>
                    </table>

                    <p class="text-xl font-bold pt-6">Technical contact for this website:</p>

                    <p>
                        <a href="mailto:digital-solutions@adelphi.de">
                            digital-solutions@adelphi.de
                        </a>
                    </p>

                    <p class="text-xl font-bold pt-6 pb-3">Legal Disclaimer</p>

                    <p class="text-xl font-bold pb-3">Contents of this website</p>

                    <p class="text-align-justify">
                        adelphi assumes no responsibility for the topicality, accuracy, completeness
                        or the quality of the information provided. Liability claims against adelphi
                        of a material or immaterial nature due to damages caused by the use or
                        disuse of information are excluded, in so far as no wilful or gross
                        negligence can be attributed to adelphi.
                    </p>

                    <p class="text-align-justify">
                        All offers are non-obligatory and nonbinding. adelphi explicitly reserves
                        the right to change, add to, delete or to cease publication of this site (or
                        parts thereof) temporarily or permanently, without prior notice.
                    </p>

                    <p class="text-xl font-bold pt-6 pb-3">External Links</p>

                    <p class="text-align-justify">
                        This site contains links to third-party websites (external links). These
                        websites are the sole responsibility of their respective operators. We can
                        therefore assume no responsibility for their contents and data protection
                        policies. We have, at the point at which the respective link was created,
                        reviewed the external content to establish whether it presents any legal
                        violations, at which time no legal violations were apparent. We have no
                        influence on the current or future design or content of the linked pages.
                        The use of external links means in no way that we claim ownership of the
                        content being linked to. Moreover, we distance ourselves expressly from all
                        illegal or offensive content. In the event that you are unexpectedly led to
                        questionable content via this website, please notify us of this via the
                        email address
                        <a href="mailto:webmaster@adelphi.de">webmaster@adelphi.de</a>
                        . Constant supervision of external links is held to be not reasonable
                        without concrete evidence of legal violations. Such links will be deleted as
                        soon as adelphi is made aware of them.
                    </p>

                    <p class="text-xl font-bold pt-6 pb-3">Copyright and Trademark Law</p>

                    <p class="text-align-justify pb-3">
                        The content of this website is protected by copyright law. Without express
                        permission from adelphi, no parts of this website may be reproduced or
                        processed using electronic systems, duplicated or distributed, unless such
                        activities are expressly permitted by copyright law.
                    </p>

                    <p class="text-align-justify pb-3">
                        Photos and images used on this website are either part of adelphi’s own bank
                        of images, or they are licensed visual material. Copyright information for
                        every image will be shown within or directly next to the image in question.
                    </p>

                    <p class="text-align-justify">
                        All brands and trademarks mentioned on this website are subject to the
                        provisions of the applicable trademark law and, where appropriate, the
                        ownership rights of the respective copyright owner.
                    </p>

                    <p class="text-xl font-bold pt-6 pb-3">Data Protection</p>

                    <p class="text-align-justify">
                        Please read our
                        <a href="/privacy" class="text-eibbrightblue">
                            Online Data Protection Statement
                        </a>
                        .
                    </p>

                    <p class="text-xl font-bold pt-6 pb-3">Legal Validity of this Disclaimer</p>

                    <p class="text-align-justify">
                        This disclaimer is to be regarded as part of adelphi’s internet
                        publications. If sections or individual wordings of this text do not, no
                        longer, or do not completely correspond to the applicable current law, the
                        remaining parts of the document remain, in content and validity, entirely
                        unaffected.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Legal Notice',
    setup() {},
}
</script>
