import _ from 'lodash'
import i18n from '@/plugins/i18n.js'
const t = i18n.global.t

/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
function ParseJsonIfNotString(str) {
    try {
        return JSON.parse(str)
    } catch (e) {
        return str
    }
}

function findFirstByKey(obj, keyToFind) {
    if (obj) {
        const objects = Object.entries(obj).reduce(
            (acc, [key, value]) =>
                // eslint-disable-next-line no-nested-ternary
                key === keyToFind
                    ? acc.concat(value) || typeof value === 'boolean'
                    : typeof value === 'object'
                    ? acc.concat(findFirstByKey(value, keyToFind))
                    : acc,
            []
        )
        // console.log(objects)
        return objects.filter(o => o !== undefined)[0]
    }
    return []
}
// Function to build conditional view logic based on the convention implemented in the backend
function compare(condition, model) {
    // console.log(model)
    const defaultValue = ParseJsonIfNotString(condition.value)
    const { operator } = condition
    const modelValue = findFirstByKey(model, condition.field)
    // console.log(`deaultValue: ${defaultValue} modelValue: ${modelValue}`)

    switch (operator) {
        case 'gt':
            return modelValue > defaultValue
        case 'lt':
            return modelValue < defaultValue
        case 'gte':
            return modelValue >= defaultValue
        case 'lte':
            return modelValue <= defaultValue
        case 'eq':
            return modelValue === defaultValue
        case 'not':
            return modelValue !== defaultValue
        case 'in':
            return defaultValue.includes(modelValue)
        default:
            return modelValue === defaultValue
    }
}

// eslint-disable-next-line import/prefer-default-export
export async function prepareConditions(schemaRaw, model) {
    /* Convert conditional display of fields */
    // Some rules are globaly defined in main.js
    let rules = []
    schemaRaw.forEach(s => {
        rules = []
        if (s.and_conditions) {
            const { and_conditions } = s
            // console.log(s.model)
            s.condition = () =>
                // eslint-disable-next-line no-new-func
                Function(
                    `"use strict";             
                    return (${and_conditions.map(w => compare(w, model)).join('&&')})`
                )(model.value)
        }
        if (s.or_conditions) {
            const { or_conditions } = s
            s.condition = () =>
                // eslint-disable-next-line no-new-func
                Function(
                    `"use strict";return (${or_conditions.map(w => compare(w, model)).join('||')})`
                )(model.value)
        }
        if (s.min || s.min == 0) {
            rules.push(`min:${s.min}`)
        }
        if (s.max || s.max == 0) {
            rules.push(`max:${s.max}`)
        }
        if (s.required) {
            rules.push('required')
        }
        if (s.component === 'SchemaForm') {
            prepareConditions(s.schema, model)
        }
        s.validations = rules.join('|')
    })
    return schemaRaw
}
function formatDefaults(fieldsWithDefault) {
    return fieldsWithDefault
        .map(s => ({ key: s.model, value: s.default_value }))
        .reduce(
            (obj, item) =>
                Object.assign(obj, {
                    [item.key]: item.value, // etwas kompliziert aber notwendig, damit die Bedingung bei Selects auch funktioniert
                }),
            {}
        )
}

async function getSubSchemaDefaults(subSchemas, defaults, path = null) {
    subSchemas.forEach(subSchema => {
        if (subSchema.schema) {
            let currentPath = subSchema.model
            if (path) {
                currentPath = `${path}.${subSchema.model}`
            }
            // console.log(path)
            const fieldsWithDefault = subSchema.schema.filter(
                s => s.default_value || typeof s.default_value === 'boolean'
            )
            const result = formatDefaults(fieldsWithDefault)
            if (Object.keys(result).length > 0) {
                _.set(defaults, currentPath, result)
            }
            const nestedSubSchemas = subSchema.schema.filter(s => s.component === 'SchemaForm')
            if (nestedSubSchemas.length > 0) {
                getSubSchemaDefaults(nestedSubSchemas, defaults, currentPath)
            }
        }
    })
    return defaults
}

export async function getDefaults(schemaRaw) {
    /* Copy default values in the model */

    let fieldsWithDefault = schemaRaw.data.filter(
        s => s.default_value || typeof s.default_value === 'boolean'
    )
    // Etwas sperrig, aber so werden auch die default Werte aus dem Extended Schema berücksichtigt.
    const extendedRawSchema = schemaRaw.data.filter(m => m.model === 'extended_schema')
    if (extendedRawSchema.length > 0) {
        fieldsWithDefault = [
            ...fieldsWithDefault,
            ...extendedRawSchema[0].schema.filter(
                s => s.default_value || typeof s.default_value === 'boolean'
            ),
        ]
    }
    // Die Werte ins benötigte Format bringen
    let defaults = formatDefaults(fieldsWithDefault)

    // SubSchemaWerte dazutun
    const subSchemas = schemaRaw.data.filter(s => s.component === 'SchemaForm')
    defaults = getSubSchemaDefaults(subSchemas, defaults)

    return defaults
}

export function getMeasureInputDetails(model, schema, result = []) {
    /* 
        Function to prepare human readable Inputs for the pdf report to make inputs transparent
        Only visible fields are included
    */
    Object.entries(model).forEach(entry => {
        const key = entry[0]
        const value = entry[1]
        const schemaField = _.find(schema, ['model', key])
        // Recursive
        if (schemaField && schemaField.component === 'SchemaForm') {
            getMeasureInputDetails(model[key], schemaField.schema, result)
        } else if (schemaField) {
            let textValue = value
            // distinguish compontents
            if (
                schemaField.component === 'FormSelect' ||
                schemaField.component === 'FormRadioSelect' ||
                schemaField.component === 'MultiLineFormField'
            ) {
                textValue = schemaField.options.find(o => o.value === value).text
            }
            if (schemaField.component === 'FormFloat' || schemaField.component === 'FormInteger') {
                const suffix = schemaField.suffix ? schemaField.suffix : ''
                textValue = `${value} ${suffix}`
            }
            if (schemaField.component === 'FormCheckbox') {
                textValue = value ? t('general.yes') : t('general.no')
            }
            if (schemaField.component === 'FormSwitchSelect') {
                const responseOptions = []

                value.forEach(f => {
                    responseOptions.push(schemaField.options.find(o => o.value === f).text)
                })
                const response = responseOptions.join(', ')
                textValue = response
            }
            textValue = textValue.replaceAll('₂', '<sub>2</sub>')
            const label = schemaField.label.replaceAll('₂', '<sub>2</sub>')
            // apply conditions
            if (schemaField.and_conditions) {
                if (
                    schemaField.and_conditions.reduce(
                        (previous, current) => previous && compare(current, model)
                    )
                ) {
                    result.push(`${label}: ${textValue}`)
                }
            } else if (schemaField.or_conditions) {
                if (
                    schemaField.or_conditions.reduce(
                        (previous, current) => previous || compare(current, model)
                    )
                ) {
                    result.push(`${label}: ${textValue}`)
                }
            } else {
                result.push(`${label}: ${textValue}`)
            }
        }
    })
    // console.log([...new Set(result)])

    return [...new Set(result)]
}
