import { createApp } from 'vue'

import VueAxios from 'vue-axios'
import VueMatomo from 'vue-matomo'
import VueSmoothScroll from 'vue3-smooth-scroll'

import { defineRules } from '@/plugins/vee-validate-rules.js'
import * as Sentry from '@sentry/vue'

import router from './router'
import App from './App.vue'

import store from './store'

import './index.css'
// import './tailwind.css'
// && messages.includes(window.navigator.language)

import i18n from './plugins/i18n.js'
import axios from './plugins/axios.js'
import { defaultLocale } from './plugins/i18n.js'
import { useMatomo, useSentry, getEnvironment } from './utils/environmentCheck'

// // Rules for vee-validate
defineRules()

const app = createApp(App)

app.use(VueAxios, axios)

app.use(router)
app.use(i18n)

// Embed Matomo Tracking only on production environment
if (useMatomo()) {
    app.use(VueMatomo, {
        host: 'https://matomo.adelphi.de/',
        siteId: 131,
        trackerFileName: 'matomo',
        router,
        enableLinkTracking: true,
        requireConsent: true,
        trackInitialView: true,
        disableCookies: false,
        enableHeartBeatTimer: false,
        heartBeatTimerInterval: 15,
        debug: false,
        userId: undefined,
        cookieDomain: '*.greenchecker.eib.org',
        cookiePath: undefined,
        domains: '*.greenchecker.eib.org',
        preInitActions: [
            ['setCustomVariable', '1', 'AppLanguage', defaultLocale],
            ['setCustomVariable', '2', 'BrowserLanguage', window.navigator.language],
            ['setCustomVariable', '3', 'FinancialIntermediary', 'eib'],
        ],
    })
}

// Embed Sentry Error Tracing only on production and staging / review environments
if (useSentry()) {
    Sentry.init({
        app,
        dsn: 'https://be85d131c472480481514347c064b9bf@sentry.adelphi.app/3',
        environment: getEnvironment(),
        release: import.meta.env.VITE_RELEASE,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ['localhost', /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.0,
        // Replay only for dev environment
        replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.0,
        integrations: [new Sentry.Replay()],
    })
}

app.use(VueSmoothScroll)

app.use(store)

app.mount('#app')
