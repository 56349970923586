<template>
    <BaseFormField :label="label" formFieldStyles="display:inline-block !important;">
        <div class="flex flex-row">
            <div
                v-if="condition_text"
                class="grow text-sm sm:text-md"
                v-html="condition_text"
            ></div>
            <RadioGroup v-model="selected">
                <div :class="condition_text ? 'flex flex-row' : '' + 'bg-white rounded-md'">
                    <RadioGroupOption
                        as="template"
                        v-for="(option, optionIdx) in options"
                        :key="option.value"
                        :value="option"
                        v-slot="{ checked, active }"
                        :data-model="model"
                    >
                        <div
                            :class="[
                                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                optionIdx === options.length - 1
                                    ? 'rounded-bl-md rounded-br-md'
                                    : '',
                                'flex cursor-pointer focus:outline-none px-4 py-2',
                            ]"
                        >
                            <span
                                :class="[
                                    checked
                                        ? 'bg-blue-600 border-transparent'
                                        : 'bg-white border-gray-300',
                                    active ? 'ring-1 ring-offset-2 ring-indigo-500' : '',
                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                ]"
                                aria-hidden="true"
                            >
                                <span class="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <div class="ml-3 flex flex-col">
                                <RadioGroupLabel
                                    as="span"
                                    :class="[
                                        checked ? 'text-blue-900' : 'text-gray-900',
                                        'block text-sm font-medium',
                                    ]"
                                >
                                    {{ option.text }}
                                </RadioGroupLabel>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </div>
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import { ref, watch } from 'vue'
import {
    RadioGroup,
    // RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
} from '@headlessui/vue'
import BaseFormField from '@/components/base/BaseFormField.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    name: 'BaseRadio',
    components: {
        BaseFormField,
        RadioGroup,
        // RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        ValidationMessage,
    },
    props: {
        options: [Array],
        label: String,
        condition_text: String,
        modelValue: [String, Boolean, Object, Number],
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String, Boolean],
            default: () => ({}),
        },
        model: String,
    },
    setup(props, { emit }) {
        const selected = ref(props.options.find(s => s.value === props.modelValue))
        watch(selected, val => {
            emit('update:modelValue', val.value)
        })

        return {
            selected,
        }
    },
}
</script>
