<template>
    <div>
        <div class="">
            <Stepper viewMode="Assessment" />
        </div>
        <div>
            <ExplainBox
                :subheader="t('stepper.steps', { x: currentStep, y: 3 })"
                :title="t(`stepper.steps.step${currentStep}.title`)"
                :text="t(`stepper.steps.step${currentStep}.text`)"
                :step="currentStep"
                v-if="currentStep < 3"
            />
            <MeasureSelectionFilters subheader="test" v-show="currentStep == 1" />

            <!-- Measure Selection -->
            <div class="container mx-auto" v-if="currentStep == 1">
                <MeasureSelection />
            </div>

            <!-- Measure Assessment/Input -->
            <div class="container mx-auto" v-if="currentStep == 2">
                <div class="py-12">
                    <div class="grid grid-cols-12">
                        <div class="col-span-12 px-0 lg:px-3 xl:pl-28" v-if="currentMeasureEPBD">
                            <div
                                class="border-l-2 bg-yellow-100 mt-6 border-yellow-400 p-2 py-4 my-2 mb-4 rounded-xl"
                            >
                                <h5
                                    class="ml-1 text-xs pb-1 sm:ml-2 sm:pb-3 font-semibold sm:text-base text-yellow-500"
                                >
                                    {{ t('general.importantNotice') }}
                                </h5>

                                <p
                                    class="ml-1 text-xs sm:text-base sm:ml-2"
                                    v-html="t('general.EPBDNotice')"
                                ></p>
                            </div>
                        </div>
                        <div class="col-span-12 lg:col-span-8">
                            <MeasureSpecification ref="spec" :key="currentMeasure" />
                        </div>
                        <div class="col-span-12 lg:col-span-4 mt-6 lg:mt-0">
                            <div class="h-full">
                                <div class="sticky top-6">
                                    <MeasureSummary />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline'
import MeasureSelection from '@/components/assessment/MeasureSelection.vue'
import MeasureSpecification from '@/components/assessment/MeasureSpecification.vue'
import MeasureSelectionFilters from '@/components/assessment/MeasureSelectionFilters.vue'
import MeasureSummary from '@/components/assessment/MeasureSummary.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import ExplainBox from '@/components/common/ExplainBox.vue'
import Stepper from '@/components/assessment/Stepper.vue'

export default {
    name: 'Assessment',
    components: {
        Stepper,
        MeasureSelection,
        MeasureSelectionFilters,
        MeasureSpecification,
        BaseButton,
        ArrowSmallRightIcon,
        ExplainBox,
        MeasureSummary,
        MeasureSummary,
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const route = useRoute()

        const currentMeasureId = computed(() => {
            return store.getters['measures/currentMeasureId']
        })

        if (route.name === 'Specification' && currentMeasureId.value) {
            store.commit('global/currentStep', 2)
        } else if (route.name === 'Selection') {
            store.commit('global/currentStep', 1)
        }

        const currentStep = computed(() => {
            return store.getters['global/currentStep']
        })

        const currentMeasure = computed(() => {
            return store.getters['measures/currentMeasure']
        })

        const currentMeasureEPBD = computed(() => {
            // Measure identifiers that currently have to show the EPBD Notice
            const measuresWithEPBD = [
                'roof_insulation',
                'floor_insulation',
                'wall_insulation',
                'window_replacement',
            ]

            const id_without_version = currentMeasure.value.identifier.split('_v')[0]

            // Do you confirm compliance with the local minimum energy performance standards in line with EPBD?
            return measuresWithEPBD.includes(id_without_version)
        })

        return {
            currentMeasure,
            currentMeasureEPBD,
            currentStep,
            t,
        }
    },
}
</script>
