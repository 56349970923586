<template>
    <div class="pt-3 pb-12">
        <div class="container mx-auto lg:px-28" data-testid="measuresList">
            <div
                v-if="loading"
                class="mx-auto px-28 pt-24 pb-24 text-center object-center w-0 text-eibmidnightblue40"
            >
                <div class="dots-1"></div>
            </div>
            <div
                v-for="(s, i) in filteredSectors"
                v-bind:key="'group' + i"
                class="pt-3 pb-3 ml-6 mr-6 sm:pt-6 sm:pb-6"
            >
                <div>
                    <div class="container mx-auto">
                        <div class="max-w-screen-md">
                            <h1 class="h3" v-html="s.name"></h1>
                            <p
                                class="mt-1 mb-4 text-sm text-eibgrey40 sm:text-black sm:text-base sm:my-6"
                                v-html="s.description"
                            ></p>
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
                    <MeasureItem
                        v-for="(m, i) in measuresPerSector(s.id)"
                        v-bind:key="'measure' + s.id + '-' + i"
                        v-bind:measure="m"
                        @click="m.disabled ? '' : addMeasure(m)"
                        :class="m.disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        :disabled="m.disabled"
                    />
                </div>
            </div>
            <div
                v-if="filteredSectors.length === 0 && !loading"
                class="text-center text-eibgrey60 mb-6 mt-12 italic"
            >
                {{ t('assessment.selection.noMeasuresFound') }}
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import MeasureItem from './MeasureItem.vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'MeasureSelection',
    components: { MeasureItem },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()

        // const locale = store.getters['global/locale']

        store.dispatch('measures/getMeasurePool')

        const loading = computed(() => {
            return store.getters['measures/loading']
        })
        const measures = computed(() => {
            return store.getters['measures/measurePool']
        })
        const sectors = computed(() => {
            return store.getters['measures/sectors']
        })
        const filteredMeasures = computed(() => {
            return store.getters['filters/filteredMeasures']
        })
        const filteredSectors = computed(() => {
            return store.getters['filters/filteredSectors'].sort((a, b) => {
                return Number(a.ordering) - Number(b.ordering)
            })
        })

        const measuresPerSector = sectorId => {
            return filteredMeasures.value.filter(el => {
                for (let i = 0; i < el.classification.length; i++) {
                    if (el.classification[i].sectors.includes(sectorId)) {
                        return true
                    }
                }
                return false
            })
        }

        const addMeasure = async measure => {
            await store.dispatch('measures/addMeasure', measure)
            store.commit('measures/removePreCheckResult')
            router.push(`/specification/${measure.url}`)
        }

        return {
            sectors,
            filteredMeasures,
            filteredSectors,
            measures,
            loading,
            t,
            addMeasure,
            measuresPerSector,
        }
    },
}
</script>
