<template>
    <div class="grid grid-cols-1">
        <div class="text-center">
            <BaseButton test="WebhookCreateBtn" @click="sendData" size="xl"
                :disabled="completedMeasuresCount === 0 ? true : false" :loading="isLoading" :class="{
                    'bg-red-500 border-red-500 hover:border-eibbrightblue': postFailed,
                    'bg-eibsuccess border-eibsuccess hover:border-eibbrightblue': postSuccessful,
                }">
                <div v-if="!isLoading">
                    <div v-if="!postFailed && !postSuccessful">
                        <CloudArrowUpIcon class="h-6 w-6 ml-0 mr-2 float-left rotate-90" />
                        {{ t('general.webhookBtn', [fiData.shortName]) }}
                    </div>
                    <div v-if="postFailed">
                        <XCircleIcon class="h-6 w-6 ml-0 mr-2 float-left" />
                        {{ t('general.webhookBtn', [fiData.shortName]) }}
                    </div>
                    <div v-if="postSuccessful">
                        <CheckCircleIcon class="h-6 w-6 ml-0 mr-2 float-left" />
                        {{ t('general.webhookBtn', [fiData.shortName]) }}
                    </div>
                </div>
                <div v-else>
                    <svg class="animate-spin ml-0 mr-3 h-6 w-6 text-gray-800 float-left" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                    {{ t('general.sending') }}
                </div>
            </BaseButton>
        </div>
        <div v-if="error" class="">
            <div class="rounded-md bg-red-50 p-4 mt-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-md font-medium text-red-800">
                            {{
                                error.response.data?.title
                                ? error.response.data.title
                                : 'Results have not been sent, an error occurred'
                            }}
                        </h3>
                        <div class="mt-2 text-sm text-red-700 whitespace-pre-line">
                            {{
                                error.response.data?.message
                                ? error.response.data.message
                                : 'An unrecongised error occurred while sending your results. Please try again later.'
                            }}
                        </div>
                    </div>
                    <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                            <button type="button"
                                class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                                @click="; (error = null), (postFailed = false)">
                                <span class="sr-only">Dismiss</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="success" class="">
            <div class="rounded-md bg-green-50 p-4 mt-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <CheckCircleIcon class="h-5 w-5 text-eibsuccess" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-md font-medium text-eibsuccess">
                            {{
                                success.data?.title ? success.data.title : 'Results have been sent'
                            }}
                        </h3>
                        <div class="mt-2 text-sm text-eibsuccess whitespace-pre-line">
                            {{
                                success.data?.message
                                ? success.data.message
                                : 'Results received. Thank you.'
                            }}
                        </div>
                    </div>
                    <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                            <button type="button"
                                class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-green-600"
                                @click="; (success = null), (postSuccessful = false)">
                                <span class="sr-only">Dismiss</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import {
    CloudArrowUpIcon,
    CheckCircleIcon,
    XMarkIcon,
    XCircleIcon,
} from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'
import { generateUUID } from '@/components/summary/pdf/helpers'
import { createWebhookData, sendDataToFi } from '@/components/summary/webhook/webhook'

export default {
    components: { BaseButton, CloudArrowUpIcon, CheckCircleIcon, XMarkIcon, XCircleIcon },
    emits: ['response'],
    setup(props, ctx) {
        const { t } = useI18n()
        const store = useStore()
        const loading = ref(false)
        const measuresCount = store.getters['measures/measuresCount']
        const postFailed = ref(false)
        const postSuccessful = ref(false)
        const error = ref(null)
        const success = ref(null)

        const fiData = store.getters['auth/financialIntermediary']
        const completedMeasuresCount = computed(() => {
            return store.getters['measures/completeMeasures'].length
        })

        const isLoading = computed({
            get() {
                return loading.value
            },
            set(newValue) {
                loading.value = newValue
            },
        })

        const sendData = async () => {
            isLoading.value = true
            error.value = null
            success.value = null
            postFailed.value = false
            postSuccessful.value = false
            store.commit('global/reportID', generateUUID())
            const data = await createWebhookData()
            sendDataToFi(data)
                .then(function (e) {
                    console.log(e)
                    success.value = e
                    ctx.emit('response', e)
                    postSuccessful.value = true
                    if (window._paq) {
                        window._paq.push(['trackEvent', 'Webhook', 'Webhook Data Sent', fiData.id])
                    }
                })
                .catch(function (e) {
                    error.value = e
                    ctx.emit('response', e)
                    postFailed.value = true
                    if (window._paq) {
                        window._paq.push(['trackEvent', 'Webhook', 'Webhook Data Sending Error', fiData.id])
                    }
                })
            isLoading.value = false
        }

        return {
            t,
            isLoading,
            measuresCount,
            completedMeasuresCount,
            sendData,
            fiData,
            error,
            success,
            postFailed,
            postSuccessful,
        }
    },
}
</script>

<style scoped>
.max-w-16 {
    max-width: 16.3rem;
}
</style>
