import store from '@/store'
import i18n from '@/plugins/i18n.js'
import axios from 'axios'
import { showblank, removeWhiteSpaces } from '@/components/summary/pdf/formats'
import { getSubProjectCategoryOfMeasure } from '@/components/summary/pdf/helpers'

const t = i18n.global.t
const APIToken = 'cf90c279032841f57383c48836a5016791e4482f' // TODO: needs to be feched dynamically based on client
const APIURL = '/'

const getSubProjectCategoryInEnglish = sectorsInEnglish => {
    // Refactor required to show the right sector according to times a measure has been added if no investment amount provided
    const completeMeasures = store.getters['measures/completeMeasures']
    if (completeMeasures.length > 0) {
        const measures = completeMeasures.sort(
            (a, b) => b.result.investment_amount - a.result.investment_amount
        )
        const sector = sectorsInEnglish.find(
            s => String(s.id) === String(measures[0].result.reporting_info.reporting_id)
        )
        return showblank(sector['name_fixed'])
    }
    return ''
}

const getSubProjectDescription = () => {
    const completeMeasures = store.getters['measures/completeMeasures']

    if (completeMeasures.length > 0) {
        return showblank(completeMeasures.map(m => m['name_fixed']).join(', '))
    }
    return t('general.na')
}

const getMeasureData = async fi => {
    const measuresStore = store.getters['measures/specifiedMeasures']
    const measuresWebhook = []
    measuresStore.forEach(m => {
        // const taxonomyStatement = getTaxonomyStatementByMeasure(m.uid)
        const defaults_changed = store.getters['measures/measureDefaultsChanged'](m.uid)
        const measure = {
            name: m.fullname,
            investment: m.result.investment_amount,
            // taxonomy: {
            //     compliance: getTaxonomyCompliance(m),
            //     info: taxonomyStatement[0].text,
            //     comment: getTaxonomyComment(taxonomyStatement[2]),
            // },
            identifier: m.identifier,
            eligible: m.result.eligible,
            result: m.result,
            human_readable_request: m.human_readable_request,
            request: m.request,
            hash: m.hash,
            url: m.url,
            version_number: m.version_number,
            measure_defaults_changed: defaults_changed,
            assessment_mode: m.assessment_mode,
        }

        if (fi.reporting_format == 'eib_greenchecker') {
            measure.category = getSubProjectCategoryOfMeasure(m.uid)
        }
        measuresWebhook.push(measure)
    })
    return measuresWebhook
}

const getData = async (url, language = null) => {
    const lang = language == null ? 'en' : t.locale
    // console.log(url)
    try {
        const response = await axios.get(APIURL + url, {
            headers: {
                Authorization: `Token ${APIToken}`,
                'Accept-Language': lang,
            },
        })
        // console.log(response.data)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const createWebhookData = async () => {
    const fi = store.getters['auth/financialIntermediaryId']
    const measureData = await getMeasureData(fi)
    const data = {}
    const cur_date = new Date()

    // Reporting Data Block
    data['reportingData'] = {
        version: 2,
        financialIntermediaryId: fi,
        // await getSubProjectDescription(measures, true)
    }

    if (fi) {
        // Reporting data for SBCI
        if (fi == 'sbci') {
            // Use Case Eligibility Criteria
            data['reportingData'].UseCaseIds =
                store.getters['measures/sbciUseCaseIdsOfCompleteMeasures']
            // Additional renewable and other safe and sustainable zero and low-emission energy generation capacity installed (MW)
            data['reportingData'].S_3_1 =
                parseInt(store.getters['measures/results'].renewableCapacity.replace(/ /g, '')) /
                1000
            // Estimated primary energy savings generated by the projects in kWh/year
            data['reportingData'].S_3_2 = parseInt(
                store.getters['measures/results'].primaryEnergySavings.replace(/ /g, '')
            )
            // Annual green-house gas emissions reduced/avoided in tonnes of CO2 equivalent/year
            data['reportingData'].S_3_3 =
                parseInt(store.getters['measures/results'].co2savings.replace(/ /g, '')) / 1000
            // kWh storage capacity
            data['reportingData'].S_3_4 = parseInt(
                store.getters['measures/results'].storageCapacity.replace(/ /g, '')
            )
        } else {
            // Reporting data for all other Green Checker Users
            data['reportingData'].AllocationTool = {}

            // Category Climate Action Project
            data['reportingData'].AllocationTool.CategoryOfClimateActionProject =
                store.getters['measures/climateActionCategoryOfSubProject']

            // Climate Action Results Indicators
            const results = store.getters['measures/results']

            const renewableGeneration =
                (results.electricityGeneration === undefined
                    ? 0
                    : parseInt(removeWhiteSpaces(results.electricityGeneration))) +
                (results.thermalGeneration === undefined
                    ? 0
                    : parseInt(removeWhiteSpaces(results.thermalGeneration)))

            const aggregatedResults = [
                {
                    name: 'Primary energy savings',
                    stat: removeWhiteSpaces(results.primaryEnergySavings),
                    unit: 'kWh/Year',
                },
                {
                    name: 'Renewable energy generation',
                    stat: renewableGeneration,
                    unit: 'kWh/Year',
                },
                {
                    name: 'CO2e savings',
                    stat: removeWhiteSpaces(results.co2savings),
                    unit: 'kg CO2e/year',
                },
            ]

            let indicators = []
            aggregatedResults.forEach(item => {
                if (item.stat != 0) {
                    indicators.push(`${item.name}: ${item.stat} ${item.unit}`)
                }
            })

            data['reportingData'].AllocationTool.ClimateActionResultsIndicators =
                indicators.join(', ')

            // Climate Action Additional Info
            // ToDO!
            data['reportingData'].AllocationTool.ClimateActionAdditionalInfo =
                getSubProjectDescription()
        }
    }

    // Measures Block
    data['measures'] = measureData

    // Additional Information Block
    data['beneficiaryDetails'] = store.getters['global/beneficiaryDetails']
    data['clientReferenceId'] = store.getters['global/clientReferenceId']
    data['comment'] = store.getters['global/comment']

    // Date of Assessment
    data['dateOfAssessment'] = cur_date.toISOString()

    // console.log(data)
    return data
}

export const sendDataToFi = async data => {
    const APIToken = store.getters['auth/APIToken']
    const locale = store.getters['global/locale']
    const fiId = store.getters['auth/financialIntermediaryId']
    return axios.post('/webhook/send/', data, {
        headers: {
            Authorization: `Token ${APIToken}`,
            'Financial-Intermediary-ID': `${fiId}`,
            'Accept-Language': locale,
        },
    })
}
