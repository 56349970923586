<template>
    <div class="bg-gray-100 pl-1 pr-1 sm:pl-6 sm:pr-6 pt-0">
        <!-- Search button to toggle filter -->
        <div class="xl:hidden container mx-auto px-3 sm:px-28">
            <div class="grid grid-cols-2 justify-items-center mb-4">
                <div>
                    <button
                        @click="toggleFilter"
                        class="flex rounded-full pr-6 pl-6 pt-2 pb-2 focus:outline-none border-2"
                        :class="
                            filterVisible
                                ? 'bg-white text-eibbrightblue border-eibbrightblue hover:bg-eibbrightblue hover:text-white'
                                : 'bg-eibbrightblue text-white border-white hover:bg-white hover:text-eibbrightblue hover:border-eibbrightblue'
                        "
                    >
                        <span>{{ t('filters.toggleFilter') }}</span>
                        <FunnelIcon class="h-6 w-6 ml-2" aria-hidden="true" />
                    </button>
                </div>
                <div>
                    <button
                        @click="toggleSearch"
                        class="flex rounded-full pr-6 pl-6 pt-2 pb-2 focus:outline-none border-2"
                        :class="
                            searchVisible
                                ? 'bg-white text-eibbrightblue border-eibbrightblue hover:bg-eibbrightblue hover:text-white'
                                : 'bg-eibbrightblue text-white border-white hover:bg-white hover:text-eibbrightblue hover:border-eibbrightblue'
                        "
                    >
                        <span>{{ t('filters.toggleSearch') }}</span>
                        <MagnifyingGlassIcon class="h-6 w-6 ml-2" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
        <div class="container mx-auto px-3 lg:px-28">
            <MeasureSearchBox />
            <MeasureFilterBox />
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { MagnifyingGlassIcon, FunnelIcon } from '@heroicons/vue/20/solid'
import MeasureSearchBox from '@/components/assessment/MeasureSearchBox.vue'
import MeasureFilterBox from '@/components/assessment/MeasureFilterBox.vue'

export default {
    name: 'MeasureSpecificationFilters',
    components: { MagnifyingGlassIcon, FunnelIcon, MeasureSearchBox, MeasureFilterBox },
    props: {},
    setup() {
        const { t } = useI18n()
        const store = useStore()

        // const searchText = computed(() => {
        //     return store.getters['filters/searchText']
        // })
        const filterVisible = computed(() => {
            return store.getters['filters/filterVisible']
        })

        const searchVisible = computed(() => {
            return store.getters['filters/searchVisible']
        })

        const currentMeasure = computed(() => {
            return store.getters['measures/currentMeasure']
        })

        const toggleFilter = () => {
            store.commit('filters/toggleFilter')
        }

        const toggleSearch = () => {
            store.commit('filters/toggleSearch')
        }

        return {
            filterVisible,
            searchVisible,
            currentMeasure,
            toggleSearch,
            toggleFilter,
            t,
        }
    },
}
</script>
