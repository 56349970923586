<template>
    <footer class="bg-lightgrey" aria-labelledby="footerHeading">
        <h2 id="footerHeading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto text-center">
            <button
                class="bg-eibbrightblue text-center -mt-12 rounded-full focus:outline-none"
                @click="scrollToTop"
            >
                <ChevronUpIcon class="h-10 w-10 p-2 text-white" />
            </button>
            <span class="font-alegreya block mb-6 text-base font-semibold text-eibbrightblue">
                Back to top
            </span>
        </div>
        <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:pb-16 lg:px-8">
            <div class="px-6 mb-2 text-center font-thin text-eibgrey text-lg">
                This section is part of the
            </div>
            <div class="px-6 mb-6 text-center font-semibold text-eibgrey text-lg">
                <a href="https://greengateway.eib.org/" target="_blank">Green Gateway Portal</a>
            </div>
            <div class="mx-auto mt-4 mb-9 border-3 w-12 text-center border-eibbblue60"></div>
            <div class="px-9 text-center uppercase text-xs font-semibold text-eibbrightblue">
                <p v-for="resource in greengatewayresources" class="py-2" :key="resource.href">
                    <a
                        class="border-b-2 pb-2 border-eibbrightblue hover:border-transparent"
                        :href="resource.href"
                        :target="resource.href !== '#' ? '_blank' : ''"
                    >
                        {{ resource.name }}
                    </a>
                </p>
            </div>

            <div
                class="mt-12 border-t border-gray-200 pt-6 grid grid-cols-1 sm:grid-cols-2 md:flex md:justify-between"
            >
                <div class="text-eibgrey60 text-center sm:text-left">
                    <p>&copy; European Investment Bank, 2023</p>
                    <p class="mt-3">
                        <router-link
                            class="text-sm text-eibbrightblue"
                            :to="{ name: 'Home', hash: '#faq' }"
                        >
                            FAQs
                        </router-link>
                        -
                        <router-link class="text-sm text-eibbrightblue" to="/privacy">
                            Privacy
                        </router-link>
                        -
                        <router-link class="text-sm text-eibbrightblue" to="/legal-notice">
                            Legal Notice
                        </router-link>
                    </p>
                </div>
                <div class="flex justify-center space-x-6 md:order-2 pt-6 sm:pt-0">
                    <div class="">
                        <a href="https://eiah.eib.org" target="_blank">
                            <img
                                :src="eiahlogo"
                                class="h-12 hover:opacity-70"
                                alt="InvestEU Advisory Hub"
                                title="InvestEU Advisory Hub"
                            />
                        </a>
                    </div>
                    <div>
                        <a href="https://ec.europa.eu" target="_blank">
                            <img
                                :src="eclogo"
                                class="h-8 hover:opacity-70"
                                alt="European Union"
                                title="European Union"
                            />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.eib.org" target="_blank">
                            <img
                                :src="eiblogo"
                                class="h-8 hover:opacity-70"
                                alt="European Investment Bank"
                                title="European Investment Bank"
                            />
                        </a>
                    </div>
                    <!-- <a
                        v-for="item in partnerlinks"
                        :key="item.name"
                        :href="item.href"
                        class="text-gray-400 hover:text-gray-500"
                    >
                        <span class="sr-only">{{ item.name }}</span>
                        <component :is="item.icon" class="h-24 w-24" aria-hidden="true" />
                    </a> -->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { inject } from 'vue'
import { ChevronUpIcon } from '@heroicons/vue/24/outline'
import eiahlogo from '@/assets/logo-invest-eu.png'
import eiblogo from '@/assets/logo-eib.png'
import eclogo from '@/assets/logo-european-commission.png'

export default {
    name: 'Footer',
    components: { ChevronUpIcon },
    data() {
        return {
            count: 0,
            eiblogo,
            eiahlogo,
            eclogo,
            greengatewayresources: [
                {
                    name: 'Guidelines and Knowledge',
                    href: 'https://greengateway.eib.org/knowledge-guidance/index',
                },
                { name: 'Green Eligibility Checker', href: '#' },
                { name: 'Background', href: 'https://greengateway.eib.org' },
            ],
        }
    },
    setup() {
        const smoothScroll = inject('smoothScroll')
        const scrollToTop = () => {
            // window.scrollTo(0, 0)
            smoothScroll({
                scrollTo: 0,
            })
        }

        return { scrollToTop }
    },
}
</script>
