<template>
    <a class="block">
        <div class="flex flex-wrap items-center px-2 py-2 sm:px-6 bg-eiblightgrey">
            <div class="justify-start md:order-1 order-2">
                <EligibleBox :measure="measure" view="summary"></EligibleBox>
            </div>
            <div
                class="flex-grow order-2 md:order-3 xl:order-2 justify-start items-center font-semibold font-alegreya py-4 text-2xl"
            >
                {{ measure.fullname }}
            </div>
            <div
                class="order-1 md:order-2 xl:order-3 w-full md:text-right md:w-auto md:justify-end xl:flex-shrink md:flex-grow pt-2"
            >
                <BaseButton @click="toggleVisibility" :primary="false" size="md" classes="ml-1">
                    <EyeIcon class="h-4 w-4 float-right" v-if="detailsVisible === false" />
                    <EyeSlashIcon class="h-4 w-4 float-right" v-if="detailsVisible === true" />
                </BaseButton>
                <BaseButton
                    @click="edit(measure.uid)"
                    :primary="false"
                    size="md"
                    classes="ml-1 mb-2"
                >
                    <PencilSquareIcon class="h-4 w-4 float-right" />
                </BaseButton>
                <BaseButton
                    @click="this.$refs.confirmAlertRef.showAlert(measure.uid)"
                    :primary="false"
                    size="md"
                    classes="ml-1"
                    test="deleteMeasure"
                >
                    <TrashIcon class="h-4 w-4 float-right" />
                </BaseButton>
            </div>
        </div>
        <div
            class="px-6 pb-3 py-3"
            v-if="measure.result && measure.result.non_eligibility_statement !== null"
        >
            <span
                class="text-eibineligible font-medium"
                v-html="measure.result && measure.result.non_eligibility_statement"
            ></span>
            <span
                class="text-eibmidnightblue60"
                v-if="
                    measure.result &&
                    measure.result.eligibility_statement &&
                    measure.result.paris_aligned
                "
                v-html="measure.result.eligibility_statement"
            ></span>
        </div>
        <div
            class="px-6 pb-3 py-0"
            v-if="measure.result && measure.result.non_eligibility_statement !== null"
        >
            <span class="text-eibsuccess" v-html="measure.result.eligibility_statement"></span>
        </div>
        <div v-if="showDetails">
            <!-- <div class="px-2 sm:px-6 mb-2">
                <span class="text-eibineligible text-lg">Lorem ipsum dolor sit amet</span>
            </div> -->
            <!-- <div class="px-6 py-0"><h5 class="h5 text-black">Measure Details:</h5></div> -->
            <div class="px-6 py-6" v-if="!measure.result">
                <span class="text-sm text-eibgrey60 cursor-pointer" @click="edit(measure.uid)">
                    {{ t('summary.measureIncompleteText') }}
                </span>
            </div>
            <div class="flex items-start pt-0 px-4 py-4 sm:px-6" v-if="measure.result">
                <div class="w-full">
                    <div
                        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 mt-2 justify-center"
                    >
                        <div class="md:col-span-3" v-if="measure.model.comment">
                            <div class="text-eibgrey60 font-medium text-xs my-2">
                                {{ t('general.comment') }}: {{ measure.model.comment }}
                            </div>
                        </div>
                        <div
                            class="grid grid-cols-2 md:col-span-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 mt-2 justify-center"
                        >
                            <div
                                class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3"
                            >
                                <p class="font-alegreya text-lg font-bold mb-3">
                                    {{ t('summary.estimatedGreenImpactPotential') }}
                                </p>
                                <hr />
                            </div>
                            <div
                                v-if="measure.result.impact_potential_statement"
                                class="col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3 pl-0 pr-6 text-eibgrey60 italic"
                            >
                                {{ measure.result.impact_potential_statement }}
                            </div>
                            <ResultItem
                                v-else
                                v-for="(r, index) in getFormatedResult(measure.uid)"
                                v-bind:key="'result' + measure.uid + '-' + index"
                                :label="r.label"
                                :text="r.text"
                                :result="r.result"
                                :explanation="r.explanation"
                                :type="r.type"
                                :formatedResult="r.formatedResult"
                                :unit="r.unit"
                                :show="
                                    measure.result.reporting_info.display_impact_potential &&
                                    r.result != null
                                "
                                :filter="['greenImpactPotential']"
                            />
                        </div>
                        <div class="grid grid-cols-1 gap-2 mt-2 content-start">
                            <div class="grid grid-cols-1 lg:grid-cols-2">
                                <div class="col-span-2">
                                    <p class="font-alegreya text-lg font-bold mb-3">
                                        {{ t('summary.investmentDataHeading') }}
                                    </p>
                                    <hr />
                                </div>
                                <div class="align-top my-1">
                                    <div class="text-eibgrey60 font-medium text-xs my-2">
                                        {{ t('results.investmentAmount') }}
                                    </div>

                                    <div class="font-bold text-2xl">
                                        {{
                                            measure.model.investment_amount > 0
                                                ? `${format(
                                                      measure.model.investment_amount.toFixed(2)
                                                  )}                                        
                                        `
                                                : t('general.notSpecified')
                                        }}
                                        <small v-if="measure.model.investment_amount > 0">
                                            {{ t('general.units.euro') }}
                                        </small>
                                    </div>
                                </div>

                                <div class="align-top my-1">
                                    <ResultItem
                                        label="summary.costSavingsPotential"
                                        :text="`${getFinancialSavings(measure.uid)} <small>${t(
                                            'general.units.euroYear'
                                        )}</small>`"
                                        type="costsavings"
                                        :formatedResult="getFinancialSavings(measure.uid)"
                                        :unit="t('general.units.euroYear')"
                                        :filter="['costsavings']"
                                        :show="parseInt(getFinancialSavings(measure.uid)) !== 0"
                                        :result="parseFloat(getFinancialSavings(measure.uid))"
                                        explanation="explanation.estimatedCostSavings"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>

    <teleport to="body">
        <confirm-alert
            :title="t('input.modal.confirm.measure.delete.title')"
            :text="t('input.modal.confirm.measure.delete.text')"
            :confirmBtnText="t('input.modal.confirm.measure.delete.btn')"
            :confirmBtnIcon="true"
            :cancelBtnText="t('general.keepMeasure')"
            ref="confirmAlertRef"
            @confirm-clicked="deleteMeasure"
        ></confirm-alert>
    </teleport>
</template>

<script>
// @click="deleteMeasure(measure.uid)"

import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ref, computed } from 'vue'
import {
    EyeIcon,
    PencilSquareIcon,
    TrashIcon,
    EyeSlashIcon,
    // CheckCircleIcon,
} from '@heroicons/vue/24/outline'
import BaseButton from '../base/BaseButton.vue'
import ResultItem from '../common/ResultItem.vue'
import EligibleBox from '../common/EligibleBox.vue'
import confirmAlert from '../base/confirmAlert.vue'

export default {
    name: 'SummaryItem',
    props: { measure: Object, detailsOpen: { type: Boolean, default: false } },
    components: {
        BaseButton,
        ResultItem,
        EyeIcon,
        PencilSquareIcon,
        TrashIcon,
        EyeSlashIcon,
        EligibleBox,
        confirmAlert,
    },
    setup(props) {
        const { t } = useI18n()
        const router = useRouter()
        const store = useStore()
        const confirmAlertRef = ref(false)
        const detailsVisible = ref(props.detailsOpen)
        const currentMeasure = computed(() => store.getters['measures/currentMeasure'])

        const showDetails = computed(() => {
            return detailsVisible.value
        })

        function format(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }

        const triggerShowAlert = () => {
            confirmAlertRef.value.showAlert()
        }

        const getFormatedResult = uid => {
            return store.getters['measures/result'](uid)
        }

        const getFinancialSavings = uid => {
            const results = store.getters['measures/result'](uid)
            const savings = results.find(r => r.id === 'costsavings')

            return format(savings.result.toFixed(0))
        }

        const inputdetails = uid => {
            return store.getters['measures/measureInputDetails'](uid)
        }

        const deleteMeasure = uid => {
            store.dispatch('measures/deleteMeasure', uid)
        }

        const edit = uid => {
            store.commit('measures/currentMeasureId', uid)
            const measure = store.getters['measures/currentMeasure']
            store.commit('measures/addPreCheckResult', measure.result)
            router.push(`/specification/${measure.url}`)
            store.commit('global/currentStep', 2)
        }

        function toggleVisibility() {
            detailsVisible.value = !detailsVisible.value
        }

        return {
            getFormatedResult,
            getFinancialSavings,
            deleteMeasure,
            inputdetails,
            edit,
            confirmAlertRef,
            triggerShowAlert,
            currentMeasure,
            detailsVisible,
            showDetails,
            toggleVisibility,
            format,
            t,
        }
    },
}
</script>
