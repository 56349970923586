<template>
    <div class="flex justify-between items-center" @click="enabled = !enabled">
        <h2 v-if="labelLeft" class="mr-2">{{ label }}</h2>

        <div
            class=""
            :class="[
                'flex items-center  rounded-full p-1 duration-300 ease-in-out cursor-pointer',
                enabled ? 'bg-eibbrightblue' : 'bg-gray-200',
                size == 'md' ? 'w-11 h-6' : '',
                size == 'sm' ? 'w-10 h-5' : '',
            ]"
        >
            <div
                :class="[
                    'rounded-full shadow-md transform duration-300 ease-in-out bg-white ',
                    enabled ? 'translate-x-5' : '',
                    size == 'md' ? 'w-4 h-4' : '',
                    size == 'sm' ? 'w-3 h-3' : '',
                ]"
            ></div>
        </div>
        <h2 v-if="!labelLeft" class="ml-2">{{ label }}</h2>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        modelValue: Boolean,
        labelLeft: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: 'md',
        },
    },
    computed: {
        enabled: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            },
        },
    },
}
</script>
