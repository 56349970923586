<template>
    <div class="flex items-center">
        <div class="align-middle mr-2 sm:mr-6">
            <!-- <img :src="require('@/assets/Arrow.svg')" height="25" width="25" alt="arrow" /> -->
            <ArrowSmallRightIcon
                v-if="liststyle === 'arrow'"
                class="h-4 w-4 sm:h-6 sm:w-6 uppercase ml-1 float-left text-white"
            />
            <CheckIcon
                v-if="liststyle === 'check'"
                class="h-4 w-4 sm:h-6 sm:w-6 uppercase ml-1 float-left text-white"
            />
        </div>
        <div class="align-middle"><slot></slot></div>
    </div>
</template>

<script>
import { ArrowSmallRightIcon, CheckIcon } from '@heroicons/vue/24/outline'

export default {
    name: 'BaseList',
    props: {
        liststyle: {
            type: String,
            default: 'arrow',
        },
    },
    components: { ArrowSmallRightIcon, CheckIcon },
}
</script>
