<template>
    <div id="nav" :class="route.meta.navbar !== false ? 'bg-white' : ''">
        <FIBanner />
        <div
            :class="[
                'bg-transparent grid pt-1 content-center mx-auto active:border-transparent',
                route.meta.navbar === false || route.name === 'Country'
                    ? 'h-24 grid-cols-2 lg:grid-cols-4'
                    : 'h-16 sm:h-16 grid-cols-2 lg:grid-cols-4',
            ]"
        >
            <div class="col-span-1">
                <div class="ml-4 pt-0 sm:pt-2">
                    <router-link class="inline-block" :to="countryConfirmed">
                        <img
                            :src="greengatewaylogo"
                            :class="[
                                'hover:opacity-70',
                                route.meta.navbar == false
                                    ? 'w-80 sm:w-96 sm:mt-0 ml-7'
                                    : 'w-64 mt-1 sm:w-64 sm:mt-0 ml-0 sm:ml-7',
                            ]"
                            alt="Green Gateway - Advice for financial institutions"
                            title="Green Gateway - Advice for financial institutions"
                        />
                    </router-link>
                </div>
            </div>
            <div
                :class="[
                    'hidden lg:inline-block lg:col-span-2',
                    route.meta.navbar !== false ? 'text-center' : 'text-right mt-1',
                ]"
            >
                <Country
                    class="hidden lg:inline-block"
                    v-if="route.meta.navbar !== false && route.name != 'Country'"
                />
                <LanguageSwitch class="hidden sm:inline-block" title="Change language" />
                <Settings
                    class="hidden lg:inline-block"
                    v-if="route.meta.navbar !== false && route.name != 'Country'"
                />

                <ShoppingCart
                    class="hidden lg:inline-block"
                    v-if="route.meta.navbar !== false && route.name != 'Country'"
                />
            </div>
            <div class="hidden nav-header-logos lg:col-span-1 mr-7">
                <a
                    class="ml-6 float-right hover:opacity-70"
                    target="_blank"
                    href="https://www.eib.org"
                    alt="European Investment Bank"
                    title="European Investment Bank"
                >
                    <img
                        :src="eiblogo"
                        :class="[route.name === 'Home' ? 'h-10 sm:mt-0' : 'h-10 sm:mt-1']"
                    />
                </a>
                <a
                    class="float-right hover:opacity-70"
                    target="_blank"
                    href="https://ec.europa.eu"
                    title="European Commission"
                    alt="European Commission"
                >
                    <img
                        :src="eclogo"
                        :class="[route.name === 'Home' ? 'h-10 sm:mt-0' : 'h-10 sm:mt-1']"
                    />
                </a>
            </div>

            <div
                :class="[
                    route.meta.navbar || route.name === 'Country'
                        ? 'hidden'
                        : 'inline-block col-span-1',
                ]"
            >
                <div
                    @click="mobileMenuOpen = !mobileMenuOpen"
                    :class="[
                        'cursor-pointer float-right bg-white rounded-md mr-4 w-8 h-8 p-1 mt-1 lg:hidden text-eibbrightblue hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-eibbrightblue',
                    ]"
                >
                    <span class="sr-only">Open menu</span>
                    <Bars3Icon v-if="!mobileMenuOpen" />
                    <XMarkIcon v-if="mobileMenuOpen" />
                </div>
            </div>
        </div>
        <div v-if="mobileMenuOpen" class="border-t-2 border-eiblightgrey py-3 lg:hidden">
            <nav class="grid gap-y-3">
                <a class="flex">
                    <LanguageSwitch class="inline-block" />
                </a>
                <a class="flex border-t-2 border-eiblightgrey pt-3">
                    <Country class="inline-block" />
                </a>
                <a class="flex border-t-2 border-eiblightgrey pt-3">
                    <Settings class="inline-block" />
                </a>
            </nav>
        </div>
        <div
            v-if="stagingCheck === 'staging' || stagingCheck === 'dev'"
            class="text-white bg-greengatewaygreen text-center py-2 italic text-xs md:text-base"
        >
            Attention: This is a technical preview version. Please make use of the official Green
            Eligibility Checker website at
            <a href="https://greenchecker.eib.org" class="underline">
                https://greenchecker.eib.org
            </a>
        </div>
        <div
            v-if="stagingCheck === 'dev'"
            class="flex items-center m-2 fixed bottom-0 right-0 border border-gray-400 rounded p-2 bg-gray-300 text-pink-600 text-sm"
        >
            <svg
                class="h-6 w-auto inline"
                viewBox="0 0 80 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="url(#paint0_linear)"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32 16C24.8 16 20.3 19.6 18.5 26.8C21.2 23.2 24.35 21.85 27.95 22.75C30.004 23.2635 31.4721 24.7536 33.0971 26.4031C35.7443 29.0901 38.8081 32.2 45.5 32.2C52.7 32.2 57.2 28.6 59 21.4C56.3 25 53.15 26.35 49.55 25.45C47.496 24.9365 46.0279 23.4464 44.4029 21.7969C41.7557 19.1099 38.6919 16 32 16ZM18.5 32.2C11.3 32.2 6.8 35.8 5 43C7.7 39.4 10.85 38.05 14.45 38.95C16.504 39.4635 17.9721 40.9536 19.5971 42.6031C22.2443 45.2901 25.3081 48.4 32 48.4C39.2 48.4 43.7 44.8 45.5 37.6C42.8 41.2 39.65 42.55 36.05 41.65C33.996 41.1365 32.5279 39.6464 30.9029 37.9969C28.2557 35.3099 25.1919 32.2 18.5 32.2Z"
                ></path>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="3.5"
                        y1="16"
                        x2="59"
                        y2="48"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#2298BD"></stop>
                        <stop offset="1" stop-color="#0ED7B5"></stop>
                    </linearGradient>
                </defs>
            </svg>
            Current breakpoint
            <span class="ml-1 sm:hidden md:hidden lg:hidden xl:hidden">default (&lt; 640px)</span>
            <span class="ml-1 hidden sm:inline md:hidden font-extrabold">sm</span>
            <span class="ml-1 hidden md:inline lg:hidden font-extrabold">md</span>
            <span class="ml-1 hidden lg:inline xl:hidden font-extrabold">lg</span>
            <span class="ml-1 hidden xl:inline font-extrabold">xl</span>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import {
    // useRouter,
    useRoute,
} from 'vue-router'

import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import LanguageSwitch from '@/components/common/LanguageSwitch.vue'
import ShoppingCart from '@/components/common/ShoppingCart.vue'
import Settings from '@/components/common/Settings.vue'
import Country from '@/components/country/Country.vue'
import eiblogostandard from '@/assets/logo-eib.png'
import eiblogowhite from '@/assets/logo-eib-white.png'
import gatewaylogo from '@/assets/logo-green-gateway-checker.svg'
import gatewaylogowhite from '@/assets/logo-green-gateway-checker-white.svg'
import eclogostandard from '@/assets/logo-european-commission.png'
import eclogowhite from '@/assets/logo-european-commission-white.png'
import FIBanner from '@/components/common/FIBanner.vue'

const mobileMenuOpen = ref(false)

const store = useStore()
const route = useRoute()

const measures = computed(() => {
    return store.getters['measures/measures']
})

const countryConfirmed = computed(() => {
    // if (store.getters['global/countryConfirmed']) {
    //     store.commit('global/currentStep', 1)
    //     return '/assessment'
    // }
    return '/'
})

const eiblogo = computed(() => {
    let logo = eiblogostandard
    if (route.meta.navbar === false) {
        logo = eiblogowhite
    }
    return logo
})

const greengatewaylogo = computed(() => {
    let logo = gatewaylogo
    if (route.meta.navbar === false) {
        logo = gatewaylogowhite
    }
    return logo
})

const eclogo = computed(() => {
    let logo = eclogostandard
    if (route.meta.navbar === false) {
        logo = eclogowhite
    }
    return logo
})

const numberOfMeasures = computed(() => {
    return store.state.measures.measures.length
})

const selectedCountry = computed(() => {
    const storedCountry = store.getters['global/country']
    if (storedCountry) {
        return storedCountry.text
    }
    return 'Selected Country'
})

const stagingCheck = computed(() => {
    if (window.location.host.includes('localhost')) {
        return 'dev'
    }
    if (window.location.host.includes('preview.adelphi.app')) {
        return 'staging'
    }

    return 'production'
})
</script>

<style>
@media (min-width: 1474px) {
    .nav-header-logos {
        display: inline-block !important;
    }
}
</style>
