import { createI18n } from 'vue-i18n'
/*
 * All i18n resources specified in the plugin `include` option can be loaded
 * at once using the import syntax
 */

import en from '@/locales/en.json'
import de from '@/locales/de.json'
import es from '@/locales/es.json'
import fr from '@/locales/fr.json'
import it from '@/locales/it.json'
import pl from '@/locales/pl.json'

const languages = ['en', 'de', 'es', 'fr', 'it', 'pl']
const userLang = localStorage.getItem('chosenLanguage')
const browserLang = window.navigator.language

export const defaultLocale =
    userLang !== null && languages.includes(userLang)
        ? userLang
        : browserLang && languages.includes(browserLang)
        ? browserLang
        : 'en'

// Create VueI18n instance with options
export default createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: 'en',
    fallbackRootWithEmptyString: false,
    silentFallbackWarn: false,
    inheritLocale: false,
    missingWarn: true,
    // messages,
    messages: {
        en,
        de,
        es,
        fr,
        it,
        pl,
    },
})
