const stagingCheck = new RegExp(/(.*)preview.adelphi.app/g)
const productionCheck = new RegExp(/(.*).?greenchecker.eib.org/g)
const reviewCheck = new RegExp(/review-(.*)preview.adelphi.app/g)

function review() {
    return reviewCheck.test(window.location.host)
}

function staging() {
    return stagingCheck.test(window.location.host) && !review()
}

function production() {
    return productionCheck.test(window.location.host)
}

export function getEnvironment() {
    return review() ? 'review' : staging() ? 'staging' : production() ? 'production' : 'development'
}

// Show Matomo in production only
export function useMatomo() {
    return production()
}

// Show Sentry in production and staging / review environments
export function useSentry() {
    return review() || staging() || production()
}

// Output marker.io Project ID
export function getMarkerProjectId() {
    // SBCI
    if (window.location.host === 'sbci.eib-greenchecker.preview.adelphi.app') {
        return '649942ff0ef2001603d7fb08'
    }
    // General Green Checker
    if (window.location.host === 'eib-greenchecker.preview.adelphi.app') {
        return '64958c3a61d96728c9fcaf72'
    }

    return ''
}
