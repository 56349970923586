<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <button
        @click="showBeneficiaryInput = !showBeneficiaryInput"
        type="button"
        :class="[
            'mb-2 py-2 px-4 text-base font-medium ml-1 md:py-2 md:px-3 md:text-sm lg:py-3 lg:px-6 lg:text-base  border-2 relative inline-flex items-center rounded-full justify-center focus:z-10 focus:outline-none',
            beneficiaryDetailsProvided != false
                ? 'border-eibbrightblue bg-eibbrightblue text-md text-white'
                : 'border-gray-300 bg-white text-md text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-eibbrightblue focus:border-eibbrightblue',
        ]"
    >
        <IdentificationIcon
            class="-ml-1 mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
            v-if="!beneficiaryDetailsProvided"
        />
        <CheckIcon
            class="-ml-1 mr-2 h-5 w-5 text-white"
            aria-hidden="true"
            v-if="beneficiaryDetailsProvided"
        />
        {{ t('summary.beneficiaryDetailsBtn') }}
    </button>
    <div
        v-if="showBeneficiaryInput"
        class="mt-6 mb-6 grid grid-cols-1 sm:grid-cols-2 shadow-xl rounded-xl border border-gray-100"
    >
        <div>
            <base-input
                :label="`${t('general.name')}`.concat(':')"
                v-model="beneficiaryDetails.name"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="50"
            ></base-input>
        </div>
        <div>
            <base-input
                :label="`${t('general.address')}`.concat(':')"
                v-model="beneficiaryDetails.address"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="50"
            ></base-input>
        </div>
        <div>
            <base-input
                :label="`${t('general.country')}`.concat(':')"
                v-model="beneficiaryDetails.country"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="50"
            ></base-input>
        </div>
        <div>
            <base-input
                :label="`${t('general.postalCode')}`.concat(':')"
                v-model="beneficiaryDetails.postalCode"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="50"
            ></base-input>
        </div>
        <div>
            <base-input
                :label="`${t('general.city')}`.concat(':')"
                v-model="beneficiaryDetails.city"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="50"
            ></base-input>
        </div>
        <div>
            <base-input
                :label="`${t('general.contactPerson')}`.concat(':')"
                v-model="beneficiaryDetails.contactPerson"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="50"
            ></base-input>
        </div>
        <div class="col-span-2">
            <base-input
                :label="`${t('general.contactDetails')}`.concat(':')"
                v-model="beneficiaryDetails.contactDetails"
                formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
                maxlength="100"
            ></base-input>
        </div>
    </div>
</template>

<script>
import { IdentificationIcon, CheckIcon } from '@heroicons/vue/24/outline'
import BaseInput from '@/components/base/BaseInput.vue'
import { useI18n } from 'vue-i18n'

export default {
    components: {
        IdentificationIcon,
        CheckIcon,
        BaseInput,
    },
    props: { class: { type: String } },

    data() {
        return {
            showBeneficiaryInput: false,
        }
    },
    computed: {
        beneficiaryDetails: {
            set(val) {
                this.$store.commit('global/beneficiaryDetails', val)
            },
            get() {
                return this.$store.getters['global/beneficiaryDetails']
            },
        },
        beneficiaryDetailsProvided() {
            const details = Object.values(this.beneficiaryDetails).filter(vals => vals !== '')
            if (
                details.length === 0 ||
                (details.length === 1 &&
                    this.beneficiaryDetails.country === this.$store.getters['global/country'].text)
            ) {
                return false
            }

            return true
        },
    },
    mounted() {
        if (
            this.$store.getters['global/country'] &&
            Object.keys(this.$store.getters['global/beneficiaryDetails']).length === 0
        ) {
            const beneficiary = {}
            beneficiary.country = this.$store.getters['global/country'].text
            this.$store.commit('global/beneficiaryDetails', beneficiary)
        }
    },
    setup() {
        const { t } = useI18n()
        return { t }
    },
}
</script>
