import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import filters from '@/store/modules/filters'
import global from '@/store/modules/global'
import measures from '@/store/modules/measures'

const store = Vuex.createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: { auth, filters, measures, global },
})

export default store
