<template>
    <div>
        <h4 class="h4 pt-4 sm:mr-0 sm:ml-0 sm:pt-12">
            {{ t('summary.impactsHeading') }}
        </h4>
        <!-- <div class="rounded-md bg-blue-50 p-4 mt-6">
            <div class="flex">
                <div class="flex-shrink-0">
                    <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm font-medium text-blue-700">
                        <span class="font-bold">Please note:</span>
                        The aggregated impact data shown below is currently just a preview and not
                        linked to your specified measures. It shall only give you an impression of
                        the final display of that information.
                    </p>
                </div>
            </div>
        </div> -->
        <div class="pt-4 sm:pt-3">
            <p class="text-eibgrey40 text-sm">
                <span class="font-semibold">{{ t('general.disclaimerHeading') }}</span>
                {{ t('general.toolDisclaimerSummary') }}
            </p>
            <dl class="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-3 sm:grid-cols-2">
                <div
                    v-for="item in stats"
                    :key="item.name"
                    class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
                >
                    <dt
                        class="text-md2 lg:text-md2 md:w-3/4 font-medium text-gray-500 md:text-base"
                    >
                        <span v-html="item.name"></span>
                    </dt>
                    <dd class="mt-3 text-4xl lg:text-4xl md:text-3xl font-bold text-gray-900">
                        <span v-html="item.stat"></span>
                        <span class="inline sm:hidden text-xl" v-html="item.unit"></span>
                    </dd>
                    <dt
                        class="mt-3 hidden sm:inline-block text-xl lg:text-md md:text-md font-medium font-alegreya"
                    >
                        <span v-html="item.unit"></span>
                    </dt>
                </div>
            </dl>
        </div>
    </div>
</template>

<script>
// import EligibleBox from '@/components/common/EligibleBox.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

// import { InformationCircleIcon } from '@heroicons/vue/24/outline'

export default {
    name: 'AggregatedImpacts',
    components: {
        // InformationCircleIcon
    },
    setup() {
        const store = useStore()

        const { t } = useI18n()
        const results = computed(() => {
            return store.getters['measures/results']
        })

        const stats = computed(() => {
            return [
                {
                    name: t('results.primaryEnergySavings'),
                    stat: results.value.primaryEnergySavings,
                    unit: t('general.units.kWhYear'),
                },
                {
                    name: t('results.estimatedEnergySavingPotential'),
                    stat: results.value.energySavings,
                    unit: t('general.units.kWhYear'),
                },
                {
                    name: t('results.estimatedRE'),
                    stat: results.value.electricityGeneration,
                    unit: t('general.units.kWhYear'),
                },
                {
                    name: t('results.estimatedCO2'),
                    stat: results.value.co2savings,
                    unit: t('general.units.kgCO2eYear'),
                },
                {
                    name: t('results.estimatedCostSavings'),
                    stat: results.value.costSavings,
                    unit: t('general.units.euroYear'),
                },
                {
                    name: t('report.investmentCost'),
                    stat: results.value.investmentCosts,
                    unit: t('general.units.euro'),
                },
            ]
        })

        return { stats, results, t }
    },
}
</script>
