<template>
    <button
        @click="showClientReferenceIdInput = !showClientReferenceIdInput"
        type="button"
        :class="[
            'mb-2 py-2 px-4 text-base font-medium ml-1 md:py-2 md:px-3 md:text-sm lg:py-3 lg:px-6 lg:text-base  border-2 relative inline-flex items-center rounded-full justify-center focus:z-10 focus:outline-none',
            clientReferenceId != ''
                ? 'border-eibbrightblue bg-eibbrightblue text-md text-white'
                : 'border-gray-300 bg-white text-md text-gray-700 hover:bg-gray-50 focus:ring-1 focus:ring-eibbrightblue focus:border-eibbrightblue',
        ]"
    >
        <TagIcon
            class="-ml-1 mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
            v-if="clientReferenceId === ''"
        />
        <CheckIcon
            class="-ml-1 mr-2 h-5 w-5 text-white"
            aria-hidden="true"
            v-if="clientReferenceId != ''"
        />
        {{
            fiData && fiData.custom_texts && fiData.custom_texts['general.clientReferenceId']
                ? fiData?.custom_texts['general.clientReferenceId']
                : $t('general.clientReferenceId')
        }}
    </button>
    <div
        v-if="showClientReferenceIdInput"
        class="mt-6 mb-6 shadow-xl rounded-xl border border-gray-100"
    >
        <base-input
            :label="
                fiData &&
                fiData.custom_texts &&
                fiData.custom_texts['general.clientReferenceIdLabel']
                    ? fiData?.custom_texts['general.clientReferenceIdLabel']
                    : $t('general.clientReferenceIdLabel')
            "
            formFieldClasses="grid pt-2 pr-6 pb-6 pl-6"
            v-model="clientReferenceId"
            maxlength="60"
            placeholder="e.g. 4253460503-GH"
        ></base-input>
    </div>
</template>

<script>
import { TagIcon, CheckIcon } from '@heroicons/vue/24/outline'
import BaseInput from '@/components/base/BaseInput.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    components: {
        TagIcon,
        CheckIcon,
        BaseInput,
    },
    props: { class: { type: String } },

    data() {
        return {
            showClientReferenceIdInput: false,
        }
    },
    computed: {
        clientReferenceId: {
            set(val) {
                val = val.trim()
                this.$store.commit('global/clientReferenceId', val)
            },
            get() {
                const clientReferenceId = this.$store.getters['global/clientReferenceId']

                if (clientReferenceId && clientReferenceId !== '') {
                    this.showClientReferenceIdInput = true
                }

                return clientReferenceId
            },
        },
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()

        const fiData = computed(() => {
            return store.getters['auth/financialIntermediary']
        })

        return { t, fiData }
    },
}
</script>
