<template>
    <div :class="embeddingClass">
        <base-select
            :options="countries"
            v-model="newCountry"
            :label="t('country.country')"
            :placeholder="t('country.selector.placeholder')"
            test="CountrySelection"
        />
        <base-checkbox
            :label="t('country.selector.rememberSelection')"
            v-model="remember"
            :v-if="!embedding"
        ></base-checkbox>
        <base-button
            @click="start"
            class="my-4 ml-5"
            test="CountryBtn"
            :disabled="newCountry === ''"
        >
            {{ t(submitButtonText) }}
            <ArrowSmallRightIcon class="h-6 w-6 ml-1 mr-0 float-right" />
        </base-button>
    </div>
</template>

<script>
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
// import { mapState } from 'vuex'

export default {
    components: { BaseSelect, BaseButton, ArrowSmallRightIcon, BaseCheckbox },
    name: 'CountrySelector',
    emits: ['closeCountryModal'],
    props: {
        embedMode: {
            type: Boolean,
            default: false,
        },
        submitButtonText: {
            type: String,
            default: 'country.selector.defaultButtonText',
        },
    },
    setup(props, context) {
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()

        const remember = ref(false)
        const newCountry = ref(null)

        const selectedCountry = computed(() => {
            return store.getters['global/country']
        })

        const countries = computed(() => {
            const fi_countries = store.getters['auth/financialIntermediaryCountries']
            if (fi_countries) {
                return fi_countries
            } else {
                return store.getters['global/countries']
            }
        })

        const rememberSetting = computed(() => {
            const localCountry = localStorage.getItem('country')
            if (localCountry) {
                return true
            }
            return false
        })

        const embeddingClass = computed(() => {
            if (!props.embedMode) {
                return 'w-full sm:w-1/3'
            }
            return 'w-full'
        })

        const embedding = computed(() => {
            return !props.embedMode
        })

        if (rememberSetting.value === true) {
            remember.value = true
        }

        if (selectedCountry.value !== null) {
            newCountry.value = selectedCountry.value
        }

        const start = () => {
            const country = countries.value.filter(c => c.value === newCountry.value)[0]
            if (
                store.getters['global/country'] &&
                newCountry.value !== store.getters['global/country'].value
            ) {
                store.commit('measures/deleteAllMeasures')
            }

            store.dispatch('global/setCountry', country)

            // // Change country for final beneficiary in case nothing else has been set for them, yet
            // const selectedBeneficiaryDetails = this.$store.getters['global/beneficiaryDetails']
            // if (Object.keys(selectedBeneficiaryDetails).length === 1) {
            //     selectedBeneficiaryDetails.country = this.$store.getters['global/country'].text
            //     this.$store.commit('global/beneficiaryDetails', selectedBeneficiaryDetails)
            // }

            // Remove final benficiary data, comment and client / project reference
            store.commit('global/beneficiaryDetails', {})
            store.commit('global/clientReferenceId', '')
            store.commit('global/comment', '')
            store.commit('global/countryConfirmed', true)
            store.commit('global/currentStep', 1)
            router.push('/selection')
            context.emit('closeCountryModal')

            if (remember.value) {
                const storedCountry = store.getters['global/country']
                store.commit('global/storeCountrySelection', storedCountry.value)
            }
            if (!remember.value) {
                store.commit('global/deleteStoredCountrySelection')
            }
        }

        return {
            t,
            remember,
            newCountry,
            embedding,
            embeddingClass,
            rememberSetting,
            countries,

            start,
        }
    },
}
</script>
