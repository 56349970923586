<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <TransitionRoot as="template" :show="open">
        <Dialog
            as="div"
            static
            class="fixed z-40 inset-0 overflow-y-auto"
            @close="open = false"
            :open="open"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    >
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                            >
                                <ExclamationTriangleIcon
                                    class="h-6 w-6 text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <DialogTitle as="h4" class="h4">
                                    {{ title }}
                                </DialogTitle>
                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">
                                        {{ text }}
                                    </p>
                                </div>
                                <slot></slot>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                            <BaseButton
                                @click="hideAlert"
                                :warning="true"
                                test="confirmAlertBtnYes"
                            >
                                {{ confirmBtnText }}
                            </BaseButton>
                            <button
                                type="button"
                                class="hidden inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                            >
                                {{ confirmBtnText }}

                                <TrashIcon class="h-4 w-4 float-right" v-if="confirmBtnIcon" />
                            </button>
                            <BaseButton
                                @click="open = false"
                                :primary="false"
                                classes=" ml-1"
                                test="confirmAlertBtnNo"
                            >
                                {{ cancelBtnText }}
                            </BaseButton>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'

export default {
    name: 'confirmAlert',
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationTriangleIcon,
        TrashIcon,
        BaseButton,
    },
    props: {
        confirmBtnIcon: {
            type: Boolean,
            default: false,
        },
        confirmBtnText: {
            type: String,
            default: 'OK',
        },
        cancelBtnText: {
            type: String,
            default: 'Cancel',
        },
        title: {
            type: String,
            default: 'Title',
        },
        text: {
            type: String,
            default: 'Text',
        },
    },
    setup(props, { emit }) {
        const open = ref(false)
        let value = null

        return {
            open,
            hideAlert() {
                open.value = false
                emit('confirmClicked', value)
            },
            showAlert(valueFromParent) {
                // console.log(valueFromParent)
                value = valueFromParent
                open.value = true
            },
        }
    },
}
</script>
