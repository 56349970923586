<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="bg-gray-50 -mt-32" id="faq">
        <div class="max-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div class="max-w-6xl mx-auto">
                <h2 class="h2 text-center font-extrabold text-gray-900 font-alegreya">
                    {{ t('general.faq') }}
                </h2>
                <dl class="mt-2 space-y-6 divide-y divide-gray-200" v-if="faqs">
                    <!-- Initial FAQ with document and video -->
                    <!-- <Disclosure as="div" :defaultOpen="true" class="pt-6" v-slot="{ open }">
                        <dt class="text-xl">
                            <DisclosureButton
                                class="text-left w-full flex justify-between items-start text-gray-400"
                            >
                                <span class="font-semibold text-gray-900">
                                    {{ t('infoSheetFAQ.question') }}
                                </span>
                                <span class="ml-6 h-7 flex items-center">
                                    <ChevronDownIcon
                                        :class="[
                                            open ? '-rotate-180' : 'rotate-0',
                                            'h-6 w-6 transform',
                                        ]"
                                        aria-hidden="true"
                                    />
                                </span>
                            </DisclosureButton>
                        </dt>
                        <DisclosurePanel as="dd" class="mt-2 pr-12">
                            <div class="text-gray-500 text-lg">
                                <div v-html="t('infoSheetFAQ.answer')"></div>
                                <div class="cursor-pointer mx-auto">
                                    <img
                                        src="@/assets/EIB_Green_Checker_VideoPreview.png"
                                        @click="showVideo"
                                        class="mx-auto"
                                    />
                                </div>
                            </div>
                        </DisclosurePanel>
                    </Disclosure> -->

                    <Disclosure
                        as="div"
                        v-for="question in faqs"
                        :key="question"
                        :defaultOpen="question.default_open"
                        class="pt-6"
                        v-slot="{ open }"
                    >
                        <dt class="text-xl">
                            <DisclosureButton
                                class="text-left w-full flex justify-between items-start text-gray-400"
                                @click="trackFAQ(question.identifier, open)"
                            >
                                <span class="font-semibold text-gray-900">
                                    {{ question.name }}
                                </span>
                                <span class="ml-6 h-7 flex items-center">
                                    <ChevronDownIcon
                                        :class="[
                                            open ? '-rotate-180' : 'rotate-0',
                                            'h-6 w-6 transform',
                                        ]"
                                        aria-hidden="true"
                                    />
                                </span>
                            </DisclosureButton>
                        </dt>
                        <DisclosurePanel as="dd" class="mt-2 pr-12">
                            <p class="text-gray-500 text-lg" v-html="question.description"></p>
                            <div
                                class="cursor-pointer mx-auto"
                                v-if="question.description.includes('<!-- showIntroVideo -->')"
                            >
                                <img
                                    src="@/assets/EIB_Green_Checker_VideoPreview.png"
                                    @click="showVideo"
                                    class="mx-auto"
                                />
                            </div>
                        </DisclosurePanel>
                    </Disclosure>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import {
    ChevronDownIcon,
    // ArrowDownTrayIcon
} from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronDownIcon,
        // ArrowDownTrayIcon,
    },
    setup() {
        const store = useStore()
        const faqs = computed(() => store.getters['global/faqs'])
        // const faqs = store.getters['global/faqs']
        const showVideo = event => {
            let width = 560
            let height = 315

            if (window.innerWidth > 768) {
                width = 700
                height = 394
            }
            if (window.innerWidth > 1280) {
                width = 1100
                height = 618
            }
            var path = event.path || (event.composedPath && event.composedPath())
            // console.log(path)
            // eslint-disable-next-line no-param-reassign
            path[1].innerHTML = `<iframe width="${width}" height="${height}" src="https://www.youtube-nocookie.com/embed/foDcYZfw3W0?controls=0" title="Introduction to the Green Eligibility Checker" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        }
        const { t } = useI18n()

        const trackFAQ = (q, open) => {
            if (!open && window._paq) {
                window._paq.push(['trackEvent', 'FAQs', 'Viewed', q])
            }
        }

        return {
            showVideo,
            t,
            trackFAQ,
            faqs,
        }
    },
}
</script>

<style scoped>
:deep(a) {
    @apply text-eibbrightblue;
}

:deep(p) {
    @apply my-3;
}
</style>
