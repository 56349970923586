<template>
    <div>
        <div class="sticky top-0 z-20">
            <Stepper viewMode="Summary" />
        </div>
        <div class="relative">
            <div>
                <ExplainBox
                    :subheader="t('summary.explain.subheader')"
                    :title="t('summary.explain.title')"
                    :text="t('summary.explain.text')"
                />
            </div>
            <div class="pt-12 pb-6">
                <div class="container mx-auto lg:px-28 px-6 xs:w-full">
                    <AssessmentResults />
                </div>
            </div>
            <div class="pt-12 pb-6" v-if="fidata !== null && fidata.guidance">
                <div class="lg:px-28 px-6 w-fit mx-auto">
                    <div class="rounded-md bg-blue-50 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <InformationCircleIcon
                                    class="h-5 w-5 text-eibbrightblue"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3 flex-1 md:flex md:justify-between">
                                <p class="text-sm text-eibbrightblue">
                                    {{ fidata.guidance }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-3">
                <div class="container mx-auto lg:px-28 px-6 xs:w-full">
                    <div class="flex w-full justify-center gap-4 mb-12">
                        <AdditionalInformation />
                    </div>
                    <div class="flex justify-center gap-4 mb-12">
                        <Webhook
                            v-if="fidata !== null && fidata.webhook"
                            @response="showWebhookResponse"
                        />
                    </div>
                    <div class="flex justify-center gap-4 mb-12">
                        <PDF />
                    </div>

                    <!-- <div v-if="error" class="flex justify-center gap-4 mb-12">
                        <div class="rounded-md bg-red-50 p-4 mt-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-md font-medium text-red-800">
                                        An error occurred:
                                    </h3>
                                    <div class="mt-2 text-sm text-red-700">
                                        {{
                                            error.response.data?.message
                                                ? error.response.data.message
                                                : 'error'
                                        }}
                                    </div>
                                </div>
                                <div class="ml-auto pl-3">
                                    <div class="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                                            @click=";(error = null), (postFailed = false)"
                                        >
                                            <span class="sr-only">Dismiss</span>
                                            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="text-sm lg:text-sm text-center md:text-xs text-eibgrey40">
                        <span class="font-semibold">{{ t('general.disclaimerHeading') }}</span>
                        <div
                            class="w-full md:w-2/3 text-xs text-justify mx-auto"
                            v-html="t('general.disclaimerText')"
                        ></div>
                    </div>
                </div>
            </div>
            <div class="pb-24">
                <div class="container mx-auto lg:px-28 px-6 xs:w-full">
                    <AggregatedImpacts class="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import BaseButton from '@/components/base/BaseButton.vue'
import Stepper from '@/components/assessment/Stepper.vue'
import ExplainBox from '@/components/common/ExplainBox.vue'
import AssessmentResults from '@/components/summary/AssessmentResults.vue'
import AdditionalInformation from '@/components/summary/AdditionalInformation.vue'
import AggregatedImpacts from '@/components/summary/AggregatedImpacts.vue'
import PDF from '@/components/summary/PDF.vue'
import Webhook from '@/components/summary/Webhook.vue'
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon, XCircleIcon } from '@heroicons/vue/24/outline'

export default {
    name: 'Summary',
    components: {
        AssessmentResults,
        BaseButton,
        ExplainBox,
        AdditionalInformation,
        PDF,
        Stepper,
        AggregatedImpacts,
        Webhook,
        InformationCircleIcon,
        XMarkIcon,
        XCircleIcon,
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()
        const error = ref(false)

        const showWebhookResponse = response => {
            error.value = response
            // console.log(response)
        }

        const addMeasure = () => {
            router.push('/selection')
        }

        const fidata = computed(() => {
            return store.getters['auth/financialIntermediary']
        })

        store.commit('global/currentStep', 3)

        return { addMeasure, t, fidata, showWebhookResponse, error }
    },
}
</script>
