<template>
    <div>
        <div class="flex lg:flex-nowrap flex-wrap">
            <teleport to="body">
                <info-modal
                    ref="eibEligibleModalRef"
                    :confirmText="t('eligibility.modalConfirmBtnText')"
                    confirmButtonColor="bg-eibsuccess"
                >
                    <div class="flex flex-wrap" v-if="eibGreenStatus === 'eligible'">
                        <div
                            class="bg-eibsuccess rounded-md py-1 mt-1 mb-6 px-4 mr-4 text-white cursor-pointer"
                        >
                            <span class="uppercase text-center font-medium">
                                {{ t('eligibility.eibgreenTitle') }}
                            </span>
                            <CheckCircleIcon class="h-6 w-5 ml-1 float-right" />
                        </div>
                        <p class="font-alegreya font-medium h4 pb-3">
                            {{ t('eligibility.eibgreenModalHeading') }}
                        </p>
                        <p class="text-base">
                            {{ t('eligibility.eibgreen') }}
                        </p>
                    </div>
                    <div class="flex flex-wrap" v-if="eibGreenStatus === 'ineligible'">
                        <div
                            class="bg-eibineligible rounded-md py-1 mt-1 mb-6 px-4 mr-4 text-white cursor-pointer"
                        >
                            <span class="uppercase text-center font-medium">
                                {{ t('eligibility.noteibgreenTitle') }}
                            </span>
                            <XCircleIcon class="h-6 w-5 ml-1 float-right" />
                        </div>
                        <p class="font-alegreya font-medium h4 pb-3">
                            {{ t('eligibility.noteibgreenModalHeading') }}
                        </p>
                        <p class="text-base">
                            {{ t('eligibility.noteibgreen') }}
                        </p>
                    </div>
                    <div class="flex flex-wrap" v-if="eibGreenStatus === 'error'">
                        <div
                            class="bg-eibineligible rounded-md py-1 mt-1 mb-6 px-4 mr-4 text-white cursor-pointer"
                        >
                            <span class="uppercase text-center font-medium">
                                {{ t('general.serverError') }}
                            </span>
                            <XCircleIcon class="h-6 w-5 ml-1 float-right" />
                        </div>
                        <p class="text-base">
                            The following error was detected successfully:
                            <br />
                            <span class="text-red-500">{{ serverError }}</span>
                            <br />
                            Sorry for the inconvenience caused. We would appreciate if you can send
                            us an
                            <a target="_blank" class="text-eibbrightblue" :href="mailTo">email</a>
                        </p>
                    </div>
                </info-modal>
                <info-modal
                    ref="parisModalRef"
                    :measure="measure"
                    :confirmText="t('eligibility.modalConfirmBtnText')"
                    confirmButtonColor="bg-eibsuccess"
                >
                    <div class="flex flex-wrap" v-if="parisAlignedStatus === 'eligible'">
                        <div
                            class="mb-6 bg-eibmidnightblue60 rounded-md py-1 my-1 px-4 mr-4 text-white cursor-pointer"
                        >
                            <span class="uppercase text-center font-medium">
                                {{ t('eligibility.parisAlignedTitle') }}
                            </span>
                            <CheckCircleIcon class="h-6 w-5 ml-1 float-right" />
                        </div>
                        <p class="font-alegreya font-medium h4 pb-3">
                            {{ t('eligibility.parisAlignedModalHeading') }}
                        </p>
                        <p class="text-base">
                            {{ t('eligibility.parisAligned') }}
                        </p>
                    </div>
                    <div class="flex flex-wrap" v-if="parisAlignedStatus === 'ineligible'">
                        <div
                            class="mb-6 bg-eibineligible rounded-md py-1 my-1 px-4 mr-4 text-white cursor-pointer"
                        >
                            <span class="uppercase text-center font-medium">
                                {{ t('eligibility.notParisAlignedTitle') }}
                            </span>
                            <XCircleIcon class="h-6 w-5 ml-1 float-right" />
                        </div>
                        <p class="font-alegreya font-medium h4 pb-3">
                            {{ t('eligibility.notParisAlignedModalHeading') }}
                        </p>
                        <p class="text-base">
                            {{ t('eligibility.notParisAligned') }}
                        </p>
                    </div>
                </info-modal>
            </teleport>
            <!-- <h3 v-if="label" class="text-lg font-medium py-1 my-1">{{ label }}</h3> -->
            <!-- Loading -->
            <div
                v-if="preCheckLoading && view === 'specification'"
                class="bg-eibgrey60 rounded-md py-1 my-1 px-4 mr-4 text-white cursor-pointer"
            >
                <svg
                    class="animate-spin -ml-1 mr-3 h-6 w-5 text-white float-left"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-50"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                <span class="uppercase text-center font-medium">
                    {{ t('eligibility.checking') }}
                </span>
            </div>

            <!-- EIB GREEN -->
            <div v-if="!preCheckLoading" class="flex flex-wrap">
                <div
                    v-if="eibGreenStatus === 'eligible' || alwaysGreen"
                    @click="this.$refs.eibEligibleModalRef.openModal()"
                    class="bg-eibsuccess rounded-md py-1 my-1 px-4 mr-4 text-white cursor-pointer"
                    data-testid="eligibilityStatus"
                >
                    <span class="uppercase text-center font-medium">
                        {{ t('eligibility.eibgreenTitle') }}
                    </span>
                    <CheckCircleIcon class="h-6 w-5 ml-1 float-right" />
                </div>
                <div
                    v-if="eibGreenStatus === 'ineligible'"
                    @click="this.$refs.eibEligibleModalRef.openModal()"
                    class="bg-eibineligible font-medium rounded-md text-center uppercase py-1 my-1 px-4 mr-4 text-white cursor-pointer"
                    data-testid="eligibilityStatus"
                >
                    {{ t('eligibility.noteibgreenTitle') }}
                    <XCircleIcon class="h-6 w-5 uppercase ml-1 float-right" />
                </div>
                <div
                    v-if="eibGreenStatus === 'incomplete' && !preCheckLoading"
                    class="bg-eibgrey60 font-medium rounded-md text-center uppercase py-1 my-1 px-4 mr-4 text-white"
                >
                    {{ t('general.incomplete') }}
                    <!-- <ExclamationCircleIcon class="h-6 w-5 uppercase ml-1 float-right" /> -->
                </div>
                <div
                    v-if="eibGreenStatus === 'error' && !preCheckLoading"
                    @click="this.$refs.eibEligibleModalRef.openModal()"
                    class="bg-orange-400 font-medium rounded-md text-center uppercase py-1 my-1 px-4 mr-4 text-white"
                >
                    {{ t('general.serverError') }}
                    <ExclamationCircleIcon class="h-6 w-5 uppercase ml-1 float-right" />
                </div>

                <!-- Paris aligned -->
                <div
                    v-if="
                        eibGreenStatus === 'ineligible' &&
                        parisAlignedStatus === 'eligible' &&
                        view === 'specification'
                    "
                    @click="this.$refs.parisModalRef.openModal()"
                    class="rounded-md py-1 my-1 px-4 mr-4 text-white cursor-pointer bg-eibmidnightblue60"
                    data-testid="eligibilityStatus"
                >
                    <span class="uppercase text-center font-medium">
                        {{ t('eligibility.parisAlignedTitle') }}
                    </span>
                    <CheckCircleIcon class="h-6 w-5 ml-1 float-right" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
// import { DialogTitle } from '@headlessui/vue'
import { XCircleIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import infoModal from '@/components/base/infoModal.vue'
import { useI18n } from 'vue-i18n'

export default {
    props: { measure: Object, label: String, view: String },
    components: { XCircleIcon, CheckCircleIcon, ExclamationCircleIcon, infoModal },
    setup(props) {
        const { t } = useI18n()
        const store = useStore()
        const eibEligibleModalRef = ref(false)
        const parisModalRef = ref(false)
        const triggerOpenEIBModal = () => {
            eibEligibleModalRef.value.openModal()
        }
        const triggerOpenParisModal = () => {
            parisModalRef.value.openModal()
        }
        const currentMeasurePreCheckResult = computed(() => {
            return store.getters['measures/currentMeasureResultPreCheck']
        })

        const preCheckLoading = computed(() => {
            return store.getters['measures/preCheckLoading']
        })
        const serverError = computed(() => {
            return store.getters['measures/serverError']
        })

        const alwaysGreen = computed(() => {
            if (
                props.measure &&
                props.measure.result &&
                props.measure.result.reporting_info &&
                props.measure.result.reporting_info.waive_eligibility_check
            ) {
                return true
            }
            return false
        })
        const mailTo = computed(() => {
            // Leider kann der body nicht alle Zeichen aufnehmen
            return (
                'mailto:greenchecker@eib.org?subject=Server error detected in ' +
                props.measure.identifier +
                '&body=' +
                JSON.stringify(props.measure.model).substring(0, 1024)
            )
        })

        const eibGreenStatus = computed(() => {
            return store.getters['measures/eibGreenStatus'](props.measure, props.view)
        })

        const parisAlignedStatus = computed(() => {
            return store.getters['measures/parisAlignedStatus'](props.measure, props.view)
        })

        // console.log(props.measure)

        return {
            preCheckLoading,
            serverError,
            alwaysGreen,
            eibGreenStatus,
            parisAlignedStatus,
            triggerOpenEIBModal,
            triggerOpenParisModal,
            currentMeasurePreCheckResult,
            t,
            mailTo,
        }
    },
}
</script>
