<template>
    <div class="fixed inset-x-0 bottom-0" v-if="showConsentBanner" data-testid="consent-banner">
        <div class="bg-eiblightgrey">
            <div class="max-w-4xl mx-auto py-2 sm:py-4 lg:py-6 px-2 sm:px-4 lg:px-6">
                <div class="flex flex-wrap items-center">
                    <div class="p-6 items-center">
                        <p class="ml-3 mr-4 text-eib">
                            Our website uses anonymised cookies to give you the best browser
                            experience and to collect aggregated statistics.
                            <span class="underline">This does not include</span>
                            online advertising cookies.
                        </p>
                    </div>
                    <div
                        class="p-2 sm:p-6 sm:ml-3 items-center justify-center text-center flex-grow"
                    >
                        <base-button @click="acceptAll()" class="mt-2">
                            <CheckIcon
                                class="h-5 w-5 mr-2 text-white float-left group-hover:text-eibbrightblue"
                            />
                            {{ t('general.cookieConsent.Understood') }}
                        </base-button>
                        <base-button @click="refuseNonEssential()" class="ml-3 mt-2">
                            <XMarkIcon
                                class="h-5 w-5 mr-2 text-white float-left group-hover:text-eibbrightblue"
                            />
                            {{ t('general.cookieConsent.refuseNonEssential') }}
                        </base-button>
                        <base-button
                            :primary="false"
                            :secondary="true"
                            class="ml-3 mt-2"
                            @click="show()"
                        >
                            {{ t('general.cookieConsent.manageCookies') }}
                            <ChevronRightIcon
                                class="h-5 w-5 ml-2 text-eibbbrightblue float-right"
                            />
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CheckIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'
import { useI18n } from 'vue-i18n'

function getCookie(cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export default {
    components: {
        XMarkIcon,
        CheckIcon,
        BaseButton,
        ChevronRightIcon,
    },
    methods: {
        acceptAll() {
            // eslint-disable-next-line no-underscore-dangle
            if (window._paq) {
                window._paq.push(['rememberConsentGiven'])
            } else {
                localStorage.setItem('mtm_not_available', true)
            }
            this.showConsentBanner = false
        },
        refuseNonEssential() {
            // eslint-disable-next-line no-underscore-dangle
            if (window._paq) {
                window._paq.push(['optUserOut'])
            } else {
                localStorage.setItem('mtm_not_available', true)
            }
            this.showConsentBanner = false
        },
        show() {
            this.showConsentBanner = false
            this.$router.push({ path: '/privacy', hash: '#cookies' })
        },
    },
    data() {
        return {
            consentGiven: false,
        }
    },
    computed: {
        showConsentBanner: {
            get() {
                const consentstatus = getCookie('mtm_consent')
                const consentremoved = getCookie('mtm_consent_removed')
                if (this.consentGiven === true || consentstatus !== '' || consentremoved !== '') {
                    return false
                }
                if (consentstatus === '' || consentstatus === undefined) {
                    return true
                }
                return true
            },
            set(newValue) {
                if (newValue === false) {
                    this.consentGiven = true
                }
            },
        },
    },
    setup() {
        const { t } = useI18n()
        return { t }
    },
}
</script>
