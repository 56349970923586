<template>
    <div
        v-if="enabled"
        class="fixed bottom-0 right-0 w-16 h-16 xs:mr-4 xs:mb-6 md:mr-8 md:mb-12 cursor-pointer"
    >
        <button class="bg-eibbrightblue rounded-full focus:outline-none" @click="scrollToTop">
            <ChevronUpIcon class="h-10 w-10 p-2 text-white" />
        </button>
    </div>
</template>

<script>
import { inject, ref, computed, onMounted, onUnmounted } from 'vue'
import { ChevronUpIcon } from '@heroicons/vue/24/outline'

export default {
    components: { ChevronUpIcon },
    setup() {
        const smoothScroll = inject('smoothScroll')
        const scrollY = ref(0)
        const enabled = computed(() => {
            if (scrollY.value > 500) {
                return true
            }
            return false
        })
        const scrollToTop = () => {
            // window.scrollTo(0, 0)
            smoothScroll({
                scrollTo: 0,
            })
        }
        const handleScroll = () => {
            scrollY.value = window.scrollY
        }
        onMounted(() => {
            window.addEventListener('scroll', handleScroll)
        })
        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll)
        })

        return { scrollToTop, enabled }
    },
}
</script>
