<template>
    <div>
        <div class="relative">
            <div class="bg-gray-100">
                <div class="container sm:mx-auto mb-0 py-6 w-2/3 sm:py-12">
                    <h3
                        class="sm:text-lg lg:text-4xl font-semibold sm:font-bold font-alegreya pb-6"
                    >
                        Online Data Protection Policy for
                        <a href="https://greenchecker.eib.org">greenchecker.eib.org</a>
                    </h3>

                    <p class="my-3">
                        We appreciate your interest in our online tools, i.e. the EIB Green
                        Eligibility Checker, and want you to feel secure about the protection of
                        your personal data when visiting this website. We take the protection of
                        your personal data seriously. We want you to know when we collect particular
                        data, and how we use it. In the following, we explain the type, scope and
                        purpose of data collection and the use of your personal data. This
                        information can be retrieved at any time from our website.
                    </p>

                    <p class="my-3">
                        In order to guarantee the security of your personal data, such data will be
                        assiduously protected against unauthorised access and unauthorised
                        disclosure, and will not be conveyed to third parties without authorisation.
                    </p>

                    <p class="my-3">
                        Please direct any questions regarding this Statement of Online Data
                        Protection Policy or regarding our Privacy Policy more generally to our
                        (external) data protection officer (contact details see below).
                    </p>
                </div>
            </div>
            <div>
                <div class="container sm:mx-auto mb-0 pt-6 w-2/3 sm:py-12">
                    <p class="text-xl font-bold">I. Name and address of controller</p>

                    <p class="my-3">
                        The controller as defined in the General Data Protection Regulation and
                        other data protection laws nationally applicable in the EU member states or
                        other regulations related to data protection is:
                    </p>

                    <p class="my-3">
                        adelphi
                        <br />
                        Alt-Moabit
                        <br />
                        10559 Berlin
                        <br />
                        Germany
                        <br />
                        <br />
                        Phone +49 30 8900068-0
                        <br />
                        Fax +49 30 8900068-10
                        <br />
                        email:
                        <a href="mailto:office@adelphi.de">office@adelphi.de</a>
                        <br />
                        Website:
                        <a href="https://www.adelphi.de" target="_blank">https://www.adelphi.de</a>
                    </p>

                    <p>&nbsp;</p>

                    <p class="text-xl font-bold">Name and address of data protection officer</p>

                    <p>The controller’s data protection officer is:</p>

                    <p class="my-3">
                        Sema Karakas
                        <br />
                        c/o Althammer &amp; Kill GmbH &amp; Co. KG
                        <br />
                        Thielenplatz 3
                        <br />
                        30159 Hanover
                        <br />
                        Germany
                        <br />
                        <br />
                        Phone: +49 (511) 330603-26
                        <br />
                        email:
                        <a href="mailto:privacy@adelphi.de">privacy@adelphi.de</a>
                        <br />
                        Website:
                        <a href="https://www.althammer-kill.de" target="_blank">
                            https://www.althammer-kill.de
                        </a>
                    </p>
                </div>
            </div>
            <div class="bg-gray-100">
                <div class="container sm:mx-auto mb-0 py-6 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">
                        Purpose of the website and respective data processing
                    </p>

                    <p class="my-3">
                        The use of our site is possible without disclosing personal data. Different
                        terms and conditions can apply in respect to the various services on our
                        website, as will be discussed separately below. The voluntary entry of your
                        personal details (for example, your name, address, email, phone number and
                        the like) will only be processed by us according to the provisions of
                        Germany’s data protection laws and will not be passed on to a third party
                        without your express consent. Data will be considered personal if they can
                        be clearly associated with a specific person.
                    </p>

                    <p class="text-lg font-semibold">
                        Data entered in the Green Eligibility Checker (greenchecker.eib.org)
                    </p>

                    <p class="my-3">
                        The Green Checker is intended to be used by (economic) entities who are
                        interested in implementing an climate positive investment measure and in the
                        respective financing possibilities through the EIB. The main purposes of the
                        Green Checker are the following:
                    </p>

                    <ol class="list-decimal pl-12 list-inside pl-6">
                        <li>
                            Give users a feedback on the potential eligibility of their investment
                            project for a EIB Green MBIL loan.
                        </li>
                        <li>
                            Provide users the possibility to obtain an estimate on energy, CO₂ and
                            cost savings that can be implied by several standard energy efficiency
                            measures.
                        </li>
                        <li>
                            Summarize the Green Checkers' estimate on energy, CO₂ and cost savings,
                            the indication on EIB Green eligibility and the user’s data input in a
                            pdf summary which the user can download on the last page of the Green
                            Checker. The user can use this pdf summary to present the planned
                            investment to a relevant bank (i.e. a bank participating in the EIB
                            Green instrument).
                        </li>
                    </ol>

                    <p class="my-3">
                        To facilitate these purposes, the user of the Green Asssessment is asked to
                        enter personal as well as non-personal data into the Green Checker. Whether
                        data is considered personal or non-personal depends on the type of user
                        (i.e. private individual vs. legal entity). For example, the Green Checker
                        requests the following information from the user:
                    </p>

                    <ul class="list-disc list-inside pl-6">
                        <li class="text-align-justify">
                            Technological details of the planned energy efficiency measure
                            (non-personal data)
                        </li>
                        <li class="text-align-justify">
                            Energy consumption in the previous year (personal data if private
                            individuals are concerned, non-personal in the case of legal entities)
                        </li>
                        <li class="text-align-justify">
                            Estimated energy savings from an energy audit or similar, if available
                            (personal data if private individuals are concerned, non-personal in the
                            case of legal entities)
                        </li>
                        <li class="text-align-justify">
                            Investment costs of the project planned for implementation (non-personal
                            data)
                        </li>
                        <li class="text-align-justify">
                            Contact details of the person filling in the Green Asssessment (personal
                            data)
                        </li>
                        <li class="text-align-justify">
                            Address and type of the concerned entity (personal data if private
                            individuals are concerned, non-personal in the case of legal entities)
                        </li>
                    </ul>

                    <p class="my-3">
                        All personal and non-personal data which is entered by the user in the Green
                        Checker is only stored temporarily. This temporary storage of data is
                        necessary in order to process the data and hence provide direct feedback to
                        the user (e.g. estimated CO₂ savings) and to produce the pdf summary at the
                        end of the Green Checker. The data entered by the user is not stored
                        permanently and is not passed on to third parties.
                    </p>

                    <p class="my-3">
                        The pdf summary which can be produced on the last page of the Green Checker
                        is only available to the user. That is, the pdf summary/the information
                        contained there-in will only be conveyed to a third-party if the user
                        pro-actively shares this pdf summary (e.g. if the user sends an email with
                        the pdf summary to another party).
                    </p>
                </div>
            </div>
            <div class="bg-white">
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">General information about data processing</p>

                    <p class="text-lg font-semibold">Extent of personal data processing</p>

                    <p class="my-3">
                        We process our users’ personal data only to the extent required for
                        providing a functional website and supplying our content and services. We
                        process our users’ personal data regularly only if the respective users have
                        given their consent. The only exception to this is where it is actually
                        impossible for us to obtain prior consent and processing of the data is
                        legally allowed.
                    </p>

                    <p class="text-lg font-semibold">Legal basis for processing personal data</p>

                    <p class="my-3">
                        Where we obtain the corresponding data subjects’ consent for processing
                        their personal data, art. 6 paragraph 1 point a of the EU General Data
                        Protection Regulation (GDPR) serves as the legal basis.
                    </p>

                    <p class="my-3">
                        Where we need to process personal data for the purposes of fulfilling a
                        contract, and the data subject is party to the contract, art. 6 paragraph 1
                        point b of the GDPR serves as the legal basis. This also applies to
                        processing necessary to accommodate preparations for entering into a
                        contract.
                    </p>

                    <p class="my-3">
                        Where processing of personal data is necessary for our company to fulfil a
                        legal obligation, art. 6 paragraph 1 point c of the GDPR serves as the legal
                        basis.
                    </p>

                    <p class="my-3">
                        Where processing of personal data is necessary for protecting the vital
                        interests of the data subject, or those of another individual, art. 6
                        paragraph 1 point d of the GDPR serves as the legal basis.
                    </p>

                    <p class="my-3">
                        Where processing is necessary to protect our company's or a third party’s
                        legitimate interests, and such interests are not overridden by the
                        interests, fundamental rights and freedoms of the data subject, art. 6
                        paragraph 1 point f of the GDPR serves as the legal basis.
                    </p>

                    <p class="text-lg font-semibold">Deletion of data and data storage period</p>

                    <p class="my-3">
                        The data subject’s personal data will be deleted or blocked as soon as the
                        purpose for which it has been collected has been fulfilled. Data may remain
                        on record beyond this period if such is specified in European or national
                        legislation from European Union Regulations, laws or other provisions to
                        which the controller is subject. Data will also be deleted if a storage
                        period specified in the above standards expires unless conclusion or
                        fulfilment of a contract requires the data to remain on record further.
                    </p>
                </div>
            </div>
            <div class="bg-gray-100">
                <div class="container sm:mx-auto mb-0 py-6 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">
                        Provision of website and creation of log files
                    </p>

                    <p class="text-lg font-semibold">Details and extent of data processing</p>

                    <p class="my-3">
                        Any time our website is accessed, our system automatically records data and
                        information concerning the accessing computer.
                    </p>

                    <p class="my-3">The following data is recorded:</p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            Information on the browser type and version used
                        </li>
                        <li class="text-align-justify">The user’s operating system</li>
                        <li class="text-align-justify">The user’s Internet service provider</li>
                        <li class="text-align-justify">The user’s IP address</li>
                        <li class="text-align-justify">Date and time of access</li>
                        <li class="text-align-justify">
                            Websites from which the user’s system reaches our website
                        </li>
                        <li class="text-align-justify">
                            Websites the user’s system accesses from our website
                        </li>
                    </ol>

                    <p class="my-3">
                        This data is also recorded in our system’s log files. This data is not
                        stored together with any of the user’s other personal data.
                    </p>

                    <p class="text-lg font-semibold">Legal basis for data processing</p>

                    <p class="my-3">
                        The legal basis for temporary recording of this data in our log files is
                        art. 6 paragraph 1 point f GDPR.
                    </p>

                    <p class="text-lg font-semibold">Purpose of data processing</p>

                    <p class="my-3">
                        Our system needs to temporarily record the IP address in order to provide
                        the website to the user’s computer. This also requires that the user’s IP
                        address remains logged throughout the session.
                    </p>

                    <p class="my-3">
                        Recording the data in log files is necessary to ensure that the website
                        operates correctly. The data further helps us optimise the website and
                        ensure that our computer systems remain secure. No data is processed for
                        marketing purposes in this context.
                    </p>

                    <p class="my-3">
                        The above purposes also constitute our legitimate interests in data
                        processing under art. 6 paragraph 1 point f GDPR.
                    </p>

                    <p class="text-lg font-semibold">Data storage period</p>

                    <p class="my-3">
                        The data is deleted as soon as it is no longer required for achieving the
                        purpose for which it was recorded. With respect to data being recorded in
                        order to provide the website, the data is no longer required as soon as the
                        respective session ends.
                    </p>

                    <p class="my-3">
                        With respect to data being recorded in log files, the data is no longer
                        required after fourteen days at the latest. Data may remain on record for
                        longer. If so, the users’ IP addresses are deleted or rendered untraceable
                        to make identification of the accessing client impossible.
                    </p>

                    <p class="text-lg font-semibold">Right to object and options for avoidance</p>

                    <p class="my-3">
                        The website cannot be provided without recording the data and the operation
                        of the site in the Internet is impossible without storing the data in log
                        files. There is correspondingly no option for the user to object.
                    </p>
                </div>
            </div>
            <div class="bg-white" id="cookies">
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">Use of cookies</p>

                    <p class="text-lg font-semibold">Details and extent of data processing</p>

                    <p class="my-3">
                        Our website uses cookies. Cookies are text files saved in the Internet
                        browser or by the Internet browser on the user’s computer. When a user
                        accesses a website, a cookie may be stored in the user’s operating system.
                        This cookie contains a unique character string that allows the website to
                        identify the browser when it accesses the website again.
                    </p>

                    <p class="my-3">
                        We use cookies to improve the user experience when accessing our website.
                        Some of our website’s elements need to be able to identify the accessing
                        browser even after it has left the site.
                    </p>

                    <p class="my-3">
                        The following data is recorded and transferred in the cookies:
                    </p>

                    <p class="text-md font-semibold">Website preferences</p>

                    <p class="my-3">
                        These cookies enable the site to operate correctly. Without these cookies we
                        cannot remember choices you’ve selected or provide the services you’ve
                        requested.
                    </p>

                    <SwitchGroup as="div" class="flex items-center">
                        <Switch
                            disabled
                            class="bg-indigo-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500', opacity-50"
                        >
                            <span
                                aria-hidden="true"
                                class="translate-x-5 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',"
                            />
                        </Switch>
                        <SwitchLabel as="span" class="ml-3 cursor-pointer">
                            <span class="text-gray-900">Functional cookies are required</span>
                        </SwitchLabel>
                    </SwitchGroup>

                    <div class="my-3">
                        <table>
                            <thead class="bg-eibbrightblue text-white">
                                <tr>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Cookie Name
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Purpose
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Description of use
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Expiry
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(cookie, cookieIdx) in functionalCookies"
                                    :key="cookie.name"
                                    :class="cookieIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                                >
                                    <td
                                        class="px-6 py-4 sm:whitespace-nowrap text-sm font-medium text-gray-900"
                                    >
                                        {{ cookie.name }}
                                    </td>
                                    <td
                                        class="lg:whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                                    >
                                        {{ cookie.purpose }}
                                    </td>
                                    <td class="px-6 py-4 text-sm text-gray-500">
                                        {{ cookie.description }}
                                    </td>
                                    <td
                                        class="sm:whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                                    >
                                        {{ cookie.expiry }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p class="text-md font-semibold">Website analysis cookies</p>

                    <p class="my-3">
                        These cookies help us to monitor the functions of the website and keep
                        improving website services. These cookies do not collect, process,
                        disseminate or record attributable personal data.
                    </p>

                    <SwitchGroup as="div" class="flex items-center">
                        <Switch
                            v-model="enabled"
                            :class="[
                                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            ]"
                        >
                            <span
                                aria-hidden="true"
                                :class="[
                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                ]"
                            />
                        </Switch>
                        <SwitchLabel as="span" class="ml-3 cursor-pointer">
                            <span class="text-gray-900" v-if="enabled">
                                Analysis cookies are accepted
                            </span>
                            <span class="text-gray-900" v-if="!enabled">
                                Analysis cookies are not accepted
                            </span>
                        </SwitchLabel>
                    </SwitchGroup>

                    <div class="my-3">
                        <table>
                            <thead class="bg-eibbrightblue text-white">
                                <tr>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Cookie Name
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Service
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Purpose
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Description of use
                                    </th>
                                    <th
                                        class="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                    >
                                        Expiry
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(acookie, acookieIdx) in analyticalCookies"
                                    :key="acookie.name"
                                    :class="acookieIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                                >
                                    <td
                                        class="px-6 py-4 sm:whitespace-nowrap text-sm font-medium text-gray-900"
                                    >
                                        {{ acookie.name }}
                                    </td>
                                    <td
                                        class="px-6 py-4 text-sm text-gray-500"
                                        v-html="acookie.service"
                                    ></td>
                                    <td
                                        class="lg:whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                                    >
                                        {{ acookie.purpose }}
                                    </td>
                                    <td class="px-6 py-4 text-sm text-gray-500">
                                        {{ acookie.description }}
                                    </td>
                                    <td
                                        class="sm:whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                                    >
                                        {{ acookie.expiry }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p class="my-3">
                        User data recorded this way is pseudonymised through technical measures. It
                        cannot be used to identify the accessing user. This data is not stored
                        together with any of the user’s other personal data.
                    </p>

                    <p class="text-lg font-semibold">Legal basis for data processing</p>

                    <p class="my-3">
                        The legal basis for using cookies to process personal data is art. 6
                        paragraph 1 point f GDPR.
                    </p>

                    <p class="text-lg font-semibold">Purpose of data processing</p>

                    <p class="my-3">
                        The purpose of using technically necessary cookies is to make using our
                        website easier for users. Several of our website’s functions will not work
                        without using cookies. These functions require the browser to be recognised
                        again after leaving and returning to our website.
                    </p>

                    <p class="my-3">
                        The user data recorded in technically necessary cookies is not used to
                        create user profiles.
                    </p>

                    <p class="my-3">
                        We use analysis cookies to improve the quality of our website and its
                        contents. The analysis cookies tell us how the website is being used and
                        this way allow us to keep on improving it.
                    </p>

                    <p class="my-3">
                        For further details on the analysis cookies used, you may also refer to the
                        section on web analysis by Matomo below.
                    </p>

                    <p class="my-3">
                        The above purposes also constitute our legitimate interests in processing
                        personal data under art. 6 paragraph 1 point f GDPR.
                    </p>

                    <p class="text-lg font-semibold">
                        Data storage period, right to object and options for avoidance
                    </p>

                    <p class="my-3">
                        Cookies are stored on the user’s computer and transferred to us by that
                        computer. As the user, you therefore have complete control over the use of
                        cookies. You can restrict or prevent your computer from sending cookies by
                        adjusting your Internet browser’s settings. You can delete any saved cookies
                        at any time. You can even automate deletion. If you disable cookies for our
                        website, you may no longer be able to use the site’s full range of
                        functions.
                    </p>
                </div>
            </div>
            <div class="bg-gray-100">
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold">Encryption</p>

                    <p class="my-3">
                        To keep your data secure during transmission, we use the latest state of the
                        art in encryption technology (e.g. TLS/SSL) via HTTPS.
                    </p>
                </div>
            </div>
            <div class="bg-white">
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">Contact by email</p>

                    <p class="text-lg font-semibold">Details and extent of data processing</p>

                    <p class="my-3">
                        You have the option to contact us under the email address provided. If you
                        do so, we will store the personal user data included in the email.
                    </p>

                    <p class="my-3">
                        We will not give this data to anybody else. The data will be used solely for
                        handling our conversation.
                    </p>

                    <p class="text-lg font-semibold">Legal basis for data processing</p>

                    <p class="my-3">
                        The legal basis for processing data received as part of email communication
                        is art. 6 paragraph 1 point f GDPR. If email communication pursues
                        conclusion of a contract, the legal basis shall further be art. 6 paragraph
                        1 point b GDPR.
                    </p>

                    <p class="text-lg font-semibold">Purpose of data processing</p>

                    <p class="my-3">
                        Where users contact us via email, this also constitutes the legitimate
                        interest in processing the data.
                    </p>

                    <p class="text-lg font-semibold">Data storage period</p>

                    <p class="my-3">
                        The data is deleted as soon as it is no longer required for achieving the
                        purpose for which it was recorded. In terms of the personal data received by
                        email, this applies when the respective conversation with the user has
                        concluded. The conversation has concluded when the circumstances indicate
                        that the respective subject has been fully resolved.
                    </p>

                    <p class="text-lg font-semibold">Right to object and options for avoidance</p>

                    <p class="my-3">
                        All users can at any time withdraw their consent to our processing their
                        personal data. If a user contacts us by email, they can object at any time
                        to our storing their personal data. If they do so, the conversation cannot
                        be pursued further.
                    </p>

                    <p class="my-3">
                        You can withdraw your consent and object to our storing data by phone (name
                        and address of controller) or by sending an email to withdrawal@adelphi.de.
                    </p>

                    <p class="my-3">
                        If you do so, we will delete all personal data recorded as part of our
                        contact.
                    </p>
                </div>
            </div>

            <div class="bg-gray-100">
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">Embedded YouTube videos</p>

                    <p class="my-3">
                        We embed YouTube videos on some of our websites. These plug-ins are operated
                        by YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you access a
                        web page with the YouTube plug-in and click the video, you will be connected
                        to YouTube’s servers. When this happens, YouTube receives information on the
                        sites you are visiting. If you are logged in to your YouTube account,
                        YouTube will be able to trace your surfing behaviour. You can prevent this
                        by logging out of your YouTube account beforehand.
                    </p>
                    <p class="my-3">
                        When you start a YouTube video, the provider might use cookies that collect
                        data on user behaviour.
                    </p>
                    <p class="my-3">
                        If you have disabled cookies for the Google Ad program, these YouTube
                        cookies will also be disabled. However, YouTube might store further,
                        non-personal user data in other cookies. If you want to prevent this, you
                        will need to disable cookies in your browser settings.
                    </p>
                    <p class="my-3">
                        For more information on data protection and YouTube, refer to the provider’s
                        data policy here:
                        <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">
                            https://www.google.com/intl/en/policies/privacy/
                        </a>
                    </p>
                </div>
            </div>

            <div>
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold pb-6">Web analysis by Matomo (formerly PIWIK)</p>

                    <p class="text-lg font-semibold">Extent of personal data processing</p>

                    <p class="my-3">
                        We use the open-source tool Matomo (formerly PIWIK) on our website to
                        analyse our users’ surfing behaviour. The software stores a cookie on the
                        user’s computer (see above for more on cookies). When any part of our
                        website is accessed, the following data is stored:
                    </p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            Two bytes of the accessing system’s IP address
                        </li>
                        <li class="text-align-justify">Date and time the site was accessed</li>
                        <li class="text-align-justify">Website accessed (name and URL)</li>
                        <li class="text-align-justify">
                            Website from which the user has reached the accessed website (referrer)
                        </li>
                        <li class="text-align-justify">
                            Subdomains accessed from the accessed website
                        </li>
                        <li class="text-align-justify">Duration of the visit to website</li>
                        <li class="text-align-justify">How often the website is accessed</li>
                        <li class="text-align-justify">Screen resolution</li>
                        <li class="text-align-justify">Time as in user’s time zone</li>
                        <li class="text-align-justify">Files clicked and downloaded</li>
                        <li class="text-align-justify">Links clicked to external websites</li>
                        <li class="text-align-justify">
                            Page build-up time (time required for the page to be generated and
                            displayed)
                        </li>
                        <li class="text-align-justify">
                            User’s location: country, region, town, approximate latitude and
                            longitude (geoposition) based on Internet access point
                        </li>
                        <li class="text-align-justify">Browser’s principal language</li>
                        <li class="text-align-justify">Browser’s user agent</li>
                        <li class="text-align-justify">
                            Information about the selected measures (type, entered investment
                            amounts and eligibility)
                        </li>
                    </ol>

                    <p class="my-3">
                        This software runs only on the servers hosting our website. Users’ personal
                        data is saved there and nowhere else. The data is not made accessible to
                        third parties.
                    </p>

                    <p class="my-3">
                        The software is set so that it does not save the full IP address but instead
                        masks 2 bytes of the IP address (example: 192.168.xxx.xxx). This makes it
                        impossible to associate the truncated IP address with the accessing
                        computer.
                    </p>

                    <p class="text-lg font-semibold">Legal basis for processing personal data</p>

                    <p class="my-3">
                        The legal basis for processing the users’ personal data is art. 6 paragraph
                        1 point f GDPR.
                    </p>

                    <p class="text-lg font-semibold">Purpose of data processing</p>

                    <p class="my-3">
                        Processing the users’ personal data allows us to analyse their surfing
                        behaviour. By analysing the obtained data, we can compile information on how
                        the various components of our website are used. This helps us keep on
                        improving our website and its user experience. The above purposes also
                        constitute our legitimate interests in processing the data under art. 6
                        paragraph 1 point f GDPR. Anonymising the IP addresses adequately satisfies
                        the users’ interests in protecting their personal data.
                    </p>

                    <p class="text-lg font-semibold">Data storage period</p>

                    <p class="my-3">
                        The data is deleted as soon as we no longer need them for our records.
                    </p>

                    <p class="my-3">In our cases, this corresponds to six months later.</p>

                    <p class="text-lg font-semibold">Right to object and options for avoidance</p>

                    <p class="my-3">
                        Cookies are stored on the user’s computer and transferred to us by that
                        computer. As the user, you therefore have complete control over the use of
                        cookies. You can restrict or prevent your computer from sending cookies by
                        adjusting your Internet browser’s settings. You can delete any saved cookies
                        at any time. You can even automate deletion. If you disable cookies for our
                        website, you may no longer be able to use the site’s full range of
                        functions.
                    </p>

                    <p class="my-3">
                        We offer our users the option to opt out of the analysis process. To do so,
                        click on the corresponding button in the Cookies chapter above. This will
                        save another cookie to your system which tells our system not to store your
                        user data. If you delete this cookie from your system later, you will need
                        to set the opt-out cookie again.
                    </p>
                </div>
            </div>
            <div class="">
                <div class="container sm:mx-auto mb-0 w-2/3 sm:py-12">
                    <p class="text-xl font-bold">Data subject’s rights</p>

                    <p class="my-3">
                        If your personal data is processed, you are a data subject as defined in the
                        GDPR and consequently have the following rights:
                    </p>

                    <p class="text-lg font-semibold">Right of access</p>

                    <p class="my-3">
                        You are entitled to request information from the controller on whether we
                        are processing any personal data related to yourself.
                    </p>

                    <p class="my-3">
                        If we do, you can further request information from the controller on the
                        following:
                    </p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            the purposes to which the personal data is being processed;
                        </li>
                        <li class="text-align-justify">
                            the categories of personal data processed;
                        </li>
                        <li class="text-align-justify">
                            the recipients or categories of recipients to whom the personal data
                            relating to yourself is or will be disclosed;
                        </li>
                        <li class="text-align-justify">
                            the period for which the personal data relating to yourself is intended
                            to remain on record or, if this cannot be specified, the criteria for
                            defining the storage period;
                        </li>
                        <li class="text-align-justify">
                            whether you are entitled to demand correction or deletion of the
                            personal data relating to yourself, to demand limitation of processing
                            by the controller or to object to processing;
                        </li>
                        <li class="text-align-justify">
                            whether you are entitled to file a complaint with a supervisory
                            authority;
                        </li>
                        <li class="text-align-justify">
                            everything available on the data’s source if the entity you are
                            enquiring with did not obtain it themselves;
                        </li>
                        <li class="text-align-justify">
                            whether there was any automated decision-making and profiling as per
                            art. 22 paragraphs 1 and 4 GDPR and – at least where such was the case –
                            useful information on the underlying logic and the impact and pursued
                            effects of this processing on the data subject.
                        </li>
                    </ol>

                    <p class="my-3">
                        You are entitled to request information on whether the personal data
                        relating to yourself will be transmitted to a non-EU member state or
                        international organisation. You are entitled in this context to request
                        information on suitable safeguards according to art. 46 GDPR related to the
                        transmission.
                    </p>

                    <p class="text-lg font-semibold">Right to rectification</p>

                    <p class="my-3">
                        You are entitled to request that the controller corrects and/or completes
                        the personal data relating to yourself if this data is incorrect or
                        incomplete. The controller is obliged to do so without delay.
                    </p>

                    <p class="text-lg font-semibold">Right to restriction of processing</p>

                    <p class="my-3">
                        You can request limits to the processing of personal data relating to
                        yourself if the following applies:
                    </p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            If you contest the correctness of the personal data relating to yourself
                            for a period that allows the controller to check the data's correctness;
                        </li>
                        <li class="text-align-justify">
                            Processing of the data is illegal and you object to deletion of the data
                            in favour of restricting the personal data’s use;
                        </li>
                        <li class="text-align-justify">
                            The controller no longer requires the personal data for the purposes of
                            processing, but you need them to legitimise, exercise or defend a legal
                            claim;
                        </li>
                        <li class="text-align-justify">
                            You have objected to processing in accordance with art. 21 paragraph 1
                            GDPR and it has not yet been established whether the controller’s
                            legitimate interests outweigh your own.
                        </li>
                    </ol>

                    <p class="my-3">
                        If processing the personal data relating to yourself has been limited, the
                        data can without your consent be used neither to assert, exercise or defend
                        legal claims nor to enforce protection of another individual’s or legal
                        entity’s rights nor can it be processed in the public interest of the
                        European Union or one of its member states. This does not apply to the
                        storing of the data.
                    </p>

                    <p class="my-3">
                        If processing has been restricted in accordance with the above conditions,
                        you will be notified by the controller before any restrictions are lifted.
                    </p>

                    <p class="text-lg font-semibold">Right to erasure</p>

                    <p class="my-3">a) Obligation to delete</p>

                    <p class="my-3">
                        You can request that the controller delete the personal data relating to
                        yourself immediately; the controller is then obliged to delete the data
                        immediately, provided one of the following conditions applies:
                    </p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            The personal data relating to yourself is no longer required to achieve
                            the purposes for which it was collected or otherwise processed.
                        </li>
                        <li class="text-align-justify">
                            You withdraw your consent, under which processing became legitimate as
                            per art. 6 paragraph 1 point a or art. 9 paragraph 2 point a GDPR, and
                            there is no other legal basis for processing.
                        </li>
                        <li class="text-align-justify">
                            You object to processing as per art. 21 paragraph 1 GDPR and your
                            objection is not overridden by legitimate reasons for processing, or you
                            object to processing as per art. 21 paragraph 2 GDPR.
                        </li>
                        <li class="text-align-justify">
                            The personal data relating to yourself have been processed unlawfully.
                        </li>
                        <li class="text-align-justify">
                            Deletion of the personal relating to yourself is necessary for the
                            controller to fulfil a legal obligation imposed upon them by European
                            Union law or the national laws of European Union member states.
                        </li>
                        <li class="text-align-justify">
                            The personal data relating to yourself has been collected in connection
                            with the offer of information society services as per art. 8 paragraph 1
                            GDPR.
                        </li>
                    </ol>

                    <p class="my-3">b) Notification of third parties</p>

                    <p class="my-3">
                        If the controller has published personal data relating to yourself and has
                        become obliged to delete it as per art. 17 paragraph 1 GDPR, the controller
                        will take action, including technical measures, using the available
                        technology and at appropriate expense with the aim of notifying any
                        controllers processing your personal data that you as the data subject have
                        requested deletion of all links to said personal data or to copies or
                        reproductions thereof.
                    </p>

                    <p class="my-3">c) Exceptions</p>

                    <p class="my-3">The right to erasure becomes void if processing is necessary</p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            to exercise of the right to free expression and information;
                        </li>
                        <li class="text-align-justify">
                            to fulfil a legal obligation requiring the controller to process the
                            data imposed upon them by European Union law or the national laws of a
                            European Union member state, or to complete a duty in the public
                            interest or to perform executive duties appointed to the controller;
                        </li>
                        <li class="text-align-justify">
                            in the interests of public health and safety as per art. 9 paragraph 2
                            points h and i and art. 9 paragraph 3 GDPR;
                        </li>
                        <li class="text-align-justify">
                            for archiving purposes in the public interest, for scientific or
                            historical research or for statistical purposes as per art. 89 paragraph
                            1 GDPR, provided that the right described in section a) can be
                            reasonably assumed to prevent or seriously impede achievement of the
                            processing purposes;
                        </li>
                        <li class="text-align-justify">
                            to assert, exercise or defend legal claims.
                        </li>
                    </ol>

                    <p class="text-lg font-semibold">Notification obligation</p>

                    <p class="my-3">
                        If you have asserted your right to rectification, erasure or restriction of
                        processing against the controller, the controller is under obligation to
                        notify all recipients to whom the personal data relating to yourself has
                        been disclosed of the corresponding rectification or erasure of data or of
                        the restriction of their processing. The controller is exempted from this
                        obligation where such notification proves impossible or unreasonable.
                    </p>

                    <p class="my-3">
                        You have the right to be informed of who these recipients are.
                    </p>

                    <p class="text-lg font-semibold">Right to data portability</p>

                    <p class="my-3">
                        You have the right to receive the personal data concerning yourself that you
                        have provided to a controller in a structured, commonly used and
                        machine-readable format. You are also entitled to transmit this data to
                        another controller without the controller to whom you have provided the data
                        hindering you from doing so and if
                    </p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            you have consented to processing as per art. 6 paragraph 1 point a GDPR
                            or art. 9 paragraph 2 point a GDPR or processing is governed by a
                            contract as per art. 6 paragraph 1 point b GDPR and
                        </li>
                        <li class="text-align-justify">
                            processing occurs using automated methods.
                        </li>
                    </ol>

                    <p class="my-3">
                        When exercising this right, you can further request controllers to send the
                        personal data relating to yourself directly to another controller, provided
                        this is technically feasible. This must not adversely affect the liberties
                        and rights of others.
                    </p>

                    <p class="my-3">
                        The right to data portability does not extend to the processing of personal
                        data where such processing is necessary for fulfilling a duty in the public
                        interest or for exercising executive duties appointed to the controller.
                    </p>

                    <p class="text-lg font-semibold">Right to object</p>

                    <p class="my-3">
                        You are entitled to object for reasons arising from your own personal
                        situation at any time against processing of personal data relating to
                        yourself where processing is legitimised by art. 6 paragraph 1 points e or f
                        GDPR; this applies in equal measure to profiling legitimised by these
                        provisions.
                    </p>

                    <p class="my-3">
                        The controller will cease to process your personal data unless they can
                        prove compelling legitimate reasons for processing that override your
                        interests, rights and liberties or processing pursues the assertion,
                        exercise or defence of legal claims.
                    </p>

                    <p class="my-3">
                        If personal data relating to yourself is processed for the purpose of direct
                        advertising, you are entitled to object at any time to the processing of
                        your personal data for this purpose; this applies equally to profiling where
                        it occurs in connection with such direct advertising.
                    </p>

                    <p class="my-3">
                        If you object to processing for direct advertising, the personal data
                        relating to yourself will no longer be processed for this purpose.
                    </p>

                    <p class="my-3">
                        You may, in connection with the use of information society services –
                        Directive 2002/58/EC notwithstanding – exercise your right to object by
                        means of automated methods that are subject to technical specifications.
                    </p>

                    <p class="text-lg font-semibold">
                        Right to withdraw your consent under data protection law
                    </p>

                    <p class="my-3">
                        You are entitled to withdraw your consent under data protection law at any
                        time. Your withdrawing consent does not affect legitimacy of any processing
                        that has occurred with your consent prior to withdrawal.
                    </p>

                    <p class="text-lg font-semibold">
                        Automated individual decision-making, including profiling
                    </p>

                    <p class="my-3">
                        You have the right not to be subject to any decision that entails legal
                        implications for yourself or has similar, substantially adverse effects on
                        yourself, if said decision is based solely on automated processing; this
                        includes profiling. You do not have this right if the decision
                    </p>

                    <ol class="list-decimal pl-12">
                        <li class="text-align-justify">
                            is necessary to allow conclusion or fulfilment of a contract between
                            yourself and the controller,
                        </li>
                        <li class="text-align-justify">
                            is legitimate under the legal provisions of the European Union or its
                            member states to which the controller is subject and these legal
                            provisions include appropriate measures safeguarding your rights,
                            liberties and legitimate personal interests or
                        </li>
                        <li class="text-align-justify">is made with your express consent.</li>
                    </ol>

                    <p class="my-3">
                        However, such decisions may have been made based on personal data of special
                        categories as per art. 9 paragraph 1 GDPR unless art. 9 paragraph 2 points a
                        or g GDPR also apply and appropriate measures have been taken to protect
                        your rights, liberties and legitimate personal interests.
                    </p>

                    <p class="my-3">
                        With respect to cases (1) and (3), the controller shall take appropriate
                        precautions to protect your rights, liberties and legitimate personal
                        interests; such precautions will include at least the right to enforce
                        intervention by a human individual at the controller’s, to put forward your
                        own opinion and to contest the decision.
                    </p>

                    <p class="text-lg font-semibold">
                        Right to complain with a supervisory authority
                    </p>

                    <p class="my-3">
                        If you believe that processing of personal data relating to yourself is in
                        breach of the GDPR, you have the right to lodge a complaint with a
                        supervisory authority, particularly in the EU member state you, your place
                        of work or the locale of the alleged infringement are in. This does not
                        affect your recourse to other administrative or judicial remedies.
                    </p>

                    <p class="my-3">
                        The supervisory authority receiving the complaint will keep the appellant up
                        to date on status and results of the complaint, including on recourse to
                        judicial remedies as per art. 78 GDPR.
                    </p>

                    <p class="text-lg font-semibold">Changes to our data policy</p>

                    <p class="my-3">
                        We reserve the right to amend this data policy to keep it in line with the
                        latest legal requirements or to adjust it to reflect changes to our
                        services, e.g. if we introduce new services. The latest version of our data
                        policy will apply to any further visits.
                    </p>

                    <p class="my-3">&nbsp;</p>

                    <p class="my-3"><em>Latest version: 11 November 2021</em></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

const functionalCookies = [
    {
        name: 'country',
        purpose: 'Change website country',
        description:
            'Defines the country setting of the site based on your specification. Can be changed and updated at any time and on any page. If "Remember my selection" is chosen, the information will be stored until you delete it manually by unselecting "Remember my selection" and saving your choice. Otherwise it will expiry by the end of your browsing session.',
        expiry: 'End of browsing session / never',
    },
    {
        name: 'mtm_consent',
        purpose: 'Remember cookie settings',
        description:
            'To identify if the user has allowed or disallowed the usage of Matomo web metrics stored on EIB’s website servers whilst on the website.',
        expiry: '30 years',
    },
    {
        name: 'mtm_consent_removed',
        purpose: 'Remember cookie settings',
        description:
            'To identify if the user has allowed or disallowed the usage of Matomo web metrics stored on EIB’s website servers whilst on the website.',
        expiry: '30 years',
    },
]

const analyticalCookies = [
    {
        name: 'matomo_sessid',
        purpose: 'Monitoring web use',
        service: '<a href="https://matomo.org/" target="_blank">Matomo open source software</a>',
        description:
            "Used by Matomo stored on adelphi's website servers. Recognises website visitors (no personal information is collected on the user).",
        expiry: '1 year',
    },
    {
        name: '_pk_ses.131.1fff',
        purpose: 'Monitoring web use',
        service: '<a href="https://matomo.org/" target="_blank">Matomo open source software</a>',
        description:
            "Used by Matomo stored on adelphi's website servers. Identifies the pages viewed by the same user during the same visit. (no personal information is collected on the user).",
        expiry: '30 minutes',
    },
    {
        name: '_pk_ref.131.1fff',
        purpose: 'Monitoring web use',
        service: '<a href="https://matomo.org/" target="_blank">Matomo open source software</a>',
        description:
            "Used by Matomo stored on adelphi's website servers. Stores the attribution information, the referrer initially used to visit the website. (no personal information is collected on the user).",
        expiry: '6 months',
    },
    {
        name: '_pk_id.131.1fff',
        purpose: 'Monitoring web use',
        service: '<a href="https://matomo.org/" target="_blank">Matomo open source software</a>',
        description:
            "Used by Matomo stored on adelphi's website servers. Recognises website visitors (no personal information is collected on the user).",
        expiry: '13 months',
    },
]

function getCookie(cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export default {
    components: { Switch, SwitchGroup, SwitchLabel },

    setup() {
        const enabled = ref()
        const consentstatus = getCookie('mtm_consent')

        watch(enabled, newStatus => {
            if (window._paq) {
                // eslint-disable-next-line no-underscore-dangle
                newStatus === true ? window._paq.push(['rememberConsentGiven']) : window._paq.push(['optUserOut'])
            }
        })

        if (consentstatus !== '') {
            enabled.value = true
        } else {
            enabled.value = false
        }

        return {
            functionalCookies,
            analyticalCookies,
            enabled,
        }
    },
}
</script>
