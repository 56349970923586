<template>
    <div
        v-if="showFIbanner"
        id="fiBanner"
        class="text-white text-center py-2 italic text-xs md:text-base"
        :style="{ 'background-color': fidata.mainColour }"
    >
        {{ t('login.bannerText', [fidata.name]) }}
        <!-- <ArrowRightOnRectangleIcon
            class="w-4 h-4 ml-2 text-white inline-block cursor-pointer"
            alt="Logout"
            title="Logout"
            @click="logout"
        /> -->
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'

export default {
    name: 'FIBanner',
    // eslint-disable-next-line vue/no-unused-components
    components: { ArrowRightOnRectangleIcon }, // LocalSwitcher,
    setup() {
        const showFIbanner = ref(false)
        const store = useStore()
        const { locale, t } = useI18n()

        const logout = async () => {
            await store.commit('auth/financialIntermediary', null)
            await store.commit('auth/fiJourney', false)
            await sessionStorage.removeItem('fi')
            await store.commit('measures/setMeasurePool', [])
            await store.dispatch('measures/getMeasurePool')
            await store.dispatch('global/getFAQs')
            showFIbanner.value = false
        }

        const fidata = computed(() => {
            return store.getters['auth/financialIntermediary']
        })

        const fiId = computed(() => {
            return store.getters['auth/financialIntermediaryId']
        })

        const fiJourney = computed(() => {
            return store.getters['auth/fiJourney']
        })

        if (fiJourney.value) showFIbanner.value = true

        watch(fiJourney, newValue => {
            if (newValue !== null) {
                showFIbanner.value = true
            } else {
                showFIbanner.value = false
            }
        })

        return {
            fidata,
            fiId,
            fiJourney,
            logout,
            showFIbanner,
            t,
        }
    },
}
</script>
