<template>
    <div>
        <ExplainBox
            :subheader="t('report.disclaimerHeading')"
            :title="t('disclaimer.ConfirmationHeading')"
            :text="t('disclaimer.ConfirmationExplanation')"
        />
        <div class="p-12 pb-24">
            <div
                class="container mx-auto lg:px-28 text-sm"
                v-html="t('general.disclaimerText')"
            ></div>
            <div class="container mx-auto lg:px-28 text-center">
                <base-button @click="confirm" class="my-4 ml-5" test="disclaimerBtn">
                    {{ t('disclaimer.ConfirmButton') }}
                    <ArrowSmallRightIcon class="h-6 w-6 ml-1 mr-0 float-right" />
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline'
import ExplainBox from '@/components/common/ExplainBox.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'Disclaimerconfirmation',
    components: { ArrowSmallRightIcon, ExplainBox, BaseButton },
    setup() {
        const { t } = useI18n()
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const confirm = () => {
            store.commit('global/disclaimerConfirmed', true)
            store.commit('global/storeDisclaimerConfirmed')

            const { next } = route.query

            router.push(next)

            // console.log('confirm')
        }

        return {
            confirm,
            t,
        }
    },
}
</script>
