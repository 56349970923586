<template>
    <div v-if="currentMeasure">
        <!-- Simple mode -->
        <div>
            <SchemaFormWithPlugins
                :preventModelCleanupOnSchemaChange="true"
                ref="mainSchema"
                :schema="currentMeasure.schema"
                class="grid grid-cols-1"
            >
                <template #afterForm="{ validation }">
                    <!-- Der Button triggert dann die validation, anders gings -->
                    <button id="submitBtn" style="visibility: hidden">Submit</button>
                    <FormErrors :validation="{ validation }" mode="simple" />
                </template>
            </SchemaFormWithPlugins>
        </div>

        <!-- Extended mode -->
        <div v-show="advancedMode" data-testid="advancedModeForm">
            <div class="pt-6 pl-6 pr-6">
                <hr />
                <h2
                    class="h2 text-xl mt-3 pt-6"
                    v-html="t('assessment.specification.advancedModeHeadline')"
                ></h2>
                <div class="block mt-2">
                    <div
                        class="flex items-end text-sm"
                        v-html="t('assessment.specification.advancedModeExplanation')"
                    ></div>
                </div>
            </div>

            <SchemaFormWithPlugins
                :preventModelCleanupOnSchemaChange="true"
                :schema="currentMeasure.extendedSchema"
                ref="advancedSchema"
                class="grid grid-cols-1"
            >
                <template #afterForm="{ validation }">
                    <!-- Der Button triggert dann die validation, anders gings -->
                    <FormErrors :validation="{ validation }" mode="advanced" />
                </template>
            </SchemaFormWithPlugins>
        </div>

        <!-- Meta fields (investment & comment) -->
        <div>
            <div class="pt-6 pl-6 pr-6">
                <hr />
                <!-- <h2 class="h2 text-xl mt-3 pt-6">The following information is optional:</h2> -->
            </div>
            <SchemaFormWithPlugins
                :preventModelCleanupOnSchemaChange="true"
                :schema="currentMeasure.metaSchema"
                class="grid grid-cols-1"
            />
        </div>
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import debounce from '@/plugins/debounce.js'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import LookupPlugin, { lookupSubSchemas } from '@formvuelate/plugin-lookup'
import VeeValidatePlugin from '@formvuelate/plugin-vee-validate'
import { SchemaFormFactory, useSchemaForm } from 'formvuelate'
import FormErrors from '@/components/assessment/FormErrors.vue'
import BaseInteger from '@/components/base/BaseInteger.vue'
import BaseDecimal from '@/components/base/BaseDecimal.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import BaseRadio from '@/components/base/BaseRadio.vue'
import BaseSwitchSelect from '@/components/base/BaseSwitchSelect.vue'
import MultiLineFormField from '@/components/base/MultiLineFormField.vue'
import BaseTextArea from '@/components/base/BaseTextArea.vue'

const SchemaFormWithPlugins = SchemaFormFactory([
    LookupPlugin({
        // Map Component-Names of in the Schema provided by the API to Components implemented
        mapComponents: {
            FormInteger: BaseInteger,
            FormFloat: BaseDecimal,
            FormSelect: BaseSelect,
            FormSwitch: BaseSwitch,
            FormCheckbox: BaseSwitch,
            FormText: BaseTextArea,
            FormRadioSelect: BaseRadio,
            FormSwitchSelect: BaseSwitchSelect,
            MultiLineFormField: MultiLineFormField,
        },
    }),
    VeeValidatePlugin({
        // plugin configuration here
        mapProps(validation) {
            return {
                errorMessage: validation.errorMessage,
            }
        },
    }),
])

export default {
    /* eslint-disable camelcase */
    components: { SchemaFormWithPlugins, FormErrors },
    props: { currentMeasure: Object, advancedMode: Boolean },
    setup(props) {
        const { t } = useI18n()
        const store = useStore()
        const model = ref({}) // An object that holds the entries of the form
        const advancedSchema = ref({})
        const mainSchema = ref({})
        lookupSubSchemas(SchemaFormWithPlugins)
        useSchemaForm(props.currentMeasure ? props.currentMeasure.model : {}) // assign object to form

        // The method is called via refs by the parent of the parent (views.Assessment -> MeasureSpecification)
        function formSubmit() {
            document.getElementById('submitBtn').click()
        }

        const valid = computed(() => {
            if (mainSchema.value.slotBinds) {
                // console.log("validate")
                return mainSchema.value.slotBinds.validation.meta
            }
            return null
        })

        const advanced_valid = computed(() => {
            if (advancedSchema.value.slotBinds) {
                return advancedSchema.value.slotBinds.validation.meta
            }
            return null
        })

        const sendRequest = () => {
            const measure = props.currentMeasure
            measure['assessment_mode'] = props.advancedMode === true ? 'extended' : 'simple'
            store.dispatch('measures/getResult', measure)
        }

        const debauncedSendRequest = debounce(sendRequest, 300)

        watch(props.currentMeasure.model, val => {
            const { model } = store.getters['measures/currentMeasure']
            if (mainSchema.value.slotBinds) {
                mainSchema.value.slotBinds.validation.values = model
            }
            // console.log(toRaw(model))
        })
        // Monitors the validation of the schema and triggers getResult if valid
        watch(valid, val => {
            if (val.valid) {
                debauncedSendRequest()
            }
        })
        watch(advanced_valid, val => {
            if (val.valid) {
                debauncedSendRequest()
            }
        })

        return { model, mainSchema, advancedSchema, formSubmit, t }
    },
}
</script>

<style>
div.schema-row {
    display: contents !important;
}
div.schema-col {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem !important;
}

/* required for proper subshema display */
div.schema-row div[model] {
    display: inherit !important;
}
</style>
