<template>
    <div class="px-12 py-0 lg:pt-28 h-192 min-h-screen">
        <div class="absolute inset-0 -z-10 bg-gray-50">
            <img
                class="min-h-screen object-cover w-full"
                src="@/assets/hero.jpg"
                :alt="t('general.imageAlt')"
            />
        </div>
        <div class="mx-auto relative items-center lg:flex container sm:pt-16 pb-16">
            <div class="w-full sm:w-3/4">
                <h1 class="h0 text-white text-left">Green Eligibility Checker</h1>
                <h2 class="h3 mb-6 mt-3 italic text-white" v-if="financialIntermediaryId !== 'eib'">
                    <span class="mr-3">
                        {{ t('for') }}
                    </span>
                    <span
                        class="px-2 pr-3 py-1 text-white"
                        :style="{ 'background-color': fiData.mainColour }"
                    >
                        {{ fiData.name }}
                    </span>
                </h2>
                <h3 class="h3 text-white text-left pt-3">
                    {{ t('hero.teaser') }}
                </h3>
                <h5 class="h5 text-white pt-9 pb-4">
                    {{ t('hero.heading') }}
                </h5>
                <BaseList class="pb-1 sm:pb-2 text-white font-base" liststyle="arrow">
                    {{ t('hero.bulletItem1') }}
                </BaseList>
                <BaseList class="pb-1 sm:pb-2 text-white font-base" liststyle="arrow">
                    {{ t('hero.bulletItem2') }}
                </BaseList>
                <BaseList class="pb-1 sm:pb-2 text-white font-base" liststyle="arrow">
                    {{ t('hero.bulletItem3') }}
                </BaseList>
                <div class="pt-3 pb-9 italic text-gray-300 text-xs sm:text-sm" liststyle="check">
                    {{ t('hero.durationHint') }}
                </div>
                <BaseButton @click="start" test="StartBtn">
                    {{ t('hero.start') }}
                    <ArrowSmallRightIcon class="h-5 w-5 sm:h-6 sm:w-6 ml-1 float-right" />
                </BaseButton>
            </div>
        </div>

        <!-- <div class="w-full mx-auto text-center hidden sm:inline-block mt-60 lg:mt-36 xl:mt-8">
            <router-link :to="{ name: 'Home', hash: '#faq' }">
                <button
                    class="
                        bg-white bg-opacity-40
                        hover:bg-opacity-100
                        text-center
                        -mt-12
                        rounded-full
                        focus:outline-none
                    "
                >
                    <ChevronDownIcon class="h-10 w-10 p-2 text-eibgrey60" />
                </button>
            </router-link>
        </div> -->
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import {
    ArrowSmallRightIcon,
    // ChevronDownIcon
} from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseList from '@/components/base/BaseList.vue'

export default {
    components: {
        BaseButton,
        BaseList,
        ArrowSmallRightIcon,
    },
    name: 'Hero',
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()

        const fiData = computed(() => store.getters['auth/financialIntermediary'])
        const financialIntermediaryId = computed(
            () => store.getters['auth/financialIntermediaryId']
        )

        const start = () => {
            const country = store.getters['global/country']
            const countryConfirmed = store.getters['global/countryConfirmed']

            // TEMPORARY WORKAROUND
            if (country === null || !countryConfirmed) {
                router.push('/country')
            } else {
                router.push('/selection')
                store.commit('global/currentStep', 1)
            }
        }

        return { t, start, financialIntermediaryId, fiData }
    },
}
</script>
