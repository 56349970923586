<template>
    <div
        class="shadow-measures rounded-xl hover:bg-gray-100 measureitem"
        :class="disabled ? 'opacity-30' : ''"
        :title="disabled ? 'This measure is still under development' : ''"
        :alt="disabled ? 'This measure is still under development' : ''"
    >
        <div
            class="sm:flex sm:overflow-hidden sm:h-full sm:flex-nowrap measure"
            data-testid="measure-card"
            :data-measureid="measure.version_number"
        >
            <div class="w-full sm:w-36 sm:block sm:flex-none">
                <img
                    :src="baseURL + measure.image"
                    alt=""
                    class="object-cover rounded-b-none sm:rounded-r-none sm:rounded-bl-xl object-center w-full h-full max-h-48 sm:max-h-full sm:h-full rounded-xl"
                />
            </div>
            <div class="pl-6 pr-6">
                <div class="pt-3 pb-5">
                    <div class="flex items-center">
                        <div class="flex-grow">
                            <h1
                                class="text-md sm:text-xl text-black font-bold font-alegreya measuretitle"
                                data-testid="measure-title"
                            >
                                <span v-if="disabled">Coming soon:&nbsp;</span>
                                <span v-html="measure.name"></span>
                            </h1>
                        </div>
                        <div class="justify-end text-right float-right">
                            <ArrowSmallRightIcon
                                class="h-8 w-8 ml-1 mr-0 float-right text-blue-500"
                            />
                        </div>
                    </div>
                    <p
                        class="hidden sm:inline text-eibgrey60 pt-3 text-base sm:text-md leading-4 sm:leading-5"
                        v-html="truncateString(measure.measure_description, 165)"
                    ></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline'
import axios from '@/plugins/axios.js'

export default {
    name: 'MeasureItem',
    components: { ArrowSmallRightIcon },
    props: {
        measure: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const truncateString = (str, num) => {
            if (str.length > num) {
                return `${str.slice(0, num)}...`
            }
            return str
        }

        const baseURL = axios.defaults.baseURL
        return { baseURL, truncateString }
    },
}
</script>
