<template>
    <div>
        <div
            class="bg-midnight-blue flex justify-center h-auto pt-2 sm:pt-3 md:pt-4 align-middle active:border-transparent items-center"
        >
            <div class="">
                <div
                    v-for="step in steps.items"
                    v-bind:key="'step' + step.id"
                    @click="stepClickable(step) ? (currentStep = step.id) : null"
                    class="inline-block mx-3 text-center sm:mx-6 sm:w-32 md:mx-12 md:w-36"
                    :class="stepClickable(step) ? 'cursor-pointer' : 'cursor-not-allowed'"
                >
                    <div
                        class="uppercase text-xs sm:text-base sm:pb-2 font-medium sm:font-semibold text-white"
                    >
                        <span>{{ t('stepper.step') }}</span>
                        {{ step.id }}
                    </div>
                    <div class="text-gray-400 uppercase font-normal text-xs sm:text-base">
                        {{ t(`stepper.steps.step${step.id}.shorttext`) }}
                    </div>
                    <div
                        v-show="step.id === currentStep.id"
                        class="bg-eibbrightblue h-1 mt-2 sm:h-2 sm:mt-4"
                    ></div>
                </div>
            </div>
        </div>
        <div class="container mx-auto"></div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: 'Steppper',
    components: {},
    props: {
        viewMode: {
            type: String,
            default: 'Assessment',
        },
    },
    setup(props) {
        const store = useStore()
        const { t } = useI18n()
        const router = useRouter()

        const currentMeasure = computed(() => {
            return store.getters['measures/currentMeasure']
        })

        const measuresInCart = computed(() => {
            return store.getters['measures/measures'].length
        })

        const steps = reactive({
            items: [
                {
                    id: 1,
                },
                {
                    id: 2,
                },
                {
                    id: 3,
                },
            ],
        })

        const currentStep = computed({
            get() {
                const step = store.getters['global/currentStep']
                if (step) {
                    return steps.items.filter(s => s.id === step)[0]
                }
                return steps.items[0]
            },
            set(val) {
                if (!currentMeasure.value && val === 2 && props.viewMode === 'Assessment') {
                    return
                }
                if (props.viewMode === 'Summary' && val === 2) {
                    return
                }
                if (val === 3) {
                    store.commit('global/currentStep', val)
                    if (props.viewMode === 'Assessment') {
                        router.push('/summary')
                    }
                } else {
                    // if ()
                    store.commit('global/currentStep', val)
                    if (props.viewMode === 'Summary') {
                        router.push('/selection')
                        store.commit('global/currentStep', 1)
                        store.commit('measures/currentMeasureId', null)
                        store.commit('measures/removePreCheckResult')
                    } else if (props.viewMode === 'Assessment') {
                        if (val === 1) {
                            router.push('/selection')
                            store.commit('measures/currentMeasureId', null)
                            store.commit('measures/removePreCheckResult')
                        }
                    }
                }
            },
        })

        const stepClickable = step => {
            if ((currentStep.value.id === 1 || props.viewMode === 'Summary') && step.id === 2) {
                return false
            }

            if (step.id === 1 && measuresInCart.value >= 50) {
                return false
            }
            return true
        }

        return { t, stepClickable, currentMeasure, currentStep, steps, measuresInCart }
    },
}
</script>
