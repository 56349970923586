<template>
    <div :class="formFieldClasses" :style="formFieldStyles">
        <div v-if="header" class="border-b-4 mt-6">
            <h3 class="uppercase font-medium text-md">{{ header }}</h3>
        </div>
        <div class="inline-block" v-if="label">
            <div class="text-sm sm:text-md font-bold my-1 sm:my-3">
                <span v-html="label"></span>
                <InformationCircleIcon
                    v-if="description"
                    @click="descriptionVisible = !descriptionVisible"
                    class="inline ml-1 h-4 w-4 sm:h-5 sm:w-5 text-eibbrightblue cursor-help"
                    aria-hidden="true"
                />
            </div>
        </div>
        <div class="self-end">
            <div class="flex items-start">
                <div class="flex-shrink" v-html="prefix"></div>
                <div class="flex-grow self-end"><slot /></div>
                <div class="flex-shrink pl-2 pt-2" v-html="suffix"></div>
            </div>
        </div>
        <div class="block" v-if="description && descriptionVisible">
            <div
                class="rounded-md bg-blue-50 p-2 my-2"
                @click="descriptionVisible = !descriptionVisible"
            >
                <div class="flex items-end">
                    <!-- <span>
                        <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </span> -->
                    <div class="ml-2 text-sm" v-html="description"></div>
                </div>
            </div>
        </div>
        <ValidationMessage :errorMessage="$attrs.errorMessage" />
    </div>
</template>

<script>
import { ref } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { InformationCircleIcon, ValidationMessage },
    props: {
        label: String,
        suffix: String,
        prefix: String,
        description: String,
        styled: String,
        formFieldClasses: { type: String, default: 'grid p-6' },
        formFieldStyles: String,
        header: String,
    },
    setup() {
        const descriptionVisible = ref(false)
        return { descriptionVisible }
    },
}
</script>
