<template>
    <div class="flex rounded-md shadow-sm mb-6" v-if="searchVisible">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
                inputmode="search"
                type="text"
                v-model="searchfilter"
                ref="measuresSearchInput"
                @keyup="searchByText(searchfilter)"
                class="shadow-sm focus:border-transparent block w-full text-xs sm:text-sm pl-10 h-9 rounded-l-md sm:h-12 rounded-none border-gray-300 text-gray-700 hover:border-eibbrightblue focus:ring-eibbrightblue focus:border-eibbrightblue"
                :class="searchfilter === '' ? 'rounded-r-md' : ''"
                :placeholder="t('assessment.selection.filter.searchPlaceholder')"
            />
        </div>
        <button
            v-if="searchfilter"
            @click="resetSearchText"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-1 sm:py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-eibbrightblue focus:border-eibbrightblue"
        >
            <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>{{ t('filters.resetSearchField') }}</span>
        </button>
    </div>
</template>
<script>
import { useWindowSize } from 'vue-window-size'
import { ref, computed, onMounted } from 'vue'
// import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'MeasureSearchBox',
    components: { XMarkIcon, MagnifyingGlassIcon },
    setup() {
        const { t } = useI18n()
        const { width } = useWindowSize()
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const searchfilter = ref('')
        const measuresSearchInput = ref(null)

        // Show search button only if screen >= xl (1280px)
        const searchVisible = computed(() => {
            if (width.value >= 1280) {
                return true
            }
            return store.getters['filters/searchVisible']
        })

        const resetSearchText = () => {
            searchfilter.value = ''
            store.dispatch('filters/resetSearchText')
        }

        const searchByText = async searchtext => {
            store.commit('filters/searchText', searchtext)

            searchtext
                ? router.replace({ query: { q: searchtext }, hash: `${route.hash}` })
                : router.replace({ query: {}, hash: `${route.hash}`, replace: true })
        }

        onMounted(() => {
            if (route.query.q) {
                searchfilter.value = route.query.q
                store.commit('filters/searchText', route.query.q)
            }
            if (searchVisible.value) {
                const searchField = measuresSearchInput.value
                searchField.focus()
            }
        })

        return {
            searchfilter,
            searchByText,
            resetSearchText,
            searchVisible,
            measuresSearchInput,
            t,
        }
    },
}
</script>
