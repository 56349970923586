<template>
    <!-- Meta Filter Selection -->
    <div class="flex gap-x-2 gap-y-1 items-stretch flex-wrap" v-show="filterVisible">
        <div class="hidden xl:inline">{{ t('general.filter') }}</div>
        <div v-for="cf in categoryFilters" v-bind:key="cf.id">
            <div class="flex-none">
                <FilterSelect :filter="cf" />
            </div>
        </div>
        <InformationCircleIcon
            class="w-4 h-4 mt-1 -ml-1 text-eibbrightblue cursor-pointer"
            @click="this.$refs.eibAlwaysEligibleModalRef.openModal()"
        />
        <button
            @click="resetAllFilters"
            v-if="activeFilterCounter > 0"
            class="-ml-px relative inline-flex items-center rounded-md px-3 py-0.5 border border-gray-300 text-sm text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-eibbrightblue focus:border-eibbrightblue"
        >
            <XMarkIcon class="h-4 w-4 mr-1 -ml-1 text-gray-400" aria-hidden="true" />
            <span>{{ t('filters.resetSearchField') }}</span>
        </button>
    </div>
    <teleport to="body">
        <info-modal
            ref="eibAlwaysEligibleModalRef"
            :confirmText="t('eligibility.modalConfirmBtnText')"
            confirmButtonColor="bg-eibsuccess"
        >
            <div class="container">
                <p class="font-alegreya font-medium h4 pb-3">
                    {{ t('eligibility.alwaysEIBgreenModalHeading') }}
                </p>
                <p class="text-base">
                    {{ t('eligibility.alwaysEIBgreen') }}
                </p>
            </div>
            <!-- <hr class="my-6" /> -->
            <div class="container mt-9">
                <p class="font-alegreya font-medium h4 pb-3">
                    {{ t('eligibility.notAlwaysEIBgreenModalHeading') }}
                </p>
                <p class="text-base">
                    {{ t('eligibility.notAlwaysEIBgreen') }}
                </p>
            </div>
        </info-modal>
    </teleport>
    <!-- Meta Filter Display -->
    <div class="mt-4 flex gap-x-2 gap-y-1 items-stretch flex-wrap">
        <div
            v-for="(categoryFilter, index) in selectedFilters"
            v-bind:key="categoryFilter + '-' + index"
        >
            <div v-for="cfs in categoryFilter.values" v-bind:key="cfs.id">
                <div class="flex-none">
                    <div
                        class="float-left flex uppercase font-medium text-xs text-eibblue80 bg-white cursor-default py-1 my-1 px-2 border border-eibblue80 rounded-md hover:border-gray-300"
                    >
                        {{ cfs.i18nRequired ? t(`${cfs.name}`) : cfs.name }}
                        <div class="float-right">
                            <XMarkIcon
                                class="ml-2 w-4 h-4 cursor-pointer"
                                @click="removeFilter({ filters: categoryFilter, id: cfs.id })"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs text-eibgrey60 pt-2 pb-3" v-if="isFiltered">
        {{ t('filters.measuresCountFitting', filteredMeasuresCount) }}
    </div>
</template>
<script>
import { useWindowSize } from 'vue-window-size'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import FilterSelect from '@/components/base/FilterSelect.vue'
import infoModal from '@/components/base/infoModal.vue'

export default {
    name: 'MeasureSearchBox',
    components: { XMarkIcon, FilterSelect, InformationCircleIcon, infoModal },
    setup() {
        const { t } = useI18n()
        const { width } = useWindowSize()
        const store = useStore()

        const eibAlwaysEligibleModalRef = ref(false)
        const triggerOpenEIBAlwaysEligibleModal = () => {
            eibAlwaysEligibleModalRef.value.openModal()
        }

        const categoryFilters = [
            { id: 'sectors', i18nRequired: false },
            // { id: 'targetAreas' },
            { id: 'economicActivities', i18nRequired: false },
            { id: 'environmentalTargets', i18nRequired: false },
            { id: 'eligibilityStatus', i18nRequired: true },
            // { id: 'climateActionCategories' },
            // { id: 'financialProducts' },
        ]

        // Show filter button only if screen >= xl (1280px)
        const filterVisible = computed(() => {
            if (width.value >= 1280) {
                return true
            }
            return store.getters['filters/filterVisible']
        })

        const filteredMeasuresCount = computed(() => {
            return store.getters['filters/filteredMeasures'].length
        })

        const removeFilter = filter => {
            // console.log(filter)
            const newFiltered = filter.filters.values.filter(f => f.id !== filter.id)
            store.commit(`filters/${filter.filters.target}`, newFiltered)
        }

        const resetAllFilters = () => {
            store.commit('filters/removeAllFilters')
        }

        const sectorsFilter = computed(() => {
            return store.getters['filters/sectorsFilter']
        })
        // const targetAreasFilter = computed(() => {
        //     return store.getters['filters/targetAreasFilter']
        // })
        const economicActivitiesFilter = computed(() => {
            return store.getters['filters/economicActivitiesFilter']
        })
        const environmentalTargetsFilter = computed(() => {
            return store.getters['filters/environmentalTargetsFilter']
        })
        const eligibilityStatusFilter = computed(() => {
            return store.getters['filters/eligibilityStatusFilter']
        })
        // const climateActionCategoriesFilter = computed(() => {
        //     return store.getters['filters/climateActionCategoriesFilter']
        // })
        // const financialProductsFilter = computed(() => {
        //     return store.getters['filters/financialProductsFilter']
        // })

        const activeFilterCounter = computed(() => {
            return store.getters['filters/activeFilterCounter']
        })

        const isFiltered = computed(() => {
            if (
                filteredMeasuresCount.value === store.getters['measures/measurePool'].length &&
                activeFilterCounter.value === 0
            ) {
                return false
            }
            return true
        })

        const selectedFilters = computed(() => {
            const result = []
            if (sectorsFilter.value.length > 0) {
                result.push({ target: 'sectorsFilter', values: sectorsFilter.value })
            }
            // if (targetAreasFilter.value.length > 0) {
            //     result.push({ target: 'targetAreasFilter', values: targetAreasFilter.value })
            // }
            if (economicActivitiesFilter.value.length > 0) {
                result.push({
                    target: 'economicActivitiesFilter',
                    values: economicActivitiesFilter.value,
                })
            }
            if (environmentalTargetsFilter.value.length > 0) {
                result.push({
                    target: 'environmentalTargetsFilter',
                    values: environmentalTargetsFilter.value,
                })
            }
            if (eligibilityStatusFilter.value.length > 0) {
                result.push({
                    target: 'eligibilityStatusFilter',
                    values: eligibilityStatusFilter.value,
                })
            }
            // if (climateActionCategoriesFilter.value.length > 0) {
            //     result.push({
            //         target: 'climateActionCategoriesFilter',
            //         values: climateActionCategoriesFilter.value,
            //     })
            // }
            // if (financialProductsFilter.value.length > 0) {
            //     result.push({
            //         target: 'financialProductsFilter',
            //         values: financialProductsFilter.value,
            //     })
            // }

            return result
        })

        return {
            isFiltered,
            filterVisible,
            removeFilter,
            resetAllFilters,
            triggerOpenEIBAlwaysEligibleModal,
            activeFilterCounter,
            selectedFilters,
            categoryFilters,
            filteredMeasuresCount,
            t,
        }
    },
}
</script>
