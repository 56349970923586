<template>
    <div class="relative z-10">
        <div class="active:border-none focus:outline-none cursor-pointer">
            <button
                @click="this.$refs.confirmModalRef.openModal()"
                class="inline-block justify-center uppercase lg:p-2 text-nav font-bold tracking-wide mx-3 disabled:opacity-50 disabled:cursor-not-allowed"
                data-testid="CountryText"
                :disabled="countries.length <= 1"
            >
                <GlobeAltIcon class="h-6 w-6 mr-1 float-left text-blue-500" aria-hidden="true" />
                <span class="inline-block">
                    {{ selectedCountry }}
                </span>
            </button>
        </div>
        <teleport to="body">
            <confirm-modal
                ref="confirmModalRef"
                :confirmText="t('general.confirmText')"
                :showConfirmButton="false"
                :showCancelButton="false"
            >
                <div>
                    <div
                        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-500"
                    >
                        <GlobeAltIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h4" class="h4 font-alegreya">
                            {{ t('country.specifyCountry') }}
                        </DialogTitle>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">
                                {{ t('country.noteOnAssumptions') }}
                            </p>
                        </div>

                        <div v-if="measuresCount > 0">
                            <div class="rounded-md bg-yellow-50 p-3 mx-5 my-3">
                                <div class="flex">
                                    <div class="ml-3">
                                        <h3 class="text-md font-medium text-yellow-800">
                                            <ExclamationTriangleIcon
                                                class="h-5 w-5 text-yellow-400 inline-block"
                                                aria-hidden="true"
                                            />
                                            {{ t('general.attention') }}
                                        </h3>
                                        <div class="mt-2 text-sm text-yellow-700">
                                            <p>
                                                {{
                                                    t(
                                                        'country.warningChangeCountryWithMeasuresDone',
                                                        measuresCount
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <country-selector
                    :embedMode="true"
                    :submitButtonText="t('country.changeCountry')"
                    @close-country-modal="this.$refs.confirmModalRef.closeModal()"
                ></country-selector>
            </confirm-modal>
        </teleport>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { DialogTitle } from '@headlessui/vue'
import { GlobeAltIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import confirmModal from '../base/confirmModal.vue'
import CountrySelector from './CountrySelector.vue'

export default {
    name: 'Country',
    components: {
        confirmModal,
        ExclamationTriangleIcon,
        GlobeAltIcon,
        DialogTitle,
        CountrySelector,
    },
    setup() {
        const store = useStore()
        const { t } = useI18n()
        const confirmModalRef = ref(false)
        const triggerOpenModal = () => {
            confirmModalRef.value.openModal()
        }

        const measuresCount = computed(() => {
            return store.getters['measures/measures'].length
        })

        const countries = computed(() => {
            const fi_countries = store.getters['auth/financialIntermediaryCountries']
            if (fi_countries) {
                return fi_countries
            } else {
                return store.getters['global/countries']
            }
        })

        const country = computed(() => {
            const storedCountry = store.getters['global/country']
            if (storedCountry) {
                return storedCountry.value
            }
            return null
        })

        const selectedCountry = computed(() => {
            const storedCountry = store.getters['global/country']
            if (storedCountry) {
                return storedCountry.text
            }
            return t('navButtonTitle')
        })

        return {
            confirmModalRef,
            triggerOpenModal,
            measuresCount,
            countries,
            country,
            selectedCountry,
            t,
        }
    },
}
</script>
