/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from '@/plugins/axios.js'
import Fuse from 'fuse.js'

const getActiveCategories = (categoryName, categories, measurePool) => {
    const uniqueCategoryIds = [
        ...new Set(measurePool.flatMap(m => m.classification.map(c => c[categoryName]).flat())),
    ]
    const uniqueCategories = categories.filter(s => uniqueCategoryIds.includes(s.id))
    return uniqueCategories
}

const state = {
    climateActionCategories: [],
    economicActivities: [],
    economicActivitiesFilter: [],
    environmentalTargets: [],
    environmentalTargetsFilter: [],
    eligibilityStatus: [
        {
            id: 1,
            identifier: true,
            name: 'filters.eligibilityStatus.alwaysEligible',
            i18nRequired: true,
        },
        {
            id: 2,
            identifier: false,
            name: 'filters.eligibilityStatus.eligibilityCheckRequired',
            i18nRequired: true,
        },
    ],
    eligibilityStatusFilter: [],
    filterVisible: false,
    searchText: '',
    searchVisible: false,
    sectors: [],
    sectorsFilter: [],
}

// Helper for generic mutations from: https://itnext.io/reusable-vuex-mutation-functions-9b4920aa737b
const set = key => (state, val) => {
    state[key] = val
}

const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase() // Groß-/Kleinschreibung ignorieren
    const nameB = b.name.toUpperCase() // Groß-/Kleinschreibung ignorieren
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // Namen müssen gleich sein
    return 0
}

const mutations = {
    searchText: set('searchText'),
    sectors: set('sectors'),
    sectorsFilter: set('sectorsFilter'),
    climateActionCategories: set('climateActionCategories'),
    economicActivities: set('economicActivities'),
    economicActivitiesFilter: set('economicActivitiesFilter'),
    environmentalTargets: set('environmentalTargets'),
    environmentalTargetsFilter: set('environmentalTargetsFilter'),
    eligibilityStatusFilter: set('eligibilityStatusFilter'),
    toggleFilter(state) {
        state.filterVisible = !state.filterVisible
    },
    toggleSearch(state) {
        state.searchVisible = !state.searchVisible
    },
    removeAllFilters(state) {
        state.sectorsFilter = []
        state.economicActivitiesFilter = []
        state.environmentalTargetsFilter = []
        state.eligibilityStatusFilter = []
    },
}

const getters = {
    filterVisible(state) {
        return state.filterVisible
    },
    searchVisible(state) {
        return state.searchVisible
    },
    measurePool(state, getters, rootState, rootGetters) {
        return rootGetters['measures/measurePool']
    },
    sectors(state, getters) {
        return getActiveCategories('sectors', state.sectors, getters.measurePool)
    },
    sectorsFilter(state) {
        return state.sectorsFilter
    },
    eligibilityStatus(state) {
        return state.eligibilityStatus
    },
    eligibilityStatusFilter(state) {
        return state.eligibilityStatusFilter
    },
    climateActionCategories(state) {
        return state.climateActionCategories
    },
    economicActivities(state, getters) {
        return getActiveCategories(
            'economic_activities',
            state.economicActivities,
            getters.measurePool
        )
    },
    economicActivitiesFilter(state) {
        return state.economicActivitiesFilter
    },
    environmentalTargets(state, getters) {
        return getActiveCategories(
            'environmental_targets',
            state.environmentalTargets,
            getters.measurePool
        )
    },
    environmentalTargetsFilter(state) {
        return state.environmentalTargetsFilter
    },

    eligibilityStatusFilter(state) {
        return state.eligibilityStatusFilter
    },
    searchText(state) {
        return state.searchText
    },

    activeFilterCounter(state) {
        const activeFilters = [
            ...state.economicActivitiesFilter,
            ...state.environmentalTargetsFilter,
            ...state.sectorsFilter,
            ...state.eligibilityStatusFilter,
        ]
        return activeFilters.length
    },

    filteredMeasures(state, getters) {
        const searchText = getters.searchText.toLowerCase()
        const { measurePool, sectors } = getters

        function getArraysIntersection(a1, a2) {
            // eslint-disable-next-line func-names
            return a1.filter(function (n) {
                return a2.indexOf(n) !== -1
            })
        }

        let filteredSectorIDs = []
        if (searchText !== '') {
            const fuseOptions = {
                includeScore: true,
                includeMatches: true,
                ignoreLocation: true,
                keys: ['name'],
                threshold: 0.2,
            }
            const fuse = new Fuse(sectors, fuseOptions)
            filteredSectorIDs = fuse.search(searchText).map(x => x.item.id)
        }

        let fuseResult = measurePool
        if (searchText !== '') {
            const fuseOptions = {
                includeScore: true,
                includeMatches: true,
                ignoreLocation: true,
                keys: ['name', 'measure_description'],
                threshold: 0.2,
            }
            const fuse = new Fuse(fuseResult, fuseOptions)
            fuseResult = fuse.search(searchText).map(x => x.item)
        }

        const filteredMeasures = measurePool.filter(
            m =>
                fuseResult.map(x => x.identifier).includes(m.identifier) ||
                // the following includes the measures that have a sector that matches the search text
                getArraysIntersection(
                    m.classification.map(c => c.sectors).flat(),
                    filteredSectorIDs
                ).length > 0
        )

        let filteredMeasuresBySector = []
        let filteredMeasuresByEconomicActivity = []
        let filteredMeasuresByEnvironmentalTarget = []
        let filteredMeasuresByEligibilityStatus = []
        let filteredMeasuresAll = []
        const filterArrays = []

        if (getters.sectorsFilter.length > 0) {
            filteredMeasuresBySector = filteredMeasures.filter(item => {
                return getters.sectorsFilter
                    .map(m => m.id)
                    .some(f => item.classification.some(c => c.sectors.includes(f)))
            })
            filterArrays.push('filteredMeasuresBySector')
        }

        if (getters.economicActivitiesFilter.length > 0) {
            filteredMeasuresByEconomicActivity = filteredMeasures.filter(item => {
                return getters.economicActivitiesFilter
                    .map(m => m.id)
                    .some(f => item.classification.some(c => c.economic_activities.includes(f)))
            })
            filterArrays.push('filteredMeasuresByEconomicActivity')
        }

        if (getters.environmentalTargetsFilter.length > 0) {
            filteredMeasuresByEnvironmentalTarget = filteredMeasures.filter(item => {
                return getters.environmentalTargetsFilter
                    .map(m => m.id)
                    .some(f => item.classification.some(c => c.environmental_targets.includes(f)))
            })
            filterArrays.push('filteredMeasuresByEnvironmentalTarget')
        }

        if (getters.eligibilityStatusFilter.length > 0) {
            filteredMeasuresByEligibilityStatus = filteredMeasures.filter(item => {
                const result = getters.eligibilityStatusFilter
                    .map(m => m.identifier)
                    .some(f => item.classification.some(c => c.waive_eligibility_check === f))
                return result
            })
            filterArrays.push('filteredMeasuresByEligibilityStatus')
        }

        filteredMeasuresAll = filteredMeasures

        if (filterArrays.length > 0) {
            const filteredMeasuresAllFiltered = filteredMeasures.filter(item => {
                if (
                    filterArrays.includes('filteredMeasuresBySector') &&
                    filteredMeasuresBySector.includes(item) === false
                ) {
                    return false
                }

                if (
                    filterArrays.includes('filteredMeasuresByEligibilityStatus') &&
                    filteredMeasuresByEligibilityStatus.includes(item) === false
                ) {
                    return false
                }
                if (
                    filterArrays.includes('filteredMeasuresByEconomicActivity') &&
                    filteredMeasuresByEconomicActivity.includes(item) === false
                ) {
                    return false
                }
                if (
                    filterArrays.includes('filteredMeasuresByEnvironmentalTarget') &&
                    filteredMeasuresByEnvironmentalTarget.includes(item) === false
                ) {
                    return false
                }
                return true
            })

            filteredMeasuresAll = filteredMeasuresAllFiltered
        }

        return filteredMeasuresAll
    },
    filteredSectors(state, getters) {
        const allsectors = getters.sectors.sort()
        const allmeasures = getters.filteredMeasures
        const measuresSectorsIds = [
            ...new Set(allmeasures.flatMap(m => m.classification.map(c => c.sectors)).flat()),
        ]
        const measuresSectorsList = allsectors
            .filter(s => measuresSectorsIds.includes(s.id))
            // eslint-disable-next-line func-names
            .sort(sortByName)
        return measuresSectorsList
    },
}

const actions = {
    searchText({ commit }, searchtext) {
        commit('searchText', searchtext)
    },
    resetSearchText({ commit }) {
        commit('searchText', '')
    },
    async getSectors({ state, rootGetters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        // console.log('sectorslocalchange', localeChange)
        if (state.sectors.length === 0 || localeChange) {
            axios
                .get('helpers/sectors', {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': rootGetters['global/locale'],
                    },
                })
                .catch(error => console.log(error))
                .then(response => commit('sectors', response.data.sort(sortByName)))
        }
    },
    async getClimateActionCategories({ state, rootGetters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        if (state.climateActionCategories.length === 0) {
            axios
                .get('helpers/climate_action_categories', {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': 'en',
                    },
                })
                .catch(error => console.log(error))
                .then(response => commit('climateActionCategories', response.data))
        }
    },
    async getEconomicActivities({ state, rootGetters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        if (state.economicActivities.length === 0 || localeChange) {
            axios
                .get('/helpers/economic_activities', {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': rootGetters['global/locale'],
                    },
                })
                .catch(error => console.log(error))
                .then(response => commit('economicActivities', response.data.sort(sortByName)))
        }
    },
    async getEnvironmentalTargets({ state, rootGetters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        if (state.environmentalTargets.length === 0 || localeChange) {
            axios
                .get('/helpers/environmental_targets', {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': rootGetters['global/locale'],
                    },
                })
                .catch(error => console.log(error))
                .then(response => commit('environmentalTargets', response.data.sort(sortByName)))
        }
    },
    async getMeasureMetaData({ dispatch }) {
        await dispatch('getSectors')
        await dispatch('getEconomicActivities')
        await dispatch('getClimateActionCategories')
        await dispatch('getEnvironmentalTargets')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
