<template>
    <div class="px-0 md:px-0 lg:px-3">
        <div class="lg:shadow-xl md:rounded-xl p-1 sm:py-8 sm:px-4 md:border border-gray-100">
            <div class="mx-6">
                <div class="mt-6">
                    <h5 class="h5 sm:text-md font-bold pt-3 pb-3">
                        {{ t('measureSummary.eligibleStatus') }}
                    </h5>
                    <div>
                        <EligibleBox :measure="currentMeasure" view="specification" />
                    </div>
                    <div
                        v-if="
                            currentMeasurePreCheckResult &&
                            !preCheckLoading &&
                            currentMeasurePreCheckResult.non_eligibility_statement !== ''
                        "
                        class="pt-3"
                    >
                        <span
                            class="text-eibineligible text-xs sm:text-base sm:font-semibold"
                            v-html="currentMeasurePreCheckResult.non_eligibility_statement"
                        ></span>
                    </div>
                    <div
                        v-if="
                            currentMeasurePreCheckResult &&
                            !preCheckLoading &&
                            currentMeasurePreCheckResult.impact_potential_statement !== ''
                        "
                        class="pt-3"
                    >
                        <span
                            class="text-eibgrey60 italic"
                            v-html="currentMeasurePreCheckResult.impact_potential_statement"
                        ></span>
                    </div>
                    <p
                        class="text-md sm:text-md font-bold pt-6 pb-1 border-b-4"
                        v-if="showImpacts()"
                    >
                        {{ t('measureSummary.estimatedImpacts') }}
                    </p>
                    <div class="grid grid-cols-1 xl:grid-cols-2">
                        <ResultItem
                            v-for="(r, index) in getFormatedPreResult"
                            v-bind:key="index"
                            :label="r.label"
                            :text="r.text"
                            :result="r.result"
                            :explanation="r.explanation"
                            :type="r.type"
                            :formatedResult="r.formatedResult"
                            :unit="r.unit"
                            :show="display_impact_potential && r.result != null"
                            :filter="['greenImpactPotential']"
                            class="grid"
                        />
                    </div>
                    <p
                        class="text-md sm:text-md font-bold pt-6 pb-1 border-b-4"
                        v-if="showImpacts('buildingInfo')"
                    >
                        {{ t('measureSummary.estimatedBuildingEnergyDemand') }}
                    </p>
                    <div class="grid grid-cols-1 xl:grid-cols-2">
                        <ResultItem
                            v-for="(r, index) in getFormatedPreResult"
                            v-bind:key="index"
                            :label="r.label"
                            :text="r.text"
                            :result="r.result"
                            :explanation="r.explanation"
                            :type="r.type"
                            :formatedResult="r.formatedResult"
                            :unit="r.unit"
                            :show="display_impact_potential && r.result != null"
                            :filter="['buildingInfo']"
                            class="grid"
                        />
                    </div>
                </div>
                <div class="flex flex-row justify-center gap-x-6 my-6 flex-wrap">
                    <BaseButton
                        @click="next('Summary')"
                        class="mt-2"
                        test="measureCalcBtn"
                        :disabled="eibGreenStatus === 'incomplete' || preCheckLoading"
                        :alt="!formValid ? t('assessment.specification.fillOutRequiredFields') : ''"
                        :title="
                            !formValid ? t('assessment.specification.fillOutRequiredFields') : ''
                        "
                    >
                        <span v-if="preCheckLoading">
                            {{ t('specification.loadingBtn') }}
                        </span>
                        <span
                            v-else-if="
                                currentMeasure &&
                                currentMeasurePreCheckResult &&
                                currentMeasurePreCheckResult.reporting_info &&
                                currentMeasurePreCheckResult.reporting_info.waive_eligibility_check
                            "
                        >
                            {{ t('specification.doneBtn') }}
                        </span>
                        <span v-else>{{ t('specification.doneBtn') }}</span>
                        <ArrowSmallRightIcon
                            class="h-6 w-6 ml-1 mr-0 float-right"
                            v-if="!preCheckLoading"
                        />
                    </BaseButton>
                </div>
                <div
                    class="flex flex-row justify-center gap-x-6 my-6 border-t-2 border-gray-100 pt-6 mt-12"
                >
                    <!-- delete measure -->
                    <BaseButton
                        @click="this.$refs.confirmAlertRef.showAlert()"
                        :primary="false"
                        size="sm"
                        class="text-eibgrey40 border-eibgrey40"
                    >
                        <XMarkIcon class="h-4 w-4 float-left mr-1" />
                        {{ t('general.cancelSpecification') }}
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
    <teleport to="body">
        <confirm-alert
            :title="t('input.modal.confirm.measure.delete.title')"
            :text="t('input.modal.confirm.measure.delete.text')"
            :confirmBtnText="t('input.modal.confirm.measure.delete.btn')"
            ref="confirmAlertRef"
            @confirm-clicked="deleteMeasure"
        ></confirm-alert>
    </teleport>
</template>

<script>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ResultItem from '@/components/common/ResultItem.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import {
    XMarkIcon,
    InformationCircleIcon,
    ArrowSmallRightIcon,
    TrashIcon,
    ArrowSmallLeftIcon,
} from '@heroicons/vue/24/outline'
import EligibleBox from '@/components/common/EligibleBox.vue'
import confirmAlert from '@/components/base/confirmAlert.vue'
import FormSwitch from '@/components/base/FormSwitch.vue'

export default {
    name: 'MeasureSummary',
    components: {
        XMarkIcon,
        InformationCircleIcon,
        ArrowSmallRightIcon,
        TrashIcon,
        ArrowSmallLeftIcon,
        ResultItem,
        BaseButton,
        confirmAlert,
        EligibleBox,
        FormSwitch,
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()
        const confirmAlertRef = ref(false)

        const advancedMode = computed(() => {
            return store.getters['measures/currentMeasure']['assessment_mode'] == 'extended'
                ? true
                : false
        })

        const currentMeasure = computed(() => {
            return store.getters['measures/currentMeasure']
        })
        const currentMeasurePreCheckResult = computed(() => {
            return store.getters['measures/currentMeasureResultPreCheck']
        })
        const getFormatedPreResult = computed(() => {
            return store.getters['measures/preResult']
        })
        const preCheckLoading = computed(() => {
            return store.getters['measures/preCheckLoading']
        })
        const showImpacts = (filter = 'greenImpactPotential') => {
            const items = getFormatedPreResult.value.filter(
                r =>
                    r.result !== null &&
                    r.result !== '' &&
                    r.result !== undefined &&
                    r.type === filter
            )

            return items.length > 0
        }
        const display_impact_potential = computed(() => {
            if (
                currentMeasure &&
                currentMeasurePreCheckResult &&
                currentMeasurePreCheckResult.value.reporting_info &&
                currentMeasurePreCheckResult.value.reporting_info.hasOwnProperty(
                    'display_impact_potential'
                )
            ) {
                return currentMeasurePreCheckResult.value.reporting_info.display_impact_potential
            } else {
                return true
            }
        })
        const formValid = computed(() => {
            return store.getters['global/formValid']
        })
        const eibGreenStatus = computed(() => {
            return store.getters['measures/eibGreenStatus'](currentMeasure.value, 'specification')
        })

        const next = navigateTo => {
            store.dispatch('measures/addPreResultToInvestment')
            const emptyPreResult = new Object()
            store.commit('measures/addPreCheckResult', emptyPreResult)

            router.push({ name: navigateTo })
        }

        const deleteMeasure = () => {
            store.commit('global/currentStep', 1)
            router.push('/selection')
            store.dispatch('measures/deleteMeasure', currentMeasure.value.uid)
            store.commit('measures/currentMeasureId', null)
        }

        return {
            currentMeasure,
            preCheckLoading,
            currentMeasurePreCheckResult,
            formValid,
            confirmAlertRef,
            t,
            getFormatedPreResult,
            showImpacts,
            next,
            deleteMeasure,
            advancedMode,
            display_impact_potential,
            eibGreenStatus,
        }
    },
}
</script>

<style scoped>
.flex-wrap {
    flex-wrap: wrap;
}
</style>
