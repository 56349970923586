/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from '@/plugins/axios.js'

import { getDefaults, prepareConditions, getMeasureInputDetails } from '../schemaPreperation'

const getSumOfObject = obj => {
    return obj && Object.keys(obj).length > 0 ? Object.values(obj).reduce((a, b) => a + b) : null
}

// Obj = energy_savings from measure result, target = 'CO2_conversion_factor' or 'kWh_conversion_factor'
const getSumByEnergySource = (obj, energyData, target) => {
    let result = null
    if (typeof obj !== 'undefined' && energyData && Object.keys(energyData).length > 0) {
        Object.keys(obj).forEach(s => {
            const source = energyData.find(e => e.energy_source.identifier === s)
            if (source) {
                const saving = obj[s] * source[target]
                result += saving
            }
        })
    }
    return result
}

const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0)

// const format = num => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ')
// Safari fix
const format = num => {
    if (num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
    return ' '
}

const state = {
    measurePool: [],
    measures: [],
    currentMeasureId: null,
    currentMeasureResultPreCheck: {},
    loading: false,
    preCheckLoading: false,
    serverError: false,
}

const measuresWithVehicles = [
    'vans',
    'trucks',
    'passenger_cars',
    'publictransport_rail',
    'publictransport_water',
    'publictransport_road',
    'freight_transport_rail',
    'freight_transport_water',
]

// Helper for generic mutations from: https://itnext.io/reusable-vuex-mutation-functions-9b4920aa737b
const set = key => (state, val) => {
    state[key] = val
}

const mutations = {
    measures(state, measures) {
        state.measures = Object.assign([], measures)
    },
    markMeasureReady(state, status) {
        const uid = state.currentMeasureId
        state.measures.find(m => m.uid === uid).ready = status
    },
    currentMeasureId(state, uid) {
        if (uid === null) {
            state.currentMeasureResultPreCheck = {}
        }
        state.currentMeasureId = uid
    },
    setMeasurePool: set('measurePool'),
    loading: set('loading'),
    preCheckLoading: set('preCheckLoading'),
    serverError: set('serverError'),
    addResultToMeasure(state, result) {
        // console.log('addresulttomeasure')
        const uid = state.currentMeasureId
        const currentMeasure = state.measures.find(m => m.uid === uid)
        currentMeasure.result = result
        // eslint-disable-next-line no-underscore-dangle
        if (window._paq) {
            // eslint-disable-next-line no-underscore-dangle
            window._paq.push([
                'trackEvent',
                'Measures',
                'Measure Completed',
                currentMeasure.name_fixed,
            ])
        }
    },
    addPreCheckResult(state, result) {
        state.currentMeasureResultPreCheck = {}
        Object.assign(state.currentMeasureResultPreCheck, result)
    },
    removePreCheckResult(state) {
        state.currentMeasureResultPreCheck = {}
    },
    modifyMeasure(state, measure) {
        // console.log(measure)
        Object.assign(
            state.measures.find(m => m.uid === measure.uid),
            measure
        )
    },
    deleteAllMeasures(state) {
        state.measures = []
    },
    resetAdvancedMode(state) {
        const currentMeasure = state.measures.find(m => m.uid === state.currentMeasureId)
        const { extendedSchema } = currentMeasure
        extendedSchema.forEach(element => {
            const defaultValue =
                typeof element.default_value === 'object'
                    ? element.default_value.value
                    : element.default_value
            currentMeasure.model[element.model] = defaultValue
        })
        currentMeasure.assessment_mode = 'simple'
    },
    setAdvancedMode(state, value) {
        const currentMeasure = state.measures.find(m => m.uid === state.currentMeasureId)
        if (value) {
            currentMeasure.assessment_mode = 'extended'
        } else {
            currentMeasure.assessment_mode = 'simple'
        }
    },
}

const getters = {
    assessmentMode(state) {
        // checking for one measure if defaults have been changed
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            return measure.result.assessment_mode
        }
    },
    measureExists(state) {
        return uid => {
            return state.measures.filter(m => m.uid === uid).length > 0
        }
    },
    measureExistsInPool(state) {
        return identifier => {
            // getMeasurePool
            const measureSearch = state.measurePool.find(m => m.url === identifier)
            return measureSearch === undefined ? false : measureSearch
        }
    },
    completeMeasures(state) {
        return state.measures.filter(m => m.result)
    },
    climateActionCategories(state, getters, rootState, rootGetters) {
        return rootGetters['filters/climateActionCategories']
    },
    climateActionCategoryOfSubProject(state, getters) {
        const eligibleMeasures = state.measures.filter(m => m.result.eligible === true)

        if (eligibleMeasures.length == 0) {
            return null
        }
        const allClimateActionCategories = getters.climateActionCategories

        let summaryByClimateActionCategory = {}

        eligibleMeasures.forEach(m => {
            const { investment_amount, reporting_info } = m.result
            const { reporting_id } = reporting_info
            if (summaryByClimateActionCategory[reporting_id]) {
                investment_amount === null
                    ? (summaryByClimateActionCategory[reporting_id].sum += 0)
                    : (summaryByClimateActionCategory[reporting_id].sum += investment_amount)
                summaryByClimateActionCategory[reporting_id].count++
            } else {
                summaryByClimateActionCategory[reporting_id] = {
                    sum: investment_amount === null ? 0 : investment_amount,
                    count: 1,
                }
            }
        })

        const arr = Object.entries(summaryByClimateActionCategory)

        // At least one investment amount value was specified -> return category with highest investment amount
        if (arr.filter(m => m[1].sum > 0).length > 0) {
            const sortedArr = arr.sort((a, b) => b[1].sum - a[1].sum)
            return allClimateActionCategories.find(c => c.id === parseInt(sortedArr[0][0])).name
        }
        // No investment amount value was specified -> return category with highest count
        const sortedArr = arr.sort((a, b) => b[1].count - a[1].count)
        return allClimateActionCategories.find(c => c.id === parseInt(sortedArr[0][0])).name
    },
    specifiedMeasures(state) {
        return state.measures.filter(m => m.result)
    },
    vehiclesCount: state => uid => {
        const measure = state.measures.find(m => m.uid === uid)
        if (
            measuresWithVehicles.some(v => measure.identifier.includes(v)) &&
            measure.result.number_of_units
        ) {
            return measure.result.number_of_units
        }
        return 0
    },
    completedMeasuresVehiclesCount(state, getters) {
        let vehicles = 0
        getters.completeMeasures.forEach(m => {
            vehicles += getters.vehiclesCount(m.uid)
        })
        // console.log(vehicles)
        return vehicles
    },
    completedMeasuresTaxonomyComplianceStatus(state) {
        const completedMeasuresCount = state.measures.filter(
            m => m.result && m.result.eligible === true
        ).length
        const compliantMeasuresCount = state.measures.filter(
            m =>
                m.result &&
                m.result.eligible === true &&
                m.result.reporting_info.taxonomy_activity_category !== null
        ).length

        if (completedMeasuresCount > 0 && completedMeasuresCount === compliantMeasuresCount) {
            return 'full'
        }
        if (completedMeasuresCount > 0 && compliantMeasuresCount === 0) {
            return 'n/a'
        }
        if (
            completedMeasuresCount > 0 &&
            compliantMeasuresCount > 0 &&
            compliantMeasuresCount < completedMeasuresCount
        ) {
            return 'partial'
        }

        return '-'
    },
    currentMeasure(state) {
        const measure = state.measures.find(m => m.uid === state.currentMeasureId)

        if (measure !== undefined) {
            measure.number > 1
                ? (measure.fullname = `${measure.name} (${measure.number})`)
                : (measure.fullname = measure.name)
        }
        return measure
    },
    currentMeasureId(state) {
        return state.currentMeasureId
    },
    currentMeasureResultPreCheck(state) {
        return state.currentMeasureResultPreCheck.results
    },
    displayImpactPotential(state) {
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)

            if (measure.result.impact_potential_statement !== undefined) {
                if (
                    measure.result.reporting_info.display_impact_potential === false ||
                    measure.result.impact_potential_statement !== null
                ) {
                    return false
                }
            }
            return measure.result.reporting_info.display_impact_potential
        }
    },
    reElectricity(state, getters) {
        return getters.energyData.filter(e => e.energy_source.identifier === 'feed_in')[0]
    },
    measures(state) {
        return state.measures
    },
    measuresCount(state) {
        return state.measures.length
    },
    completedMeasuresCount(state) {
        return state.measures.filter(m => m.hasOwnProperty('result')).length
    },
    loading(state) {
        return state.loading
    },
    preCheckLoading(state) {
        return state.preCheckLoading
    },
    serverError(state) {
        return state.serverError
    },
    sectors(state, getters, rootState, rootGetters) {
        return rootGetters['filters/sectors']
    },
    measurePool(state) {
        return state.measurePool
    },
    measureInputDetails(state) {
        // checking for one measure all input details
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            const { model } = measure
            // eslint-disable-next-line prefer-destructuring
            let schema = measure.schema
            if (measure.result.assessment_mode === 'extended') {
                schema = schema.concat(measure.extendedSchema)
            }
            const result = getMeasureInputDetails(model, schema)

            return result.join(' | ')
        }
    },
    measureDefaultsChanged(state) {
        // checking for one measure if defaults have been changed
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)

            if (Object.prototype.hasOwnProperty.call(measure, 'result')) {
                const schemadata = measure.schema.concat(measure.extendedSchema)
                // schemadata.filter(d => d.default !== undefined || d.model !== undefined)
                const modeldata = measure.model

                for (let i = 0; i < Object.keys(schemadata).length; i += 1) {
                    const measureModel = schemadata[i].model

                    let defaultValue = ''
                    if (typeof schemadata[i].default_value === 'object') {
                        defaultValue = schemadata[i].default_value.value
                    }
                    if (typeof schemadata[i].default_value === 'boolean') {
                        defaultValue = schemadata[i].default_value
                    }
                    if (typeof schemadata[i].default_value === 'number') {
                        defaultValue = schemadata[i].default_value
                        // console.log(
                        //     typeof schemadata[i].default_value,
                        //     schemadata[i].default_value
                        // )
                    }

                    if (defaultValue !== '' && modeldata[measureModel] !== undefined) {
                        if (modeldata[measureModel] !== defaultValue) {
                            return true
                        }
                    }
                }
            }

            return false
        }
    },
    energyData(state, getters, rootState, rootGetters) {
        return [...rootGetters['global/energyData']]
    },
    filteredSectors(state, getters) {
        const allsectors = getters.sectors.sort()
        const allmeasures = getters.filteredMeasures
        const measuresSectorsIds = [
            ...new Set(allmeasures.flatMap(m => m.classification.map(c => c.sectors)).flat()),
        ]
        const measuresSectorsList = allsectors.filter(s => measuresSectorsIds.includes(s.id))

        return measuresSectorsList
    },
    filteredMeasures(state, getters, rootState, rootGetters) {
        let searchText = rootGetters['filters/searchText']
        if (searchText !== '') {
            searchText = searchText.toLowerCase()
        }
        const allmeasures = state.measurePool.filter(m => m.name.toLowerCase().includes(searchText))

        return allmeasures
    },
    energyCostSaving(state, getters) {
        // energy cost savings for one measure
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            let result = 0
            const eeSavings = getSumByEnergySource(
                measure.result.energy_savings,
                getters.energyData,
                'price'
            )
            const reSavings =
                measure.result.estimated_electricity_generation * getters.reElectricity.price // TODO: value needs to be replaced by feed in tariff
            result += eeSavings + reSavings
            return result
        }
    },
    emissionSaving(state, getters) {
        // emission savings for one measure
        return uid => {
            let measureResult = {}
            if (!uid) {
                const preResult = state.currentMeasureResultPreCheck
                measureResult = preResult.results
            } else {
                const measure = state.measures.find(m => m.uid === uid)
                measureResult = measure.result
            }
            let result = null
            const eeSavings = getSumByEnergySource(
                measureResult.energy_savings,
                getters.energyData,
                'co2_conversion_factor'
            )
            let reSavings = null
            if (measureResult.estimated_electricity_generation) {
                const emissionFactorGrid = getters.energyData.filter(
                    e => e.energy_source.identifier === 'electricity'
                )[0]
                reSavings =
                    measureResult.estimated_electricity_generation *
                    emissionFactorGrid.co2_conversion_factor
            }
            if (eeSavings || reSavings) {
                result += eeSavings + reSavings
            }
            return result
        }
    },
    energySaving(state) {
        // energy savings for one measure
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            let result = 0
            const savings = measure.result.energy_savings
            result += sumValues(savings)
            return result
        }
    },
    primaryEnergySaving(state) {
        // energy produced by one measure
        return uid => {
            if (!uid) {
                return state.currentMeasureResultPreCheck.results.primary_energy_savings
            } else {
                const measure = state.measures.find(m => m.uid === uid)
                const result = measure.result.primary_energy_savings
                return result
            }
        }
    },
    energyProduced(state) {
        // energy produced by one measure
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            const result =
                measure.result.estimated_electricity_generation +
                measure.result.estimated_thermal_generation
            return result
        }
    },
    renewableCapacity(state) {
        // renewable capacity added by one measure
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            const result =
                measure.result.added_capacity_electricity_generation +
                measure.result.added_capacity_thermal_generation
            return result
        }
    },
    storageCapacity(state) {
        // renewable capacity added by one measure
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            const result = measure.result.energy_storage_capacity
            return result
        }
    },
    investmentAmount(state) {
        // investment amount for one measure
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            const result = measure.result.investment_amount
            return result
        }
    },
    measureComment(state) {
        return uid => {
            const measure = state.measures.find(m => m.uid === uid)
            const result = measure.result.comment
            return result
        }
    },
    eibGreenStatus(state, getters) {
        return (measure = null, view = 'specification') => {
            let measureResult = {}
            if (
                view == 'specification' &&
                typeof getters.currentMeasureResultPreCheck !== 'undefined' &&
                getters.currentMeasureResultPreCheck.constructor === Object &&
                Object.keys(getters.currentMeasureResultPreCheck).length > 0
            ) {
                measureResult = getters.currentMeasureResultPreCheck
            } else if (measure && measure.result) {
                measureResult = measure.result
            }
            if (measureResult.hasOwnProperty('eligible')) {
                if (measureResult.eligible === true) {
                    return 'eligible'
                }
                if (measureResult.eligible === false) {
                    return 'ineligible'
                }
                if (getters.serverError !== false) {
                    console.log(getters.serverError)
                    return 'error'
                }
            }
            return 'incomplete'
        }
    },
    parisAlignedStatus(state, getters) {
        return (measure = null, view = 'specification') => {
            let measureResult = {}
            if (
                view == 'specification' &&
                typeof getters.currentMeasureResultPreCheck !== 'undefined' &&
                getters.currentMeasureResultPreCheck.constructor === Object &&
                Object.keys(getters.currentMeasureResultPreCheck).length > 0
            ) {
                measureResult = getters.currentMeasureResultPreCheck
            } else if (measure && measure.result) {
                measureResult = measure.result
            }
            if (measureResult.hasOwnProperty('eligible')) {
                if (measureResult.paris_aligned === true) {
                    return 'eligible'
                }
                if (measureResult.paris_aligned === false) {
                    return 'ineligible'
                }
            }
            return 'incomplete'
        }
    },
    // Results for all EIB Green measures
    results(state, getters) {
        // eslint-disable-next-line prefer-destructuring
        const completeMeasures = getters.completeMeasures

        const fixed = (value, units) => {
            if (typeof value === 'number') {
                if (units) {
                    return value.toFixed(units)
                }
                return value.toFixed(0)
            }
            return value
        }

        // eslint-disable-next-line prefer-const
        let waterSavings = 0
        let primaryEnergySavings = 0
        let energySavings = 0
        let co2savings = 0
        let renewableCapacity = 0
        let storageCapacity = 0
        let costSavings = 0
        let investmentCosts = 0
        let electricityGeneration = 0
        let categoryCodes = []
        if (completeMeasures && completeMeasures.length > 0) {
            energySavings = format(
                fixed(
                    completeMeasures
                        .map(m => getters.energySaving(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            primaryEnergySavings = format(
                fixed(
                    completeMeasures
                        .map(m => getters.primaryEnergySaving(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            electricityGeneration = format(
                fixed(
                    completeMeasures
                        .map(m => getters.energyProduced(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            renewableCapacity = format(
                fixed(
                    completeMeasures
                        .map(m => getters.renewableCapacity(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            storageCapacity = format(
                fixed(
                    completeMeasures
                        .map(m => getters.storageCapacity(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            co2savings = format(
                fixed(
                    completeMeasures
                        .map(m => getters.emissionSaving(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            costSavings = format(
                fixed(
                    completeMeasures
                        .map(m => getters.energyCostSaving(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            investmentCosts = format(
                fixed(
                    completeMeasures
                        .map(m => getters.investmentAmount(m.uid))
                        .reduce((a, b) => a + b, 0)
                )
            )
            categoryCodes = completeMeasures.map(m =>
                String(m.result.reporting_info.taxonomy_activity_category)
            )
        }
        return {
            energySavings,
            primaryEnergySavings,
            co2savings,
            renewableCapacity,
            storageCapacity,
            costSavings,
            electricityGeneration,
            categoryCodes,
            waterSavings,
            investmentCosts,
        }
    },
    result: (state, getters) => uid => {
        const measure = state.measures.find(m => m.uid === uid)

        const fixed = (value, units) => {
            if (typeof value === 'number') {
                if (units) {
                    return value.toFixed(units)
                }
                return value.toFixed(0)
            }
            // console.log(value)
            return value
        }

        let resultFormated = []
        if (measure && Object.prototype.hasOwnProperty.call(measure, 'result')) {
            const r = measure.result

            // Needs refactor with functions added under results above
            resultFormated = [
                // TODO: Energy savings und emission savings nur einmal berechnen
                {
                    label: 'summary.results.estimatedCO2',
                    text: `${format(
                        fixed(getters.emissionSaving(uid))
                    )} <small>kg C0<sub>2</sub>e/year</small>`,
                    result: getters.emissionSaving(uid),
                    formatedResult: format(fixed(getters.emissionSaving(uid))),
                    unit: 'general.units.kgCO2eYear',
                    id: 'co2e_savings',
                    type: 'greenImpactPotential',
                    // explanation: 'CO2e savings explanation text',
                },
                {
                    label: 'summary.results.primaryEnergySavings',
                    text:
                        getters.primaryEnergySaving(uid) !== null
                            ? `${format(
                                  fixed(getters.primaryEnergySaving(uid))
                              )} <small>kWh/year</small>`
                            : `${format(getters.primaryEnergySaving(uid))} <small>kWh/year</small>`,
                    result: getters.primaryEnergySaving(uid),
                    formatedResult:
                        getters.primaryEnergySaving(uid) !== null
                            ? format(fixed(getters.primaryEnergySaving(uid)))
                            : format(getters.primaryEnergySaving(uid)),
                    unit: 'general.units.kWhYear',
                    type: 'greenImpactPotential',
                    explanation: 'explanation.primaryEnergySavings',
                    id: 'primary_energy_savings',
                },
                {
                    label: 'summary.results.energySavings',
                    text: `${format(
                        fixed(getSumOfObject(r.energy_savings))
                    )} <small>kWh/year</small>`,
                    result: getSumOfObject(r.energy_savings),
                    formatedResult: format(fixed(getSumOfObject(r.energy_savings))),
                    unit: 'general.units.kWhYear',
                    type: 'greenImpactPotential',
                    explanation: 'explanation.finalEnergySavings',
                    id: 'energy_savings',
                },
                {
                    label: 'summary.results.estimatedRE',
                    text: `${format(
                        fixed(r.estimated_electricity_generation)
                    )} <small>kWh/year</small>`,
                    formatedResult: format(fixed(r.estimated_electricity_generation)),
                    result: r.estimated_electricity_generation,
                    unit: 'general.units.kWhYear',
                    id: 'estimated_electricity_generation',
                    type: 'greenImpactPotential',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.estimatedRE',
                    text: `${format(
                        fixed(r.estimated_thermal_generation)
                    )} <small>kWh/year</small>`,
                    formatedResult: format(fixed(r.estimated_thermal_generation)),
                    result: r.estimated_thermal_generation,
                    unit: 'general.units.kWhYear',
                    id: 'estimated_thermal_generation',
                    type: 'greenImpactPotential',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.addedStorageCapacity',
                    text: `${format(fixed(r.energy_storage_capacity))} <small>kWh</small>`,
                    result: r.energy_storage_capacity,
                    formatedResult: format(fixed(r.energy_storage_capacity)),
                    unit: 'units.kWh',
                    id: 'energy_storage_capacity',
                    type: 'greenImpactPotential',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.estimatedCostSavings',
                    text: `${format(
                        fixed(getters.energyCostSaving(uid), 0)
                    )} <small>€/year</small>`,
                    result: getters.energyCostSaving(uid),
                    formatedResult: format(fixed(getters.energyCostSaving(uid), 0)),
                    unit: 'general.units.euroYear',
                    type: 'financialImpactPotential',
                    id: 'costsavings',
                    explanation: 'explanation.estimatedCostSavings',
                },
                {
                    label: 'summary.results.investmentAmount',
                    text: measure.model.investment_amount
                        ? `${format(fixed(measure.model.investment_amount))} <small>€</small>`
                        : 'general.notSpecified',
                    result: measure.model.investment_amount,
                    type: 'financialData',
                    id: 'investment_amount',
                    // explanation: 'Investment cost explanation text',
                },
            ]
            // }
        }
        return resultFormated
    },
    preResult: (state, getters) => {
        const fixed = (value, units) => {
            if (typeof value === 'number') {
                if (units) {
                    return value.toFixed(units)
                }
                return value.toFixed(0)
            }
            // console.log(value)
            return value
        }
        const preResult = state.currentMeasureResultPreCheck
        let resultFormated = []
        if (preResult && Object.prototype.hasOwnProperty.call(preResult, 'results')) {
            const r = preResult.results

            // Needs refactor with functions added under results above
            resultFormated = [
                // TODO: Energy savings und emission savings nur einmal berechnen
                {
                    label: 'summary.results.estimatedCO2',
                    text: `${format(
                        fixed(getters.emissionSaving())
                    )} <small>kg C0<sub>2</sub>e/year</small>`,
                    result: getters.emissionSaving(),
                    formatedResult: format(fixed(getters.emissionSaving())),
                    unit: 'general.units.kgCO2eYear',
                    id: 'co2e_savings',
                    type: 'greenImpactPotential',
                    // explanation: 'CO2e savings explanation text',
                },
                {
                    label: 'summary.results.primaryEnergySavings',
                    text:
                        getters.primaryEnergySaving() !== null
                            ? `${format(
                                  fixed(getters.primaryEnergySaving())
                              )} <small>kWh/year</small>`
                            : `${format(getters.primaryEnergySaving())} <small>kWh/year</small>`,
                    result: getters.primaryEnergySaving(),
                    formatedResult:
                        getters.primaryEnergySaving() !== null
                            ? format(fixed(getters.primaryEnergySaving()))
                            : format(getters.primaryEnergySaving()),
                    unit: 'general.units.kWhYear',
                    type: 'greenImpactPotential',
                    explanation: 'explanation.primaryEnergySavings',
                    id: 'primary_energy_savings',
                },
                {
                    label: 'summary.results.energySavings',
                    text: `${format(
                        fixed(getSumOfObject(r.energy_savings))
                    )} <small>kWh/year</small>`,
                    result: getSumOfObject(r.energy_savings),
                    formatedResult: format(fixed(getSumOfObject(r.energy_savings))),
                    unit: 'general.units.kWhYear',
                    type: 'greenImpactPotential',
                    explanation: 'explanation.finalEnergySavings',
                    id: 'energy_savings',
                },
                {
                    label: 'summary.results.estimatedRE',
                    text: `${format(
                        fixed(r.estimated_electricity_generation)
                    )} <small>kWh/year</small>`,
                    formatedResult: format(fixed(r.estimated_electricity_generation)),
                    result: r.estimated_electricity_generation,
                    unit: 'general.units.kWhYear',
                    id: 'estimated_electricity_generation',
                    type: 'greenImpactPotential',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.estimatedRE',
                    text: `${format(
                        fixed(r.estimated_thermal_generation)
                    )} <small>kWh/year</small>`,
                    formatedResult: format(fixed(r.estimated_thermal_generation)),
                    result: r.estimated_thermal_generation,
                    unit: 'general.units.kWhYear',
                    id: 'estimated_thermal_generation',
                    type: 'greenImpactPotential',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.addedStorageCapacity',
                    text: `${format(fixed(r.energy_storage_capacity))} <small>kWh</small>`,
                    result: r.energy_storage_capacity,
                    formatedResult: format(fixed(r.energy_storage_capacity)),
                    unit: 'units.kWh',
                    id: 'energy_storage_capacity',
                    type: 'greenImpactPotential',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.specificFinalEnergyOld',
                    text: `${format(fixed(r.energy_storage_capacity))} <small>kWh/(m²a)</small>`,
                    result: r.baseline?.specific_final_energy_consumption?.before,
                    formatedResult: format(
                        fixed(r.baseline?.specific_final_energy_consumption?.before)
                    ),
                    unit: 'units.kWhm2Year',
                    id: 'specific_final_energy_consumption_before',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.specificFinalEnergyNew',
                    text: `${format(fixed(r.energy_storage_capacity))} <small>kWh/(m²a)</small>`,
                    result: r.baseline?.specific_final_energy_consumption?.after,
                    formatedResult: format(
                        fixed(r.baseline?.specific_final_energy_consumption?.after)
                    ),
                    unit: 'units.kWhm2Year',
                    id: 'specific_final_energy_consumption_after',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.specificPrimaryEnergyOld',
                    text: `${format(fixed(r.energy_storage_capacity))} <small>kWh/(m²a)</small>`,
                    result: r.baseline?.specific_primary_energy_consumption?.before,
                    formatedResult: format(
                        fixed(r.baseline?.specific_primary_energy_consumption?.before)
                    ),
                    unit: 'units.kWhm2Year',
                    id: 'specific_primary_energy_consumption_before',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.specificPrimaryEnergyNew',
                    text: `${format(
                        fixed(r.baseline?.specific_primary_energy_consumption?.after)
                    )} <small>kWh/(m²a)</small>`,
                    result: r.baseline?.specific_primary_energy_consumption?.after,
                    formatedResult: format(
                        fixed(r.baseline?.specific_primary_energy_consumption?.after)
                    ),
                    unit: 'units.kWhm2Year',
                    id: 'specific_primary_energy_consumption_after',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.specificPrimaryEnergyWithoutREOld',
                    text: `${format(fixed(r.energy_storage_capacity))} <small>kWh/(m²a)</small>`,
                    result: r.baseline?.specific_primary_energy_consumption_without_RE?.before,
                    formatedResult: format(
                        fixed(r.baseline?.specific_primary_energy_consumption_without_RE?.before)
                    ),
                    unit: 'units.kWhm2Year',
                    id: 'specific_primary_energy_consumption_without_RE_before',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.specificPrimaryEnergyWithoutRENew',
                    text: `${format(
                        fixed(r.baseline?.specific_primary_energy_consumption_without_RE?.after)
                    )} <small>kWh/(m²a)</small>`,
                    result: r.baseline?.specific_primary_energy_consumption_without_RE?.after,
                    formatedResult: format(
                        fixed(r.baseline?.specific_primary_energy_consumption_without_RE?.after)
                    ),
                    unit: 'units.kWhm2Year',
                    id: 'specific_primary_energy_consumption_without_RE_after',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.PrimaryEnergyOld',
                    text: `${format(
                        fixed(r.baseline?.primary_energy_consumption?.before)
                    )} <small>kWh/(a)</small>`,
                    result: r.baseline?.primary_energy_consumption?.before,
                    formatedResult: format(fixed(r.baseline?.primary_energy_consumption?.before)),
                    unit: 'general.units.kWhYear',
                    id: 'specific_primary_energy_consumption_before',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.PrimaryEnergyNew',
                    text: `${format(
                        fixed(r.baseline?.primary_energy_consumption?.after)
                    )} <small>kWh/(a)</small>`,
                    result: r.baseline?.primary_energy_consumption?.after,
                    formatedResult: format(fixed(r.baseline?.primary_energy_consumption?.after)),
                    unit: 'general.units.kWhYear',
                    id: 'specific_primary_energy_consumption_after',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.PrimaryEnergyOldWithoutRE',
                    text: `${format(
                        fixed(r.baseline?.primary_energy_consumption_without_RE?.before)
                    )} <small>kWh/(a)</small>`,
                    result: r.baseline?.primary_energy_consumption_without_RE?.before,
                    formatedResult: format(
                        fixed(r.baseline?.primary_energy_consumption_without_RE?.before)
                    ),
                    unit: 'general.units.kWhYear',
                    id: 'specific_primary_energy_consumption_without_RE_before',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                {
                    label: 'summary.results.baseline.PrimaryEnergyNewWithoutRE',
                    text: `${format(
                        fixed(r.baseline?.primary_energy_consumption_without_RE?.after)
                    )} <small>kWh/(a)</small>`,
                    result: r.baseline?.primary_energy_consumption_without_RE?.after,
                    formatedResult: format(
                        fixed(r.baseline?.primary_energy_consumption_without_RE?.after)
                    ),
                    unit: 'general.units.kWhYear',
                    id: 'specific_primary_energy_consumption_without_RE_after',
                    type: 'buildingInfo',
                    // explanation: 'Renewable energy generation explanation text',
                },
                // {
                //     label: 'summary.results.estimatedCostSavings',
                //     text: `${format(
                //         fixed(getters.energyCostSaving(uid), 0)
                //     )} <small>€/year</small>`,
                //     result: getters.energyCostSaving(uid),
                //     formatedResult: format(fixed(getters.energyCostSaving(uid), 0)),
                //     unit: 'general.units.euroYear',
                //     type: 'financialImpactPotential',
                //     id: 'costsavings',
                //     explanation: 'explanation.estimatedCostSavings',
                // },
                // {
                //     label: 'summary.results.investmentAmount',
                //     text: measure.model.investment_amount
                //         ? `${format(fixed(measure.model.investment_amount))} <small>€</small>`
                //         : 'general.notSpecified',
                //     result: measure.model.investment_amount,
                //     type: 'financialData',
                //     id: 'investment_amount',
                //     // explanation: 'Investment cost explanation text',
                // },
            ]
            // }
        }
        return resultFormated
    },
    sbciUseCaseIdsOfCompleteMeasures: (state, getters) => {
        return state.measures
            .filter(m => m.result)
            .map(m => m.result.reporting_info.reporting_id)
            .join(', ')
    },
}

const actions = {
    async addMeasure({ state, commit, dispatch }, measure) {
        // commit('global/formValidSimple', false, { root: true })
        // commit('global/formValidAdvanced', false, { root: true })
        const uid = () => {
            const d = new Date().getTime()
            return d.toString(16)
        }
        measure.model = {}
        measure = await dispatch('getSchema', measure)
        const { measures } = state
        const measureCopy = { ...measure }

        const numberOfMeasuresOfSameType = state.measures.filter(
            m => m.name === measure.name
        ).length

        if (numberOfMeasuresOfSameType > 0) {
            measureCopy.number = `${numberOfMeasuresOfSameType + 1}`
        } else {
            measureCopy.number = 1
        }

        measureCopy.uid = uid()

        measures.push(measureCopy)
        await commit('measures', measures)
        await commit('currentMeasureId', measureCopy.uid)
        // eslint-disable-next-line no-underscore-dangle
        if (window._paq && measure.name_fixed) {
            // eslint-disable-next-line no-underscore-dangle
            window._paq.push(['trackEvent', 'Measures', 'Measure Added', measure.name_fixed])
        }
        commit('filters/searchText', '', { root: true })
    },
    async updateMeasuresLanguage({ state, commit, getters, dispatch }) {
        const measures = getters.measures
        const measurePool = getters.measurePool

        let newMeasures = []
        // console.log('newMeasures Array', newMeasures)

        measures.forEach(async m => {
            let measureFromPool = measurePool.find(mfp => mfp.identifier === m.identifier)

            const numberOfMeasuresOfSameType = measures.filter(fm => fm.name === m.name).length

            // @Silas: das geht hier irgendwie noch nicht ...
            let translatedSchema = await dispatch('getSchema', m)

            // console.log('transaltedschema', measureFromPool)
            if (numberOfMeasuresOfSameType > 0) {
                m.fullname = `${m.name} (${numberOfMeasuresOfSameType + 1})`
            } else {
                m.fullname = m.name
            }

            const newMeasure = { ...m, ...measureFromPool }
            // console.log('newmeasure', newMeasure)
            // console.log('poolmeasure', measureFromPool)
            // console.log('oldmeasure', m)
            newMeasure.schema = translatedSchema.schema
            newMeasure.metaSchema = translatedSchema.metaSchema
            newMeasure.extendedSchema = translatedSchema.extendedSchema
            newMeasure.model = m.model
            // console.log('newMeasure', newMeasure)
            newMeasures.push(newMeasure)
        })

        // console.log('newMeasures Array', newMeasures)
        Object.assign(state.measures, newMeasures)
    },

    deleteMeasure({ state, commit }, uid) {
        const measures = state.measures.filter(m => m.uid !== uid)
        commit('measures', measures)
    },
    deleteMeasures({ commit }) {
        const measures = []
        commit('measures', measures)
    },
    async getMeasurePool({ state, rootGetters, commit }, intermediary = null) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        const fi = rootGetters['auth/financialIntermediaryId']
        const fiJourney = rootGetters['auth/fiJourney']
        const locale = rootGetters['global/locale']
        // TODO: [EIBG-273] This might need to be refactored as it (sometimes?) doesn't pick up when the URL is change to an FI subdomain AFTER it was already on the general domain.
        if (state.measurePool.length === 0 || localeChange) {
            localeChange ? commit('setMeasurePool', []) : ''
            let fiId = 'eib'
            fiJourney && fi !== null ? (fiId = fi) : 'eib'
            fiJourney && intermediary ? (fiId = intermediary) : 'eib'

            commit('loading', true)
            await axios
                .get(`/helpers/clients/${fiId}/measure_package/?contractor=eib`, {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': locale,
                    },
                })
                .catch(error => {
                    console.log(error)
                    commit('loading', false)
                })
                .then(response => {
                    // eslint-disable-next-line func-names
                    response.data.measure_versions.sort(function (a, b) {
                        const nameA = a.name.toUpperCase() // Groß-/Kleinschreibung ignorieren
                        const nameB = b.name.toUpperCase() // Groß-/Kleinschreibung ignorieren
                        if (nameA < nameB) {
                            return -1
                        }
                        if (nameA > nameB) {
                            return 1
                        }

                        // Namen müssen gleich sein
                        return 0
                    })
                    commit('setMeasurePool', response.data.measure_versions)
                    commit('loading', false)

                    return response.data
                })
        }
    },
    async getSchema({ rootGetters }, measure) {
        const APIToken = rootGetters['auth/APIToken']
        const country = rootGetters['global/country']
        const locale = rootGetters['global/locale']
        const fiData = rootGetters['auth/financialIntermediary']

        const reporting_format = fiData === null ? 'eib_greenchecker' : fiData.reporting_format
        const schemaRaw = await axios
            .get(`/${measure.url}/`, {
                headers: {
                    Authorization: `Token ${APIToken}`,
                    'Accept-Language': locale,
                },
                params: {
                    country: country.value,
                    reporting_format: reporting_format,
                    version: measure.version_number,
                },
            })
            .catch(err => {
                console.log(err)
            })

        measure.model = await getDefaults(schemaRaw)
        const schema = await prepareConditions(
            schemaRaw.data.filter(s => s.model !== 'extended_schema' && s.model !== 'meta_fields'),
            measure.model
        )
        const extendedSchema = await prepareConditions(
            schemaRaw.data.find(s => s.model === 'extended_schema').schema,
            measure.model
        )
        const metaSchema = await prepareConditions(
            schemaRaw.data.find(s => s.model === 'meta_fields').schema,
            measure.model
        )
        measure.schema = schema
        measure.extendedSchema = extendedSchema
        measure.metaSchema = metaSchema
        return measure
    },
    async getResult({ rootGetters, commit }, measure) {
        const country = rootGetters['global/countrycode']
        const locale = rootGetters['global/locale']
        const APIToken = rootGetters['auth/APIToken']
        const fiData = rootGetters['auth/financialIntermediary']
        const start = Date.now()
        commit('preCheckLoading', true)
        commit('serverError', false)
        // console.log('model', measure.model)

        const reporting_format = fiData === null ? 'eib_greenchecker' : fiData.reporting_format
        const result = await axios
            .post(`/${measure.url}/`, measure.model, {
                headers: {
                    Authorization: `Token ${APIToken}`,
                    'Accept-Language': locale,
                },
                params: {
                    country: country,
                    reporting_format: reporting_format,
                    version: measure.version_number,
                },
            })
            .catch(err => {
                console.log(err)
                commit('preCheckLoading', false)
                commit('serverError', err)
            })
        if (result.status === 200) {
            // console.log(result.status)
            result.data.assessment_mode = measure.assessment_mode
            const timeElapsed = Date.now() - start

            commit('addPreCheckResult', result.data)
            if (timeElapsed >= 500) {
                commit('preCheckLoading', false)
            } else {
                const timeDelay = 500 - timeElapsed
                setTimeout(() => {
                    commit('preCheckLoading', false)
                }, timeDelay)
            }
        } else {
            console.log('Error while getting result')
            commit('preCheckLoading', false)
        }
    },
    async addPreResultToInvestment({ state, getters, commit, dispatch }) {
        const measure = getters['currentMeasure']
        await dispatch('getResult', measure)
        measure['result'] = state['currentMeasureResultPreCheck'].results
        measure['human_readable_request'] =
            state['currentMeasureResultPreCheck'].human_readable_request
        measure['request'] = state['currentMeasureResultPreCheck'].request
        measure['hash'] = state['currentMeasureResultPreCheck'].hash

        dispatch('trackSelectedOptions', measure)
        commit('modifyMeasure', measure)
    },
    trackSelectedOptions({ state }, measure) {
        if (measure.identifier.includes('photovoltaic') && window._paq) {
            window._paq.push([
                'trackEvent',
                'Measure Specification',
                'Photovoltaic: Size Specification',
                measure.request.size_specification,
            ])
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
