<template>
    <div v-if="errorMessage" class="rounded-md bg-red-50 p-2 my-2">
        <div class="flex">
            <span><ExclamationCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" /></span>
            <span class="ml-2 text-sm">{{ errorMessage }}</span>
        </div>
    </div>
</template>

<script>
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

export default {
    components: { ExclamationCircleIcon },
    props: { errorMessage: { type: String } },
    setup() {},
}
</script>
