/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import axios from '@/plugins/axios.js'

function arrayEquals(a, b) {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    )
}
const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase() // Groß-/Kleinschreibung ignorieren
    const nameB = b.name.toUpperCase() // Groß-/Kleinschreibung ignorieren
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // Namen müssen gleich sein
    return 0
}

const state = {
    countries: [],
    country: null,
    energySources: [],
    energyData: [],
    energyDataOriginal: [],
    faqs: [],
    disclaimerConfirmed: false,
    countryConfirmed: false,
    rememberCountry: false,
    currentStep: 1,
    comment: '',
    reportID: '',
    clientReferenceId: '',
    beneficiaryDetails: {},
    formValidSimple: true,
    formValidAdvanced: true,
    stagingAccess: false,
    localeChange: false,
    locale: '',
    taxonomyActivities: [],
}

// Helper for generic mutations from: https://itnext.io/reusable-vuex-mutation-functions-9b4920aa737b
const set = key => (state, val) => {
    // eslint-disable-next-line no-param-reassign
    state[key] = val
}

const mutations = {
    beneficiaryDetails: set('beneficiaryDetails'),
    clientReferenceId: set('clientReferenceId'),
    comment: set('comment'),
    country: set('country'),
    reportID: set('reportID'),
    countryConfirmed: set('countryConfirmed'),
    disclaimerConfirmed: set('disclaimerConfirmed'),
    countries: set('countries'),
    setFAQs: set('faqs'),
    currentStep: set('currentStep'),
    deleteStoredCountrySelection() {
        localStorage.removeItem('country')
    },
    formValidSimple: set('formValidSimple'),
    formValidAdvanced: set('formValidAdvanced'),
    locale: set('locale'),
    localeChange: set('localeChange'),
    rememberCountry: set('rememberCountry'),
    setCountries: set('countries'),
    setEnergySources: set('energySources'),
    // setEnergyData: set('energyData'),
    // setEnergyDataOriginal: set('energyDataOriginal'),
    setEnergyData(state, energyData) {
        state.energyData = Object.assign([], energyData)
    },
    setEnergyDataOriginal(state, energyData) {
        state.energyDataOriginal = Object.assign([], energyData)
    },
    setTaxonomyActivities: set('taxonomyActivities'),
    stagingAccess: set('stagingAccess'),
    storeDisclaimerConfirmed(state) {
        localStorage.setItem('disclaimerConfirmed', Date.now())
    },
    storeCountrySelection(state) {
        localStorage.setItem(
            'country',
            JSON.stringify({ text: state.country.text, value: state.country.value })
        )
    },
}

const getters = {
    beneficiaryDetails(state) {
        return state.beneficiaryDetails
    },
    formValid(state) {
        return state.formValidSimple && state.formValidAdvanced
    },
    clientReferenceId(state) {
        return state.clientReferenceId
    },
    comment(state) {
        return state.comment
    },
    countries(state) {
        return state.countries
    },
    faqs(state) {
        return state.faqs
    },
    country(state) {
        if (state.country) {
            return state.country
        }
        return null
        // return state.countries[0]
    },
    countrycode(state) {
        if (state.country) {
            return state.country.value
        }
        return null
        // return state.countries[0]
    },
    countryConfirmed(state) {
        return state.countryConfirmed
    },
    currentStep(state) {
        return state.currentStep
    },
    disclaimerConfirmed(state) {
        return state.disclaimerConfirmed
    },
    energySources(state) {
        return state.energySources
    },
    energyData(state) {
        return state.energyData
    },
    energyDataChanged(state) {
        return !arrayEquals(
            state.energyData.map(m => parseFloat(m.price)),
            state.energyDataOriginal.map(m => parseFloat(m.price))
        )
    },
    energyDataOriginal(state) {
        return state.energyDataOriginal
    },
    locale(state) {
        return state.locale
    },
    localeChange(state) {
        return state.localeChange
    },
    rememberCountry(state) {
        return state.rememberCountry
    },
    reportID(state) {
        return state.reportID
    },
    stagingAccess(state) {
        return state.stagingAccess
    },
    taxonomyActivities(state) {
        return state.taxonomyActivities
    },
    taxonomyActivityDetails(state) {
        return tid => {
            const activity = state.taxonomyActivities.find(a => a.id === tid)
            return activity !== undefined ? activity : null
        }
    },
}

const actions = {
    deleteAdditionalInformation({ getters, commit }) {
        const beneficiaryDetails = {}
        beneficiaryDetails.country = getters.country.text
        commit('beneficiaryDetails', beneficiaryDetails)
        commit('comment', '')
        commit('clientReferenceId', '')
    },
    setCountry({ commit, dispatch }, newCountry) {
        commit('country', newCountry)
        // eslint-disable-next-line no-underscore-dangle
        if (window._paq) {
            window._paq.push(['trackEvent', 'Configuration', 'Country Set', newCountry.value])
        }
        dispatch('getEnergyData')
    },
    getCountries({ state, rootGetters, getters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        if (getters.countries.length === 0 || localeChange) {
            axios
                .get('/helpers/countries', {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': getters.locale,
                    },
                })
                .catch(err => {
                    console.log(err)
                })
                .then(response => response.data.sort(sortByName))
                .then(countries => {
                    // console.log(countries)
                    const countrylist = countries.map(m => ({
                        value: m.identifier,
                        text: m.name,
                    }))
                    commit('setCountries', countrylist)

                    // Update stored country name upon language change
                    const currentCountryCode = getters['countrycode']
                    const newCountry = countrylist.find(c => c.value === currentCountryCode)
                    commit('country', newCountry)
                })
        }
    },
    async getFAQs({ rootGetters, getters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const fiFAQPackage = rootGetters['auth/financialIntermediaryFAQPackage']
        axios
            .get(`/helpers/faq_packages/${fiFAQPackage}`, {
                headers: {
                    Authorization: `Token ${APIToken}`,
                    'Accept-Language': getters.locale,
                },
            })
            .catch(err => {
                console.log(err)
            })
            .then(response =>
                response.data.faq_items.sort((a, b) => Number(a.order) - Number(b.order))
            )
            .then(faqs => {
                commit('setFAQs', faqs)
            })
    },
    async getEnergySources({ state, rootGetters, getters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        const country = getters.countrycode
        if ((country && state.energySources.length === 0) || localeChange) {
            axios
                .get(`/helpers/energy_sources`, {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': getters.locale,
                    },
                    params: {
                        country: country,
                    },
                })
                .catch(err => {
                    console.log(err)
                })
                .then(response => response.data)
                .then(energySources => {
                    commit('setEnergySources', energySources)
                })
        }
    },
    async getTaxonomyActivities({ state, rootGetters, getters, commit }) {
        const APIToken = rootGetters['auth/APIToken']
        const localeChange = rootGetters['global/localeChange']
        if (state.taxonomyActivities.length === 0 || localeChange) {
            axios
                .get(`/helpers/taxonomy_activity_categories`, {
                    headers: {
                        Authorization: `Token ${APIToken}`,
                        'Accept-Language': getters.locale,
                    },
                })
                .catch(err => {
                    console.log(err)
                })
                .then(response => response.data)
                .then(taxonomyActivities => {
                    commit('setTaxonomyActivities', taxonomyActivities)
                })
        }
    },
    async getEnergyData({ getters, rootGetters, commit }) {
        const country = getters.countrycode
        const APIToken = rootGetters['auth/APIToken']

        axios
            .get(`/helpers/energy_data`, {
                headers: {
                    Authorization: `Token ${APIToken}`,
                    'Accept-Language': getters.locale,
                },
                params: {
                    country: country,
                },
            })
            .catch(err => {
                console.log(err)
            })
            .then(response => {
                commit('setEnergyData', response.data)
                commit('setEnergyDataOriginal', JSON.parse(JSON.stringify(response.data)))
            })
    },
    async getEnergySourceData({ getters, rootGetters, dispatch }) {
        const country = getters.countrycode
        const localeChange = rootGetters['global/localeChange']
        if ((country && state.energyData.length === 0) || localeChange) {
            await dispatch('getEnergyData')
        }
    },
    async resetEnergyData({ state, commit }) {
        commit('setEnergyData', JSON.parse(JSON.stringify(state.energyDataOriginal)))
    },
    initialiseStore({ commit }) {
        const disclaimerConfirmed = localStorage.getItem('disclaimerConfirmed')
        if (disclaimerConfirmed) {
            commit('disclaimerConfirmed', true)
        }

        const localCountry = localStorage.getItem('country')
        if (localCountry && state.country === null) {
            const localCountryObj = JSON.parse(localCountry)
            commit('country', { text: localCountryObj.text, value: localCountryObj.value })
            commit('countryConfirmed', true)
            commit('rememberCountry', true)
        }
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
