<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div
        :class="[
            'relative flex py-1 px-4 border border-gray-300 rounded-md hover:border-eibbrightblue hover:border-1',
            filteredItems.length > 0 ? 'bg-eibbrightblue' : 'bg-white',
            !filterItems || filterItems.length === 0 ? 'hidden' : '',
        ]"
    >
        <Menu>
            <div>
                <MenuButton
                    as="div"
                    :class="[
                        'relative sm:flex-grow text-left',
                        this.filteredItems.length == filterItems.length
                            ? 'cursor-default'
                            : 'cursor-pointer',
                    ]"
                >
                    <div class="flex items-center">
                        <span
                            :class="[
                                'uppercase font-medium text-xs',
                                filteredItems.length > 0 ? 'text-white' : 'text-eibbrightblue',
                            ]"
                        >
                            {{ t(`filters.${filter.id}`) }}
                        </span>
                        <ArrowDownIcon
                            class="h-4 w-4 ml-3 text-eibbrightblue"
                            aria-hidden="true"
                            v-if="filteredItems.length === 0"
                        />
                        <FunnelIcon
                            class="h-4 w-4 ml-3 text-white"
                            aria-hidden="true"
                            v-if="filteredItems.length > 0"
                        />
                    </div>
                </MenuButton>
            </div>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
                v-if="filterItems.length !== filteredItems.length"
            >
                <MenuItems
                    class="absolute left-0 w-80 mt-6 z-10 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div class="px-1 py-1">
                        <div v-for="item in filterItems" :key="filter + '-' + item.id">
                            <MenuItem
                                v-slot="{ active }"
                                as="div"
                                v-if="!this.filteredItems.includes(item)"
                            >
                                <button
                                    @click="addFilter(item)"
                                    :class="[
                                        'group text-sm text-left  flex rounded-md items-center w-full px-3 py-2',
                                        active ? 'bg-eibbrightblue text-white' : 'text-gray-900',
                                    ]"
                                >
                                    {{ i18n ? t(`${item.name}`) : item.name }}
                                </button>
                            </MenuItem>
                        </div>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { computed } from 'vue'
import { ArrowDownIcon, FunnelIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
    name: 'FilterSelect',
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        ArrowDownIcon,
        FunnelIcon,
    },
    props: {
        filter: Object,
    },
    setup(props) {
        const { t, locale } = useI18n()
        const store = useStore()

        const filterItems = computed(() => {
            return store.getters[`filters/${props.filter.id}`]
        })

        const filteredItems = computed(() => {
            return store.getters[`filters/${props.filter.id}Filter`]
        })

        const addFilter = item => {
            if (!filteredItems.value.includes(item)) {
                const filtered = filteredItems.value
                // console.log(this.filteredItems)
                filtered.push(item)
                store.commit(`filters/${props.filter.id}Filter`, filtered)
            }
        }

        const i18n = computed(() => {
            return props.filter.i18nRequired
        })

        return {
            t,
            locale,
            addFilter,
            filterItems,
            filteredItems,
            i18n,
        }
    },
}
</script>
