<template>
    <div>
        <ExplainBox
            :subheader="t('country.selection')"
            :title="t('country.explain.specifyCountry')"
            :text="t('country.explain.text')"
        />
        <div class="p-12">
            <div class="container mx-auto lg:px-28">
                <CountrySelector />
            </div>
        </div>
    </div>
</template>

<script>
import ExplainBox from '@/components/common/ExplainBox.vue'
import CountrySelector from '@/components/country/CountrySelector.vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'CountrySelection',
    components: { ExplainBox, CountrySelector },
    setup() {
        const { t } = useI18n()

        return { t }
    },
}
</script>
