// Format Currencies in EIB Styles
export function format(num) {
    if (num === null) {
        return '0'
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function formatInvestment(num) {
    if (num === null) {
        return 'Not specified'
    }
    return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
}

export function showblank(text) {
    if (text === '' || text === undefined || text === null) {
        return '-'
    }
    return text
}

export function fixed(value) {
    if (typeof value === 'number') {
        return value.toFixed(0)
    }
    return value
}

export const removeWhiteSpaces = val => {
    return val.toString().replace(/\s+/g, '').replace(',', '')
}
