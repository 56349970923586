<template>
    <div class="mt-6 mb-3 sm:mt-12 sm:mb-3 w-full text-center">
        <div>
            <h5 class="h5 pb-6">{{ t('summary.comment.header') }}</h5>

            <!-- <div class="rounded-md bg-blue-50 p-4 mb-6">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm font-medium text-blue-700">
                            {{ t('general.btnNotFunctionalPlaceholder') }}
                        </p>
                    </div>
                </div>
            </div> -->
            <Comment class="ml-1 mb-2" />
            <FinalBeneficiaryDetails class="ml-1 mb-2" />
            <ClientReferenceId class="ml-1 mb-2" />
            <!-- <p class="my-6">
                In the free text field below you may enter a comment or an ID, which will then be
                added to the PDF report.
            </p>
            <textarea
                v-model="comment"
                rows="4"
                class="
                    shadow-sm
                    bg-gray-100
                    block
                    w-full
                    sm:text-sm
                    border-none
                    rounded-md
                    px-3
                    focus:border-blue-700 focus:ring-blue-700
                "
            /> -->
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
// import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import FinalBeneficiaryDetails from '@/components/summary/FinalBeneficiaryDetails.vue'
import ClientReferenceId from '@/components/summary/ClientReferenceId.vue'
import Comment from '@/components/summary/Comment.vue'

export default {
    components: {
        FinalBeneficiaryDetails,
        // InformationCircleIcon,
        ClientReferenceId,
        Comment,
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const comment = ref()
        watch(comment, val => {
            store.commit('global/comment', val)
        })
        return { comment, t }
    },
}
</script>
