// Based on https://stackoverflow.com/questions/66335997/vue-3-composition-api-debounce-function and https://dmitripavlutin.com/vue-debounce-throttle/

const debounce = (fn, delay) => {
    let timeout

    return (...args) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

export default debounce
