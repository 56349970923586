<template>
    <div class="" v-if="filter.includes(type) && show">
        <div class="font-medium text-xs my-2 text-eibgrey60">
            <span class="inline-block" v-html="t(label)"></span>
            <InformationCircleIcon
                class="ml-1 w-4 h-4 inline-block cursor-pointer"
                @click="showHint = !showHint"
                v-if="explanation"
            />
        </div>

        <div :class="['font-bold text-2xl']" :data-testid="test">
            <span v-html="formatedResult"></span>

            <span class="ml-1 text-sm" v-html="t(unit)"></span>
        </div>

        <div class="font-medium text-xs my-2 text-eibgrey60" v-if="showHint && explanation">
            {{ t(explanation) }}
        </div>
    </div>
</template>

<script>
import {
    InformationCircleIcon,
    // XCircleIcon
} from '@heroicons/vue/24/outline'
import { useI18n } from 'vue-i18n'

export default {
    components: {
        InformationCircleIcon,
        // XCircleIcon
    },
    data() {
        return {
            showHint: false,
        }
    },
    props: {
        label: String,
        result: Number,
        text: String,
        unit: String,
        formatedResult: String,
        test: String,
        type: String,
        show: Boolean,
        explanation: String,
        filter: {
            default: ['greenImpactPotential'],
            type: Array,
        },
    },
    setup(props) {
        const { t } = useI18n()

        // if (props.text === 'general.notSpecified') {
        //     // console.log('noit specifided')
        // }

        return { t }
    },
}
</script>
