<template>
    <div>
        <div class="inline-block sm:hidden cursor-pointer">
            <div
                @click="redirectToSummary()"
                class="inline-block justify-end md:justify-center uppercase pt-1 p-1 md:p-2 text-nav font-bold tracking-wide md:mx-3 bg-eiblightgrey rounded-full sm:bg-transparent sm:rounded-none"
            >
                <CalculatorIcon
                    class="h-7 w-7 sm:h-6 sm:w-6 md:mr-1 inline-block text-eibbrightblue"
                />
                <div
                    class="inline-block bg-eibbrightblue rounded-full text-white text-sm font-xss py-0.5 px-2 mt-1 mx-0.5 sm:py-1 sm:px-1 sm:mx-1 text-center"
                >
                    {{ measures.length }}
                </div>
            </div>
        </div>
        <Popover class="relative z-30 hidden sm:inline-block">
            <div class="active:border-none focus:outline-none cursor-pointer">
                <div
                    @click="this.$refs.confirmModalRef.openModal()"
                    class="inline-block justify-end md:justify-center uppercase pt-1 p-1 md:p-2 text-nav font-bold tracking-wide md:mx-3 bg-eiblightgrey rounded-full sm:bg-transparent sm:rounded-none"
                >
                    <div
                        class="inline-block bg-eibbrightblue rounded-full text-white text-sm font-bold py-0.5 px-2 mt-1 mx-0.5 sm:py-1 sm:px-3 sm:mx-3 text-center"
                    >
                        {{ measures.length }}
                    </div>

                    <span class="hidden md:inline-block">
                        <span class="hidden xl:inline-block">
                            {{ t('assessment.shoppingCartBtnText') }}
                        </span>
                    </span>
                </div>
            </div>

            <teleport to="body">
                <confirm-modal
                    ref="confirmModalRef"
                    :confirmText="t('general.confirmText')"
                    :showConfirmButton="false"
                    :showCancelButton="false"
                >
                    <div>
                        <div class="mt-3 text-center sm:mt-5" v-if="measures.length === 0">
                            <span class="text-eibgrey60">
                                {{ t('assessment.nothingSpecifiedText') }}
                            </span>
                        </div>
                        <div class="mt-3 text-center sm:mt-5" v-if="measures.length > 0">
                            <div class="flex-auto text-right">
                                <button
                                    @click="this.$refs.resetAlertRef.showAlert()"
                                    type="button"
                                    :class="[
                                        'mb-2 py-1 px-2 text-base font-normal ml-1 border-2 relative inline-flex items-center rounded-full justify-center focus:z-10 focus:outline-none',
                                        'border-gray-300 bg-white text-xs text-gray-700 hover:bg-gray-50 focus:border-2',
                                    ]"
                                >
                                    <ArrowPathIcon class="-ml-1 mr-2 h-4 w-4 text-gray-400" />
                                    {{ t('summary.resetProjectBtn') }}
                                </button>
                            </div>
                            <div
                                v-for="measure in measures"
                                v-bind:key="measure.uid"
                                class="border-green-900 rounded-xl my-2"
                            >
                                <div>
                                    <div class="grid grid-cols-2">
                                        <EligibleBox :measure="measure" view="cart" />
                                        <div class="my-2">
                                            <div class="float-right">
                                                <BaseButton
                                                    @click="setCurrentMeasure(measure.uid)"
                                                    :primary="false"
                                                    size="sm"
                                                    style="margin-right: 5px"
                                                >
                                                    <PencilSquareIcon class="h-4 w-4 float-right" />
                                                </BaseButton>
                                                <BaseButton
                                                    @click="
                                                        this.$refs.deleteMeasureAlertRef.showAlert(
                                                            measure.uid
                                                        )
                                                    "
                                                    :primary="false"
                                                    size="sm"
                                                >
                                                    <XMarkIcon class="h-4 w-4" />
                                                </BaseButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-1 mb-4 text-left">
                                        <h4 class="h4">{{ measure.fullname }}</h4>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div class="mt-3 text-center sm:mt-5">
                            <base-button
                                @click="start(true)"
                                size="sm"
                                style="margin-bottom: 2px"
                                :disabled="measures.length >= 50"
                                :title="
                                    measures.length >= 50 ? t('general.maxMeasuresTitle') : null
                                "
                            >
                                <div class="flex items-center px-2 gap-2">
                                    <div class="">
                                        <span class="">
                                            {{ t('assessment.addMeasureBtn') }}
                                        </span>
                                    </div>

                                    <PlusIcon class="h-7 w-6 ml-1 mr-0 float-right" />
                                </div>
                            </base-button>
                            <base-button @click="viewSummary()" class="ml-3" size="sm">
                                <div class="flex items-center px-2 gap-2">
                                    <div class="">
                                        <span class="">{{ t('general.summary') }}</span>
                                    </div>

                                    <ClipboardDocumentListIcon
                                        class="h-7 w-6 ml-1 mr-0 float-right"
                                    />
                                </div>
                            </base-button>
                        </div>
                    </div>
                </confirm-modal>
            </teleport>
        </Popover>
        <confirm-alert
            :title="t('input.modal.confirm.measure.delete.title')"
            :text="t('input.modal.confirm.measure.delete.text')"
            :confirmBtnText="t('input.modal.confirm.measure.delete.btn')"
            ref="deleteMeasureAlertRef"
            @confirm-clicked="deleteMeasure"
        ></confirm-alert>
        <confirm-alert
            :title="t('input.modal.confirm.project.reset.title')"
            :text="t('input.modal.confirm.project.reset.text')"
            :confirmBtnText="t('input.modal.confirm.project.reset.btn')"
            :confirmBtnIcon="true"
            :cancelBtnText="t('input.modal.confirm.project.keep.btn')"
            ref="resetAlertRef"
            @confirm-clicked="resetProject"
        ></confirm-alert>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { Popover } from '@headlessui/vue'
import {
    CalculatorIcon,
    PencilSquareIcon,
    PlusIcon,
    ClipboardDocumentListIcon,
    ArrowPathIcon,
    XMarkIcon,
} from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'
import EligibleBox from '@/components/common/EligibleBox.vue'
import confirmAlert from '@/components/base/confirmAlert.vue'
import confirmModal from '../base/confirmModal.vue'

export default {
    name: 'ShoppingCart',
    components: {
        Popover,
        CalculatorIcon,
        ArrowPathIcon,
        XMarkIcon,
        confirmAlert,
        PencilSquareIcon,
        BaseButton,
        EligibleBox,
        ClipboardDocumentListIcon,
        PlusIcon,
        confirmModal,
    },
    setup() {
        const { t } = useI18n()
        const open = ref(false)
        const store = useStore()
        const router = useRouter()

        const confirmModalRef = ref(false)
        const deleteMeasureAlertRef = ref(false)
        const resetAlertRef = ref(false)

        const redirectToSummary = () => {
            closeModal()
            store.commit('global/currentStep', 3)
            store.commit('measures/currentMeasureId', null)
            router.push('/summary')
        }

        const closeModal = () => {
            confirmModalRef.value.closeModal()
        }

        const start = close => {
            router.push('/selection')
            store.commit('measures/currentMeasureId', null)
            store.commit('global/currentStep', 1)
            if (close) closeModal()
        }

        const viewSummary = () => {
            closeModal()
            router.push('/summary')
        }

        const measures = computed(() => {
            return store.getters['measures/measures']
        })

        const deleteMeasure = uid => {
            closeModal()
            store.dispatch('measures/deleteMeasure', uid)
            start(false)
        }

        const resetProject = () => {
            closeModal()
            store.dispatch('measures/deleteMeasures')
            store.dispatch('global/deleteAdditionalInformation')
            router.push({ name: 'Selection' })
        }

        const setCurrentMeasure = async uid => {
            closeModal()
            store.commit('measures/currentMeasureId', uid)
            store.commit('global/currentStep', 2)
            const measure = store.getters['measures/currentMeasure']
            router.push(`/specification/${measure.url}`)
        }

        return {
            open,
            confirmModalRef,
            closeModal,
            deleteMeasure,
            deleteMeasureAlertRef,
            resetProject,
            resetAlertRef,
            measures,
            setCurrentMeasure,
            viewSummary,
            start,
            redirectToSummary,
            t,
        }
    },
}
</script>
