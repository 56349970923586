<template>
    <BaseFormField :label="label">
        <div class="flex flex-row">
            <div
                v-if="condition_text"
                class="grow text-sm sm:text-md"
                v-html="condition_text"
            ></div>
            <RadioGroup v-model="selected">
                <div class="bg-white rounded-md flex">
                    <RadioGroupOption
                        as="template"
                        v-for="(setting, settingIdx) in settings"
                        :key="setting.value"
                        :value="setting"
                        v-slot="{ checked, active }"
                    >
                        <div
                            :data-testid="test"
                            :data-model="model"
                            :class="[
                                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md py-2' : '',
                                settingIdx === settings.length - 1
                                    ? 'rounded-bl-md rounded-br-md px-4 py-2'
                                    : '',
                                'flex cursor-pointer focus:outline-none',
                            ]"
                        >
                            <span
                                :class="[
                                    checked
                                        ? 'bg-blue-600 border-transparent'
                                        : 'bg-white border-gray-300',
                                    active ? 'ring-1 ring-offset-2 ring-indigo-500' : '',
                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                ]"
                                aria-hidden="true"
                            >
                                <span class="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <div class="ml-3 flex flex-col">
                                <RadioGroupLabel
                                    as="span"
                                    :class="[
                                        checked ? 'text-blue-900' : 'text-gray-900',
                                        'block text-sm font-medium',
                                    ]"
                                >
                                    {{ t(`${setting.description}`) }}
                                </RadioGroupLabel>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </div>
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    RadioGroup,
    // RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
} from '@headlessui/vue'
import BaseFormField from '@/components/base/BaseFormField.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

const settings = [
    { value: true, description: 'general.yes' },
    { value: false, description: 'general.no' },
]

export default {
    components: {
        BaseFormField,
        RadioGroup,
        // RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        ValidationMessage,
    },
    props: {
        label: String,
        condition_text: String,
        modelValue: Boolean,
        model: String,
        test: String,
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String],
            default: () => ({}),
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const selected = computed({
            get() {
                return settings.find(s => s.value === props.modelValue)
            },
            set(val) {
                emit('update:modelValue', val.value)
            },
        })
        return {
            settings,
            selected,
            t,
        }
    },
}
</script>
