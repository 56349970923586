<template>
    <div class="flex items-center mb-4">
        <input
            @input="check()"
            type="checkbox"
            data-testid="checkbox"
            :checked="checked"
            :id="fieldId"
            class="mr-2 hidden-input"
            v-bind="$attrs"
        />
        <label :for="fieldId" class="flex items-center cursor-pointer text-sm">
            <div
                :class="[
                    'w-8 h-5 flex items-center rounded-full p-1 duration-300 ease-in-out mr-4',
                    checked ? 'bg-eibbrightblue' : 'bg-gray-200',
                ]"
            >
                <div
                    :class="[
                        'bg-white w-3 h-3 rounded-full shadow-md transform duration-300 ease-in-out',
                        checked ? 'translate-x-3' : '',
                    ]"
                ></div>
            </div>
            {{ text }}
        </label>
        <span
            v-if="description"
            class="ml-2 inline-block"
            @click="descriptionVisible = !descriptionVisible"
        >
            <InformationCircleIcon
                class="h-4 w-4 sm:h-5 sm:w-5 text-eibbrightblue cursor-help"
                aria-hidden="true"
            />
        </span>
    </div>
    <div class="block" v-if="description && descriptionVisible">
        <div
            class="rounded-md bg-blue-50 p-2 mt-2 mb-4"
            @click="descriptionVisible = !descriptionVisible"
        >
            <div class="flex items-end">
                <!-- <span>
                        <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </span> -->
                <div class="ml-2 text-sm" v-html="description"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

export default {
    components: { InformationCircleIcon },
    emits: ['update:values'],
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        fieldId: {
            type: [String, Number],
            required: true,
        },
        values: {
            type: Array,
            required: true,
        },
    },
    setup(props, context) {
        const checked = computed(() => props.values.includes(props.value))
        const descriptionVisible = ref(false)

        function check() {
            const updatedValues = [...props.values]
            if (this.checked) {
                updatedValues.splice(updatedValues.indexOf(props.value), 1)
            } else {
                updatedValues.push(props.value)
            }
            context.emit('update:values', updatedValues)
        }

        return {
            check,
            checked,
            descriptionVisible,
        }
    },
}
</script>

<style>
.hidden-input {
    opacity: 0;
    width: 1px !important;
}
</style>
