<template>
    <!-- <div class="fixed inset-0 flex items-center justify-center">
        <button
            type="button"
            @click="openModal"
            class="
                px-4
                py-2
                text-sm
                font-medium
                text-white
                bg-black
                rounded-md
                bg-opacity-20
                hover:bg-opacity-30
                focus:outline-none
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
            "
        >
            Open dialog
        </button>
    </div> -->
    <TransitionRoot as="template" :show="open" ref="confirmModal">
        <Dialog
            as="div"
            static
            class="fixed z-30 inset-0 overflow-y-auto"
            @close="open = false"
            :open="open"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
                        :class="modalWidth"
                    >
                        <slot></slot>
                        <div
                            class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                            v-if="showCancelButton || showConfirmButton"
                        >
                            <button
                                v-if="showConfirmButton"
                                type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                @click="open = false"
                            >
                                {{ confirmText }}
                            </button>
                            <button
                                v-if="showCancelButton"
                                type="button"
                                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                @click="open = false"
                                ref="cancelButtonRef"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
    name: 'confirmModal',
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        confirmText: {
            type: String,
            default: 'OK',
        },
        showConfirmButton: {
            type: Boolean,
            default: true,
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
        width: {
            type: String,
        },
    },
    setup(props) {
        const open = ref(false)

        const modalWidth = computed(() => {
            if (props.width === 'xl') {
                return 'w-full sm:w-1/3'
            }
            return 'sm:max-w-lg sm:w-full'
        })

        return {
            open,
            modalWidth,
            closeModal() {
                open.value = false
            },
            openModal() {
                open.value = true
            },
        }
    },
}
</script>
