// // Rules for vee-validate
import i18n from '@/plugins/i18n.js'
import { defineRule } from 'vee-validate'
const t = i18n.global.t
import { isProxy, toRaw } from 'vue'

export function defineRules() {
    defineRule('required', value => {
        if (typeof value !== 'boolean' && value !== 0 && (!value || value.length === 0)) {
            return t('errorChecking.thisFieldIsRequired')
        }
        return true
    })
    defineRule('min', (value, [limit]) => {
        // The field is empty so it should pass
        if (typeof value !== 'number' && !isProxy(value)) {
            return true
        }
        // Für MultiLineField
        if (isProxy(value)) {
            value = toRaw(value)
            if (
                typeof value === 'object' &&
                Number(
                    value.map(v => v.numeric_value).reduce((partialSum, a) => partialSum + a, 0)
                ) < Number(limit)
            ) {
                return t('errorChecking.thisValueMustBeAtLeastLimit', [limit])
            }
        }

        if (typeof value === 'number' && Number(value) < Number(limit)) {
            return t('errorChecking.thisValueMustBeAtLeastLimit', [limit])
        }

        return true
    })
    defineRule('max', (value, [limit]) => {
        // The field is empty so it should pass
        if (typeof value !== 'number') {
            return true
        }
        if (typeof value === 'number' && Number(value) > Number(limit)) {
            return t('errorChecking.thisValueMustBeMaxLimit', [limit])
        }
        return true
    })
}
