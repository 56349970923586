<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <!-- <div class="fixed inset-0 flex items-center justify-center">
        <button
            type="button"
            @click="openModal"
            class="
                px-4
                py-2
                text-sm
                font-medium
                text-white
                bg-black
                rounded-md
                bg-opacity-20
                hover:bg-opacity-30
                focus:outline-none
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
            "
        >
            Open dialog
        </button>
    </div> -->
    <TransitionRoot as="template" :show="open" ref="infoModal">
        <Dialog
            as="div"
            static
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="open = false"
            :open="open"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-12 lg:max-w-lg lg:w-1/2 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
                    >
                        <div>
                            <slot></slot>
                        </div>
                        <div class="mt-5 sm:mt-6">
                            <BaseButton @click="open = false" :primary="false">
                                {{ confirmText }}
                            </BaseButton>
                            <button
                                type="button"
                                class="hidden inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                @click="open = false"
                            >
                                {{ confirmText }}
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'

export default {
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        // eslint-disable-next-line vue/no-unused-components
        XCircleIcon,
        // eslint-disable-next-line vue/no-unused-components
        CheckCircleIcon,
        BaseButton,
    },
    props: {
        confirmText: {
            type: String,
            default: 'Understood',
        },
        showConfirmButton: {
            type: Boolean,
            default: true,
        },
        confirmButtonColor: {
            type: String,
            default: 'eib-midnightblue',
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
        measure: { type: Object },
    },
    setup() {
        const open = ref(false)

        return {
            open,
            closeModal() {
                open.value = false
            },
            openModal() {
                open.value = true
            },
        }
    },
}
</script>
