<template>
    <div class="flex flex-wrap gap-4 items-end">
        <div class="grow max-w-sm">
            <select
                class="bg-eibblue20 rounded-md border-none w-full text-sm"
                v-model="value"
                :data-testid="test ? test : 'select'"
            >
                <option value="" disabled selected="selected" v-if="placeholder" class="text-black">
                    {{ placeholder }}
                </option>
                <option
                    class="bg-white border-none"
                    v-for="(option, i) in options"
                    :key="`option${i}`"
                    :value="getValue(option)"
                    v-html="getText(option)"
                ></option>
            </select>
        </div>
        <div class="grow max-w-xs">
            <div class="flex gap-x-4">
                <div class="self-end">
                    <cleave
                        :raw="false"
                        :options="cleaveoptions"
                        v-model="numeric_value"
                        :min="min"
                        :max="max"
                        :data-testid="test"
                        :disabled="false"
                        :styled="styled"
                        :class="[
                            'h-9 shadow-sm block w-full sm:text-sm border-none rounded-md px-3 focus:border-blue-700 focus:ring-blue-700',
                            styled === 'meta_field' ? 'bg-gray-100' : 'bg-eibblue20',
                        ]"
                        inputmode="decimal"
                    />
                </div>
                <div class="flex">
                    <span v-html="suffix"></span>
                </div>
                <div
                    v-if="showTrash"
                    class="flex justify-between items-center float-right cursor-pointer"
                >
                    <BaseButton @click="remove" size="sm" :primary="false" :secondary="true">
                        <XMarkIcon class="h-4 w-4 float-left" />
                    </BaseButton>
                </div>
            </div>
        </div>
        <ValidationMessage :validation="validation" />
    </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue'
import Cleave from 'vue-cleave-component'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { Cleave, XMarkIcon, BaseButton, ValidationMessage },
    name: 'MultiLineItem',
    props: {
        showTrash: Boolean,
        index: Number,
        options: [Array],
        modelValue: [Object],
        test: String,
        placeholder: String,
        styled: {
            type: String,
            required: false,
        },
        suffix: String,
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            cleaveoptions: {
                numeral: true,
                numeralDecimalMark: '.',
                delimiter: ' ',
            },
        }
    },
    computed: {
        value: {
            get() {
                if (this.modelValue) {
                    return this.modelValue.value
                }
                return {}
            },
            set(val) {
                // console.log(val)
                this.$emit(
                    'update:modelValue',
                    Object.assign(
                        {},
                        {
                            value: {
                                value: val,
                                numeric_value: this.modelValue.numeric_value,
                            },
                            index: this.index,
                        }
                    )
                )
            },
        },
        numeric_value: {
            get() {
                if (this.modelValue) {
                    return this.modelValue.numeric_value
                }
                return {}
            },
            set(val) {
                // console.log(val)
                this.$emit(
                    'update:modelValue',
                    Object.assign(
                        {},
                        {
                            value: {
                                value: this.modelValue.value,
                                numeric_value: parseInt(val.replace(/ /g, ''), 10),
                            },
                            index: this.index,
                        }
                    )
                )
            },
        },
    },
    methods: {
        getValue(item) {
            if (typeof item === 'object') {
                return item.value
            }
            return item
        },
        getText(item) {
            if (typeof item === 'object') {
                return item.text
            }
            return item
        },
        remove() {
            this.$emit('remove', this.index)
        },
    },
}
</script>
