<template>
    <div>
        <div class="px-0 lg:px-3 xl:pl-28">
            <div class="lg:shadow-xl md:rounded-xl p-1 sm:p-8 md:border border-gray-100">
                <!-- <div class="grid grid-cols-4 gap-4"> -->
                <div class="mx-6">
                    <div v-if="currentMeasure">
                        <div>
                            <h3 class="h3 measuretitle">
                                <span v-html="currentMeasure.fullname"></span>
                                <InformationCircleIcon
                                    v-if="currentMeasure.criteria_description"
                                    @click="descriptionVisible = !descriptionVisible"
                                    class="inline ml-1 h-4 w-4 sm:h-6 sm:w-6 text-eibbrightblue cursor-pointer"
                                    aria-hidden="true"
                                />
                            </h3>
                        </div>

                        <div
                            class="block"
                            v-if="currentMeasure.criteria_description && descriptionVisible"
                        >
                            <p
                                class="my-3 max-w-prose lg:max-w-3xl"
                                v-html="currentMeasure.measure_description"
                            ></p>
                            <div
                                class="border-l-2 bg-eiblightgrey mt-6 border-eibbrightblue p-2 py-4 my-2"
                            >
                                <h5
                                    class="ml-1 text-xs pb-1 sm:ml-2 sm:pb-3 font-semibold sm:text-base text-eibbrightblue"
                                >
                                    {{
                                        t('assessment.specification.eligibilityInformationHeading')
                                    }}
                                </h5>
                                <div class="flex items-end">
                                    <p
                                        class="ml-1 text-xs sm:text-base sm:ml-2"
                                        v-html="currentMeasure.criteria_description"
                                    ></p>
                                </div>
                            </div>
                        </div>
                        <div class="pt-3 pb-6" v-if="currentMeasure.extendedSchema.length > 0">
                            <div class="max-w-fit">
                                <FormSwitch
                                    v-model="advancedMode"
                                    class="cursor-pointer float-left"
                                    :label="t('general.advancedMode')"
                                    size="sm"
                                    :labelLeft="false"
                                    data-testid="advancedModeSwitch"
                                />
                                <InformationCircleIcon
                                    @click="
                                        advancedModeDescriptionVisible =
                                            !advancedModeDescriptionVisible
                                    "
                                    class="inline ml-1 h-3 w-3 sm:h-4 sm:w-4 text-eibbrightblue cursor-pointer"
                                    aria-hidden="true"
                                />
                            </div>
                            <div
                                v-if="advancedModeDescriptionVisible"
                                class="rounded-md bg-blue-50 p-2 my-2"
                            >
                                <div class="ml-2 text-sm">
                                    {{ t('advancedModeExplanation') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FormGenerator
                    ref="form"
                    :currentMeasure="currentMeasure"
                    :advancedMode="advancedMode"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import FormGenerator from '@/components/assessment/FormGenerator.vue'
import FormSwitch from '@/components/base/FormSwitch.vue'
import EligibleBox from '@/components/common/EligibleBox.vue'

import confirmAlert from '@/components/base/confirmAlert.vue'
import BaseButton from '@/components/base/BaseButton.vue'

export default {
    name: 'MeasureSpecification',
    components: {
        FormGenerator,
        FormSwitch,
        EligibleBox,
        XMarkIcon,
        InformationCircleIcon,
        BaseButton,
        // BaseFormField,
        // BaseInput,
        confirmAlert,
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()
        const form = ref(null)
        const descriptionVisible = ref(false)
        const advancedModeDescriptionVisible = ref(false)
        const confirmAlertRef = ref(false)
        const advancedMode = ref(false)

        // Set Loading to false upon opening
        store.commit('measures/preCheckLoading', false)

        const currentMeasure = computed(() => {
            return store.getters['measures/currentMeasure']
        })

        watch(
            () => advancedMode.value,
            newAdvancedModeStatus => {
                if (newAdvancedModeStatus === false) {
                    store.commit('measures/resetAdvancedMode')
                } else {
                    store.commit('measures/setAdvancedMode', true)
                }
            }
        )

        const preCheckLoading = computed(() => {
            return store.getters['measures/preCheckLoading']
        })

        return {
            currentMeasure,
            preCheckLoading,
            advancedMode,
            advancedModeDescriptionVisible,
            descriptionVisible,
            form,
            t,
        }
    },
}
</script>
