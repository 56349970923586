import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import LegalNotice from '../views/LegalNotice.vue'
import Summary from '../views/Summary.vue'
import Assessment from '../views/Assessment.vue'
import CountrySelection from '../views/CountrySelection.vue'
import DisclaimerConfirmation from '../views/DisclaimerConfirmation.vue'
import markerSDK from '@marker.io/browser'
import { getMarkerProjectId } from '../utils/environmentCheck'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { navbar: false },
    },
    {
        path: '/fi/:identifier',
        name: 'Financial Intermediary Journey',
        component: Home,
        meta: { navbar: false },
    },
    {
        path: '/selection',
        name: 'Selection',
        component: Assessment,
        beforeEnter(to, from, next) {
            store.commit('global/currentStep', 1)
            next()
        },
    },
    {
        path: '/specification',
        name: 'Specification',
        component: Assessment,
        async beforeEnter(to, from, next) {
            const currentMeasureId = store.getters['measures/currentMeasureId']
            if (currentMeasureId) {
                const measureExists = store.getters['measures/measureExists'](currentMeasureId)
                if (measureExists === false) {
                    next({ name: 'Selection' })
                } else {
                    store.commit('global/currentStep', 2)
                    next()
                }
            } else {
                await store.dispatch('measures/getMeasurePool')
                const measureExistsInPool = store.getters['measures/measureExistsInPool'](
                    to.params.app + '/' + to.params.measure
                )
                if (measureExistsInPool) {
                    await store.dispatch('measures/addMeasure', measureExistsInPool)
                    store.commit('measures/removePreCheckResult')
                    next(`/specification/${measureExistsInPool.url}`)
                } else {
                    next({ name: 'Selection' })
                }
            }
        },
        children: [
            {
                path: ':app/:measure',
                component: Assessment,
                name: 'Specification',
            },
        ],
    },
    {
        path: '/disclaimer',
        name: 'Disclaimer',
        component: DisclaimerConfirmation,
    },
    {
        path: '/country',
        name: 'Country',
        component: CountrySelection,
    },
    {
        path: '/summary',
        name: 'Summary',
        beforeEnter(to, from, next) {
            store.commit('global/currentStep', 3)
            next()
        },
        component: Summary,
    },
    {
        path: '/privacy',
        name: 'Privacy Policy',
        component: PrivacyPolicy,
    },
    {
        path: '/legal-notice',
        name: 'LegalNotice',
        component: LegalNotice,
    },
    {
        path: '/:catchAll(.*)',
        redirect: { path: '/', name: 'Home' },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
        if (savedPosition) {
            return savedPosition
        }
        return {
            top: 0,
            behavior: 'smooth',
        }
    },
})

router.beforeEach(async (to, from, next) => {
    const checkNotRequired = [
        'Disclaimer',
        'Country',
        'Home',
        'Financial Intermediary Journey',
        'Privacy Policy',
        'LegalNotice',
    ]

    if (to.query.reference) {
        store.commit('global/clientReferenceId', to.query.reference)
    } else if (to.query.ref) {
        store.commit('global/clientReferenceId', to.query.ref)
    }

    const loadMarkerIO = async () => {
        const markerProjectId = getMarkerProjectId()

        if (markerProjectId !== '') {
            const widget = await markerSDK.loadWidget({
                project: markerProjectId,
            })
            return widget
        }

        return null
    }

    loadMarkerIO()

    // redirect old FI paths to new subdomain
    if (to.name === 'Financial Intermediary Journey') {
        const fiIdentifier = to.params.identifier
        const fiSet = store.getters['auth/fiJourney']
        if (fiIdentifier && !fiSet) {
            window.location.replace(
                window.location.protocol + '//' + to.params.identifier + '.' + window.location.host
            )
        }
    }

    // Redirect all users to disclaimer confirmation if confirmation not set yet
    if (
        store.getters['global/disclaimerConfirmed'] === false &&
        !checkNotRequired.includes(to.name)
    ) {
        const disclaimerConfirmed = localStorage.getItem('disclaimerConfirmed')
        if (disclaimerConfirmed) {
            store.commit('global/disclaimerConfirmed', true)
        } else {
            router.push({ path: 'disclaimer', query: { next: to.path } })
        }
    }

    // Redirect all users to country setting if country is not set yet
    if (store.getters['global/country'] === null && !checkNotRequired.includes(to.name)) {
        const storedCountry = localStorage.getItem('country')
        if (storedCountry) {
            store.dispatch('global/setCountry', storedCountry)
            store.commit('global/countryConfirmed', true)
            next()
        } else {
            next({ name: 'Country', query: { next: to.path } })
        }
    } else {
        next()
    }
})

export default router
