<template>
    <div class="bg-gray-100 sm:pl-12 sm:pr-12 pl-4 pr-4 pt-3 sm:pt-6 pb-3 sm:pb-9" id="explainBox">
        <div class="container mx-auto lg:px-28 xs:w-full">
            <div class="max-w-6xl">
                <h3
                    class="text-gray-400 text-sm font-semibold sm:text-base uppercase sm:font-bold py-1 sm:py-3"
                >
                    {{ subheader }}
                </h3>
                <h3
                    class="font-semibold text-base sm:text-lg md:text-2xl lg:text-4xl sm:font-bold font-alegreya"
                    data-testid="stepTitle"
                >
                    {{ title }}
                </h3>
                <p class="my-3 text-sm sm:text-md md:text-base lg:text-lg">
                    {{ text }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExplainBox',
    props: {
        subheader: String,
        title: String,
        text: String,
        step: Number,
    },
}
</script>
