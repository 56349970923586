<template>
    <div class="relative z-10">
        <div class="active:border-none focus:outline-none cursor-pointer">
            <div
                @click="this.$refs.confirmModalRef.openModal()"
                class="inline-block justify-center uppercase lg:p-2 text-nav font-bold tracking-wide mx-3"
                :title="t('general.settings')"
            >
                <CogIcon class="w-6 h-6 mr-2 float-left text-eibbrightblue" />
                <span class="inline-block">{{ t('general.settings') }}</span>
            </div>
        </div>
        <teleport to="body">
            <confirm-modal
                ref="confirmModalRef"
                :confirmText="t('general.confirmText')"
                :showConfirmButton="false"
                :showCancelButton="false"
                width="xl"
            >
                <h5 class="text-sm md:h5 mb-6">
                    {{ t('settings.modalTitle') }}
                    <InformationCircleIcon
                        @click="descriptionVisible = !descriptionVisible"
                        class="inline ml-1 h-4 w-4 sm:h-5 sm:w-5 text-eibbrightblue cursor-pointer"
                        aria-hidden="true"
                    />
                </h5>

                <div class="block" v-if="descriptionVisible">
                    <p class="text-gray-500 text-sm" v-html="t('settings.energyCostSavings')"></p>
                </div>

                <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div
                                class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                            >
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th>
                                                <div
                                                    class="px-3 py-1 grid grid-cols-2 w-full text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    <div>{{ t('settings.energySource') }}</div>
                                                    <div>{{ t('settings.Tariff') }}</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(energyPrice, eId) in energyDataCopy"
                                            :key="energyPrice.id"
                                            :class="eId % 2 === 0 ? 'bg-white' : 'bg-eiblightgrey'"
                                        >
                                            <td>
                                                <div class="grid grid-cols-2 w-full items-center">
                                                    <div class="text-sm pl-2">
                                                        {{ energyPrice.energy_source.name }}
                                                    </div>
                                                    <input
                                                        type="number"
                                                        step="0.01"
                                                        name="email"
                                                        id="email"
                                                        min="0"
                                                        class="shadow-sm focus:ring-eibbrightblue focus:border-eibbrightblue w-full h-6 my-1 text-xs border-gray-300 rounded-md"
                                                        v-model="energyPrice.price"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between">
                    <base-button @click="resetPrices" class="mt-6 mb-0 ml-0" size="md">
                        {{ t('settings.resetBtn') }}
                        <ArrowPathIcon class="h-5 w-5 ml-0 mr-1 float-left" />
                    </base-button>
                    <base-button
                        @click="closeModal"
                        class="mt-6 mb-0 ml-0"
                        size="md"
                        test="energyDataBtn"
                    >
                        {{ t('settings.closeBtn') }}
                        <ArrowSmallRightIcon class="h-5 w-5 ml-1 mr-0 float-right" />
                    </base-button>
                </div>
            </confirm-modal>
        </teleport>
    </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import {
    CogIcon,
    InformationCircleIcon,
    ArrowPathIcon,
    ArrowSmallRightIcon,
} from '@heroicons/vue/24/outline'
import confirmModal from '@/components/base/confirmModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'

export default {
    name: 'Settings',
    components: {
        confirmModal,
        CogIcon,
        InformationCircleIcon,
        ArrowSmallRightIcon,
        ArrowPathIcon,
        BaseButton,
    },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const descriptionVisible = ref(false)
        const energyDataCopy = ref([])
        const energyData = computed(() => {
            return store.getters['global/energyData']
        })
        const confirmModalRef = ref(false)

        onMounted(() => {
            energyDataCopy.value = energyData.value
        })

        const triggerOpenModal = () => {
            confirmModalRef.value.openModal()
        }
        const resetPrices = async () => {
            await store.dispatch('global/resetEnergyData')
        }
        const closeModal = () => {
            descriptionVisible.value = false
            confirmModalRef.value.closeModal()
        }

        watch(energyData, newValue => {
            energyDataCopy.value = newValue
        })
        return {
            confirmModalRef,
            descriptionVisible,
            energyDataCopy,
            closeModal,
            triggerOpenModal,
            resetPrices,
            t,
        }
    },
}
</script>
