<template>
    <div>
        <!-- Refactor https://github.com/bpampuch/pdfmake/issues/866 -->

        <BaseButton test="PDFCreateBtn" @click="downloadPDF" size="xl"
            :disabled="completedMeasuresCount === 0 ? true : false" :class="isLoading ? 'cursor-not-allowed' : ''">
            <div v-if="isLoading">
                <svg class="animate-spin -ml-1 mr-3 h-6 w-6 mt-0.5 text-white group-hover:text-eibbrightblue float-left"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                    </path>
                </svg>
                {{ t('report.generatingReportTxt') }}
            </div>
            <div v-else>
                <DocumentArrowDownIcon class="h-6 w-6 ml-0 mr-2 float-left" />
                {{ t('general.createReportBtn') }}
            </div>
        </BaseButton>
        <div v-if="showError">
            <div class="rounded-md bg-red-50 p-4 mt-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                            {{ errorTitle }}
                        </h3>
                        <div class="mt-2 text-sm text-red-700">
                            {{ errorDescription }}
                        </div>
                    </div>
                    <div class="ml-auto pl-3">
                        <div class="-mx-1.5 -my-1.5">
                            <button type="button"
                                class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                                @click="showError = false">
                                <span class="sr-only">Dismiss</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { DocumentArrowDownIcon, XCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import BaseButton from '@/components/base/BaseButton.vue'
import axios from '@/plugins/axios.js'

export default {
    components: { BaseButton, DocumentArrowDownIcon, XCircleIcon, XMarkIcon },
    setup() {
        const { t } = useI18n()
        const store = useStore()
        const loading = ref(false)
        const showError = ref(false)
        const errorTitle = ref('')
        const errorDescription = ref('')
        const measuresCount = store.getters['measures/measuresCount']
        const completedMeasuresCount = computed(() => {
            return store.getters['measures/completeMeasures'].length
        })

        const isLoading = computed(() => {
            return loading.value
        })

        const setLoading = status => {
            loading.value = status
        }

        const downloadPDF = async () => {
            showError.value = false
            loading.value = true
            const APIToken = store.getters['auth/APIToken']
            let reportingDataInformation = {
                version: 1,
            }
            const fiID = store.getters['auth/financialIntermediaryId']
            if (fiID !== null) {
                reportingDataInformation.financialIntermediaryId = fiID
            }
            axios
                .post(
                    `/pdf/`,
                    {
                        reportingData: reportingDataInformation,
                        measures: store.getters['measures/completeMeasures'],
                        beneficiaryDetails: store.getters['global/beneficiaryDetails'],
                        clientReferenceId: store.getters['global/clientReferenceId'],
                        comment: store.getters['global/comment'],
                    },
                    {
                        responseType: 'blob',
                        headers: {
                            Authorization: `Token ${APIToken}`,
                            'Accept-Language': store.getters['global/locale'],
                        },
                    }
                )
                .then(res => {
                    const filename = res.headers['content-disposition']
                        .split('filename=')[1]
                        .split('.')[0]
                    let blob = new Blob([res.data], { type: res.headers['content-type'] })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.setAttribute('download', filename + '.pdf')
                    link.click()
                    loading.value = false
                    link.remove()
                    if (window._paq) {
                        window._paq.push(['trackEvent', 'Report', 'Report Created', fiID])
                    }
                })
                .catch(err => {
                    loading.value = false
                    let reader = new FileReader()

                    reader.onload = e => {
                        let error = JSON.parse(e.target.result)
                        showError.value = true
                        errorDescription.value = `Code: ${error.error}`
                        errorTitle.value = `Error: ${error.message}`
                        if (window._paq) {
                            window._paq.push([
                                'trackEvent',
                                'Report',
                                'Report Creation Error',
                                fiID,
                            ])
                        }
                        console.log(error.detail)
                    }
                    if (err.response) {
                        reader.readAsText(err.response.data)
                    }
                    console.error(err)
                })
        }

        return {
            t,
            isLoading,
            measuresCount,
            completedMeasuresCount,
            setLoading,
            downloadPDF,
            showError,
            errorDescription,
            errorTitle,
        }
    },
}
</script>
