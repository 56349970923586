/* eslint-disable no-underscore-dangle */
<template>
    <!-- <BaseFormField :label="label"> -->
    <div class="flex mb-6">
        <input
            type="checkbox"
            :id="id"
            v-model="value"
            :modelValue="modelValue"
            :required="required"
            :disabled="readOnly"
            :data-testid="test ? test : 'checkbox'"
            :data-model="model"
            class="focus:ring-indigo-500 h-4 w-4 text-eibbrightblue border-gray-300 rounded my-1 ml-6 float-left"
        />
        <label :for="id" class="ml-3 float-right font-medium">{{ label }}</label>
        <div v-if="condition_text">{{ condition_text }}</div>
        <ValidationMessage :validation="validation" />
    </div>
    <!-- </BaseFormField> -->
</template>

<script>
// import BaseFormField from '@/components/base/BaseFormField.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { ValidationMessage },
    data() {
        return {
            id: null,
        }
    },
    props: {
        modelValue: { required: true, default: true },
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            required: true,
        },
        condition_text: {
            type: String,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: Object,
            default: () => ({}),
        },
        model: {
            type: String,
        },
        test: {
            type: String,
        },
    },
    computed: {
        labelHtml() {
            const requiredHtml =
                // eslint-disable-next-line no-underscore-dangle
                this.validations._exclusive && this.validations._exclusive.required
                    ? ' <span class="label--required">*</span>'
                    : ''
            return this.label + requiredHtml
        },
        value: {
            get() {
                return this.modelValue
            },
            set(val) {
                // console.log(val)
                this.$emit('update:modelValue', val)
            },
        },
    },
    mounted() {
        const generateUUID = () => {
            const d = new Date().getTime()

            return d.toString(16)
        }
        this.id = generateUUID()
    },
}
</script>
