<template>
    <div>
        <Menu as="div" class="relative inline-block text-left z-30">
            <div
                :class="[
                    route.meta.navbar == false
                        ? 'border-2 border-white rounded-lg mt-3 border-opacity-60'
                        : '',
                ]"
            >
                <MenuButton
                    class="text-nav font-bold tracking-wide mx-3 disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="langs.length <= 1"
                >
                    <span class="sr-only">Open options</span>
                    <LanguageIcon
                        class="h-6 w-6 mr-1 float-left text-blue-500"
                        aria-hidden="true"
                        v-if="route.meta.navbar !== false"
                    />
                    <span :class="['uppercase', route.meta.navbar == false ? 'text-white' : '']">
                        {{ locale }}
                    </span>
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems
                    :class="[
                        'origin-top-right absolute  uppercase mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                        route.meta.navbar == false ? '-right-1 w-21' : 'right-3',
                    ]"
                >
                    <div class="py-1">
                        <MenuItem
                            v-slot="{ active }"
                            v-for="(lang, i) in langs"
                            :key="`Lang${i}`"
                            :value="lang"
                        >
                            <a
                                href="#"
                                :class="[
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    lang === locale ? 'font-bold' : '',
                                    'block px-4 py-2 text-sm',
                                ]"
                                @click="modalCheck(lang)"
                            >
                                {{ lang }}
                            </a>
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
        <teleport to="body">
            <confirm-alert
                :title="t('general.areYouSure')"
                :text="t('general.LanguageChangeImplications')"
                :confirmBtnText="t('general.yesChangeIt')"
                :confirmBtnIcon="true"
                :cancelBtnText="t('general.keepMeasure')"
                ref="confirmAlertRef"
                @confirm-clicked="switchLanguage"
            ></confirm-alert>
        </teleport>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { LanguageIcon } from '@heroicons/vue/24/outline'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import confirmAlert from '@/components/base/confirmAlert.vue'

const { t, locale } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useStore()
const confirmAlertRef = ref(false)

const langs = computed(() => {
    return store.getters['auth/financialIntermediaryLanguages']
})

const measuresInCart = computed(() => {
    return store.getters['measures/measuresCount']
})

const switchLanguage = lang => {
    router.push('/selection')
    store.commit('measures/measures', [])
    store.commit('measures/currentMeasureId', null)
    store.commit('global/currentStep', 1)
    locale.value = lang
}

const modalCheck = lang => {
    if (measuresInCart.value > 0) {
        confirmAlertRef.value.showAlert(lang)
    } else {
        if (window._paq) {
            window._paq.push([
                'trackEvent',
                'LanguageChange',
                'Language Change',
                `${locale.value} to ${lang} (browser language ${window.navigator.language})`,
            ])
        }
        locale.value = lang
    }
}
</script>
