import { toRaw } from 'vue'
import axios from '@/plugins/axios.js'
import i18n from '@/plugins/i18n.js'
const locale = i18n.global.locale

const state = {
    token: 'cf90c279032841f57383c48836a5016791e4482f',
    financialIntermediary: null,
    fiJourney: false,
}
const getters = {
    APIToken(state) {
        return state.token
    },
    financialIntermediary(state) {
        const fidata = sessionStorage.getItem('fi')
        if (fidata === undefined || fidata === null) {
            return state.financialIntermediary
        } else return JSON.parse(fidata)
    },
    financialIntermediaryState(state) {
        return toRaw(state.financialIntermediary)
    },
    financialIntermediaryId(state, getters) {
        return state.financialIntermediary === null ? 'eib' : getters.financialIntermediary.id
    },
    financialIntermediaryLanguages(state, getters) {
        return getters.financialIntermediary === null
            ? ['de', 'en', 'es', 'fr', 'it', 'pl']
            : getters.financialIntermediary.languages
    },
    financialIntermediaryCountries(state, getters) {
        return getters.financialIntermediary === null ||
            getters.financialIntermediary.countries.length === 0
            ? null
            : getters.financialIntermediary.countries
    },
    financialIntermediaryFAQPackage(state, getters) {
        return getters.financialIntermediary === null
            ? 'eib'
            : getters.financialIntermediary.faq_package
    },
    fiJourney(state) {
        const fidata = sessionStorage.getItem('fi')
        if (fidata === undefined || fidata === null) {
            return state.fiJourney
        } else return true
    },
}
const set = key => (state, val) => {
    state[key] = val
}

const mutations = {
    fiJourney: set('fiJourney'),
    financialIntermediary: set('financialIntermediary'),
}

const actions = {
    async getFinancialIntermediaryData({ getters, commit, dispatch, rootGetters }, data) {
        const APIToken = getters.APIToken
        const acceptLocale = rootGetters['global/locale']
        await axios
            .get(`/helpers/clients/${data.fiIdentifier}/?contractor=eib`, {
                headers: {
                    Authorization: `Token ${APIToken}`,
                    'Accept-Language': acceptLocale,
                },
            })
            .catch(error => {
                console.log(error)

                // FI not found, remove subdomain and redirect
                if (error.response.status === 404) {
                    window.location.replace(
                        window.location.protocol +
                            '//' +
                            window.location.href.replace(
                                window.location.href.split('.')[0] + '.',
                                ''
                            )
                    )
                }
            })
            .then(response => {
                const financialIntermediary = {
                    id: response.data.identifier,
                    name: response.data.name,
                    shortName: response.data.short_name,
                    webhook: response.data.webhook_enabled,
                    reporting_format: response.data.reporting_format,
                    faq_package: response.data.faq_package,
                    custom_texts: response.data.custom_texts,
                    guidance: response.data.introductory_text,
                    countries: response.data.countries.map(m => ({
                        value: m.identifier,
                        text: m.name,
                    })),
                    languages: response.data.languages,
                    mainColour: response.data.main_colour,
                }

                commit('financialIntermediary', financialIntermediary)
                commit('fiJourney', true)
                sessionStorage.setItem('fi', JSON.stringify(financialIntermediary))

                if (financialIntermediary.languages.length === 1) {
                    if (financialIntermediary.languages[0] !== locale.value) {
                        locale.value = financialIntermediary.languages[0]
                    }
                }

                // If FI has only one country, set it as default
                if (financialIntermediary.countries.length === 1) {
                    const country = financialIntermediary.countries[0]
                    dispatch('global/setCountry', country, { root: true })
                    commit('global/countryConfirmed', true, { root: true })
                    commit('global/storeCountrySelection', country.value, { root: true })
                }

                return financialIntermediary
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
