<template>
    <div id="app" class="relative mb-18">
        <Nav v-if="$route.name != 'Login'" />
        <router-view />
        <Footer v-if="$route.name != 'Login'" />
        <ScrollToTop />
        <CookieConsent v-if="!matomoNotAvailable" />
    </div>
</template>

<script>
import Nav from '@/components/common/Nav.vue'
import Footer from '@/components/common/Footer.vue'
import ScrollToTop from '@/components/common/ScrollToTop.vue'
import CookieConsent from '@/components/common/CookieConsent.vue'
import { ref, watch, onBeforeMount, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
    components: {
        Nav,
        Footer,
        ScrollToTop,
        CookieConsent,
    },
    setup(props, context) {
        const store = useStore()
        const { t, locale, messages } = useI18n()
        const matomoNotAvailable = ref(localStorage.getItem('mtm_not_available'))

        store.commit('global/locale', locale.value)

        const preventNav = e => {
            if (store.getters['measures/measures'].length > 0) {
                e.preventDefault()
                e.returnValue = ''
                return false
            }
            return true
        }

        // Set FI custom variable for Matomo (only on production environment)
        const setMatomoFICustomVariable = () => {
            const fi = store.getters['auth/financialIntermediary']
            if (fi && fi.id && window._paq) {
                window._paq.push(['setCustomVariable', '3', 'FinancialIntermediary', fi.id])
            }
        }

        const checkSubdomainForFI = async () => {
            // get host without www.
            const url = window.location.host.replace('www.', '')

            // get first subdomain
            const sub = url.split('.')[0]

            // default subdomains that are not related to a FI
            const reg = new RegExp('eib-greenchecker|greenchecker|staging|localhost:8000')
            const fi = JSON.parse(sessionStorage.getItem('fi'))

            // if subdomain is not in the default, start FI journey with subdomain as FI id
            const fiIdentifier = !reg.test(sub) ? sub : fi ? fi.id : undefined
            if (fiIdentifier) {
                await store.dispatch('auth/getFinancialIntermediaryData', {
                    fiIdentifier: sub,
                })
                setMatomoFICustomVariable()
            }
        }

        const loadData = async () => {
            await Promise.all([
                store.dispatch('global/getFAQs'),
                store.dispatch('global/getCountries'),
                store.dispatch('global/getTaxonomyActivities'),
                store.dispatch('global/initialiseStore'),
                store.dispatch('global/getEnergySourceData'),
                store.dispatch('filters/getSectors'),
                store.dispatch('filters/getClimateActionCategories'),
                store.dispatch('filters/getEconomicActivities'),
                store.dispatch('filters/getEnvironmentalTargets'),
            ])
        }

        onBeforeMount(async () => {
            window.addEventListener('beforeunload', preventNav)
            if (window._paq) {
                localStorage.removeItem('mtm_not_available')
            }
            await checkSubdomainForFI()
            await loadData()
        })

        onBeforeUnmount(() => {
            window.removeEventListener('beforeunload', preventNav)
        })

        const loadMeasurePool = async () => {
            await store.dispatch('measures/getMeasurePool')
        }

        const reloadMeasurePool = async () => {
            await store.dispatch('measures/getMeasurePool')
            await store.dispatch('measures/updateMeasuresLanguage')
        }

        const reloadFinancialIntermediaryData = async () => {
            const fi = JSON.parse(sessionStorage.getItem('fi'))
            await store.dispatch('auth/getFinancialIntermediaryData', { fiIdentifier: fi.id })
        }

        // watch for locale change and reload backend data
        watch(locale, (newLocale, oldLocale) => {
            const languages = Object.keys(messages.value)
            if (languages.includes(newLocale)) {
                document.querySelector('html').setAttribute('lang', newLocale)
                localStorage.setItem('chosenLanguage', newLocale)
            }
            store.commit('global/locale', newLocale)
            store.commit('global/localeChange', true)
            reloadFinancialIntermediaryData()
            loadData()
            reloadMeasurePool(locale.value)
            store.commit('global/localeChange', false)
        })

        return {
            t,
            locale,
            preventNav,
            loadData,
            loadMeasurePool,
            reloadFinancialIntermediaryData,
            reloadMeasurePool,
            matomoNotAvailable,
        }
    },
}
</script>
