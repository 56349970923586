/* eslint-disable no-underscore-dangle */
<template>
    <BaseFormField :label="label" :formFieldClasses="formFieldClasses">
        <input
            :type="type"
            :min="min"
            :max="max"
            :maxlength="maxlength ? maxlength : ''"
            :step="step"
            :styled="styled"
            :value="modelValue"
            :required="required"
            :disabled="readOnly"
            :data-testid="test"
            :placeholder="placeholder"
            :data-model="model"
            @input="update($event.target.value)"
            :class="[
                'h-9 shadow-sm block w-full sm:text-sm border-none rounded-md px-3 focus:border-blue-700 focus:ring-blue-700',
                styled === 'meta_field' ? 'bg-gray-100' : 'bg-eibblue20',
            ]"
            inputmode="text"
        />
        <ValidationMessage :validation="validation" />
    </BaseFormField>
</template>

<script>
import BaseFormField from '@/components/base/BaseFormField.vue'
import ValidationMessage from '@/components/base/ValidationMessage.vue'

export default {
    components: { BaseFormField, ValidationMessage },
    props: {
        modelValue: { required: true },
        required: {
            type: Boolean,
            default: false,
        },
        formFieldClasses: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            required: true,
        },
        styled: {
            type: String,
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
        validations: {
            type: [Object, String],
            default: () => ({}),
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
        maxlength: {
            type: String,
        },
        step: {
            type: Number,
            default: 1,
        },
        model: {
            type: String,
        },
        test: {
            type: String,
        },
        placeholder: {
            type: String,
        },
    },
    computed: {
        labelHtml() {
            const requiredHtml =
                // eslint-disable-next-line no-underscore-dangle
                this.validations._exclusive && this.validations._exclusive.required
                    ? ' <span class="label--required">*</span>'
                    : ''
            return this.label + requiredHtml
        },
    },
    methods: {
        update(value) {
            this.$emit('update:modelValue', value)
        },
    },
}
</script>
